export const regEx = {
    userId: /^[a-zA-Z0-9]*$/,
    password: /^[a-zA-Z0-9 \(\)\+\-\_\!\$\@]*$/,
    phone: /^[a-zA-Z0-9 \(\)\+]*$/,
    account: /^[0-9 \-]*$/,
    alphabet: /^[a-zA-Z]*$/,
    alphabetWithSpace: /^[a-zA-Z ]*$/,
    num: /^[0-9]*$/,
    numberWithSpace: /^[0-9 ]*$/,
    alphaNum: /^[a-zA-Z0-9]*$/,
    alphNumWithSpace: /^[a-zA-Z0-9 ]*$/
};
