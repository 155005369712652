import { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

import packageJson from "../package.json";

export const SocketContext = createContext();

export default function SocketContextProvider({children}) {
  const [socket, setSocket] = useState(null);

  

  useEffect(() => {
    let s = io(packageJson.gameserver);
  setSocket(s);

  },[]);
    /*
  let socketPromise = 
    socket != null ? Promise.resolve(socket) :
    
    new Promise((resolve, reject) => {
      let newSock = io('http://192.168.1.100:3000'); // Try to connect
      
      let didntConnectTimeout = setTimeout(() => {
        reject();
      }, 15000) // Reject if didn't connect within 15 seconds

      newSock.once('connect', () => {
        clearTimeout(didntConnectTimeout); // It's connected so we don't need to keep waiting 15 seconds
        setSocket(newSock); // Set the socket
        resolve(newSock); // Return the socket
      })
    });
  /*
  /*
  let socket = () => {
    console.log("SocketContextProvider async.");
    if (sock != null) {
      return Promise.resolve(sock); // If already exists resolve
    }
    return new Promise((resolve, reject) => {
      let newSock = io('http://192.168.1.100:3000'); // Try to connect
      
      let connectTimeout = setTimeout(() => {
        reject();
      }, 15000) // Reject if didn't connect within 15 seconds

      newSock.once('connect', () => {
        clearTimeout(connectTimeout); // It's connected so we don't need to keep waiting 15 seconds
        setSocket(newSock); // Set the socket
        resolve(newSock); // Return the socket
      })
    });
  };*/

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}