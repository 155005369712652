import React, { useRef, useEffect, useState, useContext } from "react";
import {Slider, Stack, useForkRef} from "@mui/material";
import { useUserContext } from './UserContext';

import "./GameRoom.css";
import { SocketContext } from "./SocketContext.js";
import packageJson from "../package.json";

import "./App.css";



const GameRoom = ({onBack, rmId, rmLevel, rmName}) => {

    
    const [roomId, setRoomId] = useState(rmId);
    const [roomLevel, setRoomLevel] = useState(rmLevel);
    const [roomName, setRoomName] = useState(rmName);
    const [roomNumber, setRoomNumber] = useState("");
    //to prevent functions and other codes to execute before the game is set up correctly
    const gameFinishedLoading = useRef(false);
    const isUserNotLeaving = useRef(true);//to prevent socket trying to access functions inside this page
    //const roomName = useRef("");
    const [debugMsg, setDebugMsg] = useState("");
    const [seeCardFinish, setSeeCardFinish] = useState(false);
    const [seeDrawCardFinish, setSeeDrawCardFinish] = useState(false);

    const [loadingDialogVisibility, setLoadingDialogVisibility] = useState("visible");
    const [menuDialogVisibility, setMenuDialogVisibility] = useState("hidden");

    const [infoMsg, setInfoMsg] = useState("");

    //user info
    const {userInfo, setUserInfo} = useUserContext();
    const playerSeatNoRef = useRef(0);

    //socket
    const {socket} = useContext(SocketContext);
    const socketEmitter = useRef(null);

    //seats
    const [userUpdated, setUserUpdated] = useState(false);

    //jackpot 
  const [jackpot, setJackpot] = useState(0);

    //room info
    const gameRoom = useRef(null);
    const playerCardVisible = useRef(false);
    const allCardsVisible = useRef(false);
    let isDealer = false;
    

    let __cards = [];
    let __status = [];
    let requestMessage = {};
    let __seats = useRef([]);

  
    const svgRef = useRef(null);
    const tableGRef = useRef(null);
    const animationG = useRef(null);

    


    const canvasWidth = document.body.clientWidth;
    const canvasHeight = window.innerHeight;
    const canvasCenterX = (canvasWidth / 2) + 10;
    const canvasCenterY = (canvasHeight / 2) + 10;

  

    //table rectangle
    const rectWidth = 0.8 * canvasWidth;
    const rectHeight = 0.8 * canvasHeight;
    const rectStrokeWidth = rectWidth * 0.04;
    const rectX = (0.5 * canvasWidth) - (0.5 * rectWidth) - (rectStrokeWidth/2);
    const rectY = (canvasCenterY) - (0.5 * rectHeight) - (rectStrokeWidth/2);
    const rectStroke  = "#005500";
    const rectRX = rectWidth / 5;
    const rectRY = rectWidth / 3;

    const tableBorderShadowStrokeWidth = rectStrokeWidth * 0.5;
    const tableBorderReflectionStrokeWidth = rectStrokeWidth * 0.1;

    //inner rectangle

    const innerRectWidth = rectWidth * 0.8;
    const innerRectHeight = rectHeight * 0.6;
    const innerRectX = rectX + ((rectWidth - innerRectWidth) / 2);
    const innerRectY = rectY + ((rectHeight - innerRectHeight) / 2);
    const innerRectStrokeWidth = 5;
    const innerRectStroke = "#600";


    //card
    const cardHeight = canvasHeight * 0.2;
    const cardWidth = cardHeight / 3 * 2;

    //seat
    const seatRadius = rectHeight / 15;
    const seatStrokeColor = "#333";
    const seatFillColor = "#333";
    const seatStrokeWidth = 3;
    const seatPadding = seatRadius * 0.3;

    //player
    const playerCircleR = seatRadius - seatStrokeWidth;
    const playerTextPadding = 10;


  const headerHeight = canvasHeight / 10;
  const jackPotBGHeight = headerHeight * 0.5;
  const jackPotBGWidth = jackPotBGHeight * 6;
  const balanceBGHeight = headerHeight;
  const balanceBGWidth = balanceBGHeight * 3;

  const gameNameFontSize = headerHeight;
  const jackPotFontSize = jackPotBGHeight * 0.9;
  const jackPotMoneyFontSize = jackPotBGHeight * 0.7;
  const balanceFontSize = jackPotBGHeight * 0.8;

    const orgSeatPos = [];
    const orgCardPos = [];
    const cardFinalPos = [];
    const orgAnchorPos = [];
    for(let x = 0; x < 8; x++){
      orgSeatPos[x] = {x:0,y:0};
        orgCardPos[x] = {x:0,y:0};
        cardFinalPos[x] = {x:0,y:0};
        orgAnchorPos[x] = {x:0,y:0}
    }
    orgSeatPos[0].x = rectX + (rectRX * 0.3);
    orgSeatPos[0].y = (rectY + (rectRY * 0.2));
    orgSeatPos[1].x = (canvasWidth) / 2;
    orgSeatPos[1].y = (rectY + seatRadius);
    orgSeatPos[2].x = (rectX + rectWidth) - (rectRX * 0.3);
    orgSeatPos[2].y = (rectY + (rectRY * 0.2));
    orgSeatPos[3].x = ((canvasWidth + rectWidth) / 2) - (seatRadius);
    orgSeatPos[3].y = canvasHeight / 2;
    orgSeatPos[4].x = (rectX + rectWidth - (rectRX*0.3));
    orgSeatPos[4].y = (rectY + rectHeight - (rectRY * 0.2));
    orgSeatPos[5].x = (canvasWidth) / 2;
    orgSeatPos[5].y = (canvasHeight - (playerCircleR * 1.5));//1.3
    orgSeatPos[6].x = rectX + (rectRX * 0.3);
    orgSeatPos[6].y = (rectY + rectHeight - (rectRY * 0.2));
    orgSeatPos[7].x = (rectY + (rectRY * 0.2));
    orgSeatPos[7].y = canvasHeight / 2;
    
    orgAnchorPos[0].x = orgSeatPos[0].x + (playerCircleR * 1.5);
    orgAnchorPos[0].y = orgSeatPos[0].y - (cardHeight/2);
    orgAnchorPos[1].x = orgSeatPos[1].x + (playerCircleR * 1.5);
    orgAnchorPos[1].y = orgSeatPos[1].y - (cardHeight/2);
    orgAnchorPos[2].x = orgSeatPos[2].x - (playerCircleR) - (cardWidth * 2);
    orgAnchorPos[2].y = orgSeatPos[2].y - (cardHeight/2);
    orgAnchorPos[3].x = orgSeatPos[3].x - (playerCircleR) - (cardWidth * 2);
    orgAnchorPos[3].y = orgSeatPos[3].y - (cardHeight/2);
    orgAnchorPos[4].x = orgSeatPos[4].x - (playerCircleR) - (cardWidth * 2);
    orgAnchorPos[4].y = orgSeatPos[4].y - (cardHeight/2);
    orgAnchorPos[5].x = orgSeatPos[5].x + (playerCircleR - (cardWidth * 2.5));
    orgAnchorPos[5].y = canvasHeight - (cardHeight*1.2);//1.1
    orgAnchorPos[6].x = orgSeatPos[6].x + (playerCircleR * 1.5);
    orgAnchorPos[6].y = orgSeatPos[6].y - (cardHeight /2);
    orgAnchorPos[7].x = orgSeatPos[7].x + (playerCircleR * 1.5);
    orgAnchorPos[7].y = orgSeatPos[7].y - (cardHeight/2);

    let cardActualX = canvasCenterX - (cardWidth/2);
    let cardActualY = canvasCenterY - (cardHeight/2)

    orgCardPos[0].x = orgAnchorPos[0].x - cardActualX;
    orgCardPos[0].y = orgAnchorPos[0].y - cardActualY;
    orgCardPos[1].x = orgAnchorPos[1].x - cardActualX;
    orgCardPos[1].y = orgAnchorPos[1].y - cardActualY;
    orgCardPos[2].x = orgAnchorPos[2].x - cardActualX;
    orgCardPos[2].y = orgAnchorPos[2].y - cardActualY;
    orgCardPos[3].x = orgAnchorPos[3].x - cardActualX;
    orgCardPos[3].y = orgAnchorPos[3].y - cardActualY;
    orgCardPos[4].x = orgAnchorPos[4].x - cardActualX;
    orgCardPos[4].y = orgAnchorPos[4].y - cardActualY;
    orgCardPos[5].x = orgAnchorPos[5].x - cardActualX;
    orgCardPos[5].y = orgAnchorPos[5].y - cardActualY;
    orgCardPos[6].x = orgAnchorPos[6].x - cardActualX;
    orgCardPos[6].y = orgAnchorPos[6].y - cardActualY;
    orgCardPos[7].x = orgAnchorPos[7].x - cardActualX;
    orgCardPos[7].y = orgAnchorPos[7].y - cardActualY;


    const seatPos = useRef([...orgSeatPos]);
    const anchorPos = useRef([...orgAnchorPos]);
    const cardPos = useRef([...orgCardPos]);
    
    // DIALOG SETTING
    const dialogWidth = 0.9 * canvasWidth;
    const dialogHeight = 0.9 * canvasHeight;
    const dialogStrokeWidth = 3;
    const dialogX = (canvasWidth - dialogWidth) / 2;
    const dialogY = (canvasHeight - dialogHeight) / 2;
    const dialogStroke = "#000";
    const dialogFillColor = "#333";
    const dialogPadding = canvasHeight * 0.05;
    const dialogRX = 10;
    const dialogRY = 10;

    const dialogTitleX = (canvasWidth/2);
    const dialogTitleY = dialogY + dialogPadding;

    const betButtonWidth = 100;
    const betButtonHeight = 40;
    const betButtonX = dialogX + dialogWidth - betButtonWidth - dialogPadding;
    const betButtonY = dialogY + dialogHeight - betButtonHeight - dialogPadding;
    const betButtonStroke = "black";
    const betButtonStrokeWidth = 1;
    const betButtonFill = "green";

    const resetButtonWidth = 100;
    const resetButtonHeight = 40;
    const resetButtonX = betButtonX - betButtonWidth - 10;
    const resetButtonY = betButtonY;
    const resetButtonStroke = "black";
    const resetButtonStrokeWidth = 1;
    const resetButtonFill = "red";

    const hitButtonWidth = 100;
    const hitButtonHeight = 40;
    const hitButtonX = dialogX + dialogWidth - hitButtonWidth - dialogPadding;
    const hitButtonY = dialogY + dialogHeight - hitButtonHeight - dialogPadding;
    const hitButtonStroke = "black";
    const hitButtonStrokeWidth = 1;
    const hitButtonFill = "green";

    const standButtonWidth = 100;
    const standButtonHeight = 40;
    const standButtonX = dialogX + dialogPadding;
    const standButtonY = dialogY + dialogHeight - hitButtonHeight - dialogPadding;
    const standButtonStroke = "black";
    const standButtonStrokeWidth = 1;
    const standButtonFill = "yellow";

    

    const chipButtonR = (dialogHeight * 0.1);
    const chipButtonX = dialogX + dialogPadding + (chipButtonR);
    const chipButtonY = canvasHeight * 0.5;
    const chipButtonPadding = (dialogWidth - (dialogPadding*2) - (chipButtonR*2*6)) / 5;

    const balanceTextX = dialogX + dialogPadding;
    const balanceTextY = dialogY + dialogPadding + (dialogHeight * 0.15);

    const betAmountTextX = dialogX + (dialogWidth/2);
    const betAmountTextY = dialogY + dialogPadding +  + (dialogHeight * 0.15);

    const [betDialogVisibility, setBetDialogVisibility] = useState("hidden");
    const [cardDialogVisibility, setCardDialogVisibility] = useState("hidden");
    const [balance, setBalance] = useState(1000000);
    const [betAmount, setBetAmount] = useState(0);
    const [dealerBalance, setDealerBalance] = useState(0);

    
    // HIT/STAND Card Setting
    const __hitStandCardHeight = canvasHeight * 0.7;
    const __hitStandCardWidth = (__hitStandCardHeight / 3 * 2);
    const __hitStandCardDialogHeight = canvasHeight;
    const __hitStandCardDialogWidth = canvasWidth;
    const __hitStandCardDialogX = canvasCenterX - (__hitStandCardDialogWidth/2);
    const __hitStandCardDialogY = canvasCenterY - (__hitStandCardDialogHeight/2);

    //Check Card Setting
    const __checkCardHeight = canvasHeight * 0.3;
    const __checkCardWidth = (__checkCardHeight / 3 * 2);
    const __checkCardDialogPadding = __checkCardWidth / 3;
    const __checkCardDialogTitleSize = __checkCardHeight / 4;
    const __checkCardDialogHeight = __checkCardHeight + (__checkCardDialogPadding * 2.1) + __checkCardDialogTitleSize;
    const __checkCardDialogWidth = ((__checkCardWidth * 6) + (__checkCardDialogPadding * 4));
    const __checkCardDialogX = canvasCenterX - (__checkCardDialogWidth/2);
    const __checkCardDialogY = canvasCenterY - (__checkCardDialogHeight/2);

    // Timer
    const [timer, setTimer] = useState(null);
    const [countDown, setCountDown] = useState(10);
    const timerWidthHeight = dialogHeight / 10;
    const [timerVisibility, setTimerVisibility] = useState("hidden");
    const [gameStageState, setGameStageState] = useState("");
    let gameStage = "";


    let playingOnHide = false;

    // for(let x = 0; x < 8; x++){
    //   __seats.current[x] = {};
    // }

    // ############ HELPER FUNCTIONS #################

    const spreadCard = (player) => {
      if(__seats.current[playerSeatNoRef.current].cardsSpread){
        return;
      }
      if(player == null){
        for(let p of gameRoom.current.players){
          if(p.userId == userInfo.userId){
            player = p;
          }
        }
      }
      __seats.current[playerSeatNoRef.current].cardsSpread = true;
      preventDrag = true;

      let cg = document.getElementById('cardsG');
      
      //spread cards
      if(__seats.current[playerSeatNoRef.current].cards.length == 2){
        __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("style", "opacity:1;transition: 0.2s;transform:translate(-"+(__hitStandCardWidth/2)+"px);");
        __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("style", "opacity:1;transition: 0.2s;transform:translate("+(__hitStandCardWidth/2)+"px);");

      }else if(__seats.current[playerSeatNoRef.current].cards.length == 3){
        __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("style", "opacity:1;transition: 0.2s;transform:translate(-"+(__hitStandCardWidth)+"px);");
        __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("style", "opacity:1;");
        __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("style", "opacity:1;transition: 0.2s;transform:translate("+(__hitStandCardWidth)+"px);");
      }

      if(gameRoom.current.gameStage == "SEE_DRAW_CARD" || player.isDealer){
        setTimeout(() => {
          playerCardsViewed();
          minimiseCard();
        }, 1000);
      }
      

    };



    const minimiseCard = (player) => {
      if(__seats.current[playerSeatNoRef.current].cardsMinimise){
        return;
      }
      __seats.current[playerSeatNoRef.current].cardsMinimise = true;

      if(player == null){
        for(let p of gameRoom.current.players){
          if(p.userId == userInfo.userId){
            player = p;
          }
        }
      }

      preventDrag = true;

      let cg = document.getElementById('cardsG');
      
      setCardDialogVisibility("hidden");
      setTimerVisibility("hidden");

      //put back to normal card position
      if(__seats.current[playerSeatNoRef.current].cards.length == 3){
        cg.appendChild(__seats.current[playerSeatNoRef.current].cards[2].cardFace);
      }
      cg.appendChild(__seats.current[playerSeatNoRef.current].cards[1].cardFace);
      cg.appendChild(__seats.current[playerSeatNoRef.current].cards[0].cardFace);

      __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("style", "opacity:1;transition: 0.5s;x:"+anchorPos.current[playerSeatNoRef.current].x+";y:"+anchorPos.current[playerSeatNoRef.current].y+";width:"+cardWidth+";height:"+cardHeight+";");
      __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("width", cardWidth);
      __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("height", cardHeight);
      __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("style", "opacity:1;transition: 0.5s;x:"+(anchorPos.current[playerSeatNoRef.current].x + (cardWidth/2))+";y:"+anchorPos.current[playerSeatNoRef.current].y+";width:"+cardWidth+";height:"+cardHeight+";");
      __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("width", cardWidth);
      __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("height", cardHeight);
      if(__seats.current[playerSeatNoRef.current].cards.length == 3){
        __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("style", "opacity:1;transition: 0.5s;x:"+(anchorPos.current[playerSeatNoRef.current].x + (cardWidth))+";y:"+anchorPos.current[playerSeatNoRef.current].y+";width:"+cardWidth+";height:"+cardHeight+";");
        __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("width", cardWidth);
        __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("height", cardHeight);
      }

      setTimeout((player) => {
        showTimesTextWithAnimation(player);
        if(player.isAutoWin){
          showAutoWinSignWithAnimation(player);
        }
        //clear prev cards
        let cdg = document.getElementById("cardDialogCardsG");
        cdg.innerHTML = "";
      }, 1000, player);

    };


    const createAutoWinSign = (player) => {
      let sg = document.getElementById("statusG");
      let starText = document.createElementNS("http://www.w3.org/2000/svg", "text");
      starText.setAttribute("x", (anchorPos.current[player.seatNo].x + (cardWidth*0.75)));
      starText.setAttribute("y", (anchorPos.current[player.seatNo].y + (cardHeight * 0.5)));
      starText.setAttribute("style","opacity:0;font-family:arial;font-size:"+(cardWidth)+"px;font-weight:bold;transform-origin:"+(anchorPos.current[player.seatNo].x + (cardWidth*0.75))+"px "+(anchorPos.current[player.seatNo].y + (cardHeight * 0.5))+"px;");
      starText.setAttribute("fill","#eec700");
      starText.setAttribute("dominant-baseline","middle");
      starText.setAttribute("text-anchor","middle");
      starText.innerHTML = "&starf;";
      sg.appendChild(starText);
      __seats.current[player.seatNo].autoWinSign = starText;
      
    }

    const showAutoWinSign = (player) => {
      __seats.current[player.seatNo].autoWinSign.style.opacity = 1;
    }


    const showAutoWinSignWithAnimation = (player, delay) => {
      __seats.current[player.seatNo].autoWinSign.style.opacity = 1;
      __seats.current[player.seatNo].autoWinSign.style.fontSize = (cardWidth*2);
      __seats.current[player.seatNo].autoWinSign.setAttribute("style","opacity:1;transition:1s linear ;font-family:arial;font-size:"+(cardWidth)+"px;font-weight:bold;transform:rotate(360deg);transform-origin:"+(anchorPos.current[player.seatNo].x + (cardWidth*0.75))+"px "+(anchorPos.current[player.seatNo].y + (cardHeight * 0.5))+"px;");
    }


    const createTimesText = (player) => {
      let sg = document.getElementById("statusG");
      const timesText = document.createElementNS("http://www.w3.org/2000/svg", "text");
      timesText.setAttribute("x", (anchorPos.current[player.seatNo].x + (cardWidth * 2.3)));
      timesText.setAttribute("y", (anchorPos.current[player.seatNo].y + (cardHeight)));
      timesText.setAttribute("style","opacity:0;font-family:arial;font-size:"+(cardWidth/3)+"px;font-weight:bold;");
      timesText.setAttribute("fill","#ffd700");
      timesText.setAttribute("dominant-baseline","bottom");
      timesText.setAttribute("text-anchor","end");
      timesText.innerHTML = "";//leave blank for initial empty display
      sg.appendChild(timesText);
      __seats.current[player.seatNo].timesText = timesText;
      //TO DO: to show quidrupal
      
    };

    const showTimesText = (player) => {
      if(player.isTripple == false && player.isDouble == false){
        return;
      }
      __seats.current[player.seatNo].timesText.setAttribute("x", anchorPos.current[player.seatNo].x);
      __seats.current[player.seatNo].timesText.setAttribute("y", (anchorPos.current[player.seatNo].y + (cardHeight)));
      if(player.isQuintuple){
        __seats.current[player.seatNo].timesText.innerHTML = "x5";
      }else if(player.isTripple){
        __seats.current[player.seatNo].timesText.innerHTML = "x3";
      }else if(player.isDouble){
        __seats.current[player.seatNo].timesText.innerHTML = "x2";
      }
      __seats.current[player.seatNo].timesText.setAttribute("style","opacity:1;font-family:arial;font-size:"+(cardWidth/3)+"px;font-weight:bold;");
    };

    const showTimesTextWithAnimation = (player, delay) => {
      if(player == null){
        return;
      }
      if(player.isTripple == false && player.isDouble == false){
        return;
      }
      __seats.current[player.seatNo].timesText.setAttribute("x", anchorPos.current[player.seatNo].x);
      __seats.current[player.seatNo].timesText.setAttribute("y", (anchorPos.current[player.seatNo].y + (cardHeight)));      
      // __seats.current[player.seatNo].timesText.setAttribute("style","opacity:1;font-family:arial;font-size:"+(cardWidth*3)+"px;font-weight:bold;");
      if(player.isQuintuple){
        __seats.current[player.seatNo].timesText.innerHTML = "x5";
      }else if(player.isTripple){
        __seats.current[player.seatNo].timesText.innerHTML = "x3";
      }else if(player.isDouble){
        __seats.current[player.seatNo].timesText.innerHTML = "x2";
      }
      __seats.current[player.seatNo].timesText.setAttribute("style","opacity:1;transition:0.2s linear;font-family:arial;font-size:"+(cardWidth/3)+"px;font-weight:bold;");
    };


    const hideTimesText = (player) => {
      __seats.current[player.seatNo].timesText.setAttribute("style","opacity:0;");
      
    };

    const createCardTotalText = (player) => {
      let sg = document.getElementById("statusG");

      let group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.setAttribute("style", "opacity:0;");

      let circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      circle.setAttribute("cx", anchorPos.current[player.seatNo].x - (cardWidth/4));
      circle.setAttribute("cy", anchorPos.current[player.seatNo].y + (cardWidth/4));
      circle.setAttribute("r", (cardWidth/4));
      circle.setAttribute("fill", "black");
      circle.setAttribute("style", "opacity:1;");
      group.appendChild(circle);
      
      const cardTotalText = document.createElementNS("http://www.w3.org/2000/svg", "text");
      cardTotalText.setAttribute("x", anchorPos.current[player.seatNo].x - (cardWidth/4));
      cardTotalText.setAttribute("y", anchorPos.current[player.seatNo].y + (cardWidth/4));
      cardTotalText.setAttribute("style","opacity:1;font-family:arial;font-size:"+(cardWidth/4)+"px;font-weight:bold;");
      cardTotalText.setAttribute("fill","white");
      cardTotalText.setAttribute("text-anchor","middle");
      cardTotalText.setAttribute("dominant-baseline","middle");
      cardTotalText.innerHTML = player.cardsPoint;
      group.appendChild(cardTotalText);
      
      sg.appendChild(group);
      __seats.current[player.seatNo].cardTotal = group;
    }


    const showCardTotal = (player) => {
      __seats.current[player.seatNo].cardTotal.children[1].innerHTML = player.cardsPoint;
      __seats.current[player.seatNo].cardTotal.style.opacity = 1;
    }

    const showCardTotalWithAnimation = (player, delay) => {
      __seats.current[player.seatNo].cardTotal.children[1].innerHTML = player.cardsPoint;
      __seats.current[player.seatNo].cardTotal.style = "opacity:1;transition:opacity 0.2s linear;font-family:arial;font-size:"+(cardWidth/4)+"px;font-weight:bold;";
    }



    const createCardFace = (card, x, y, opacity = 1, width = cardWidth, height = cardHeight) => {
      let cardFace1 = document.createElementNS('http://www.w3.org/1999/xhtml', 'embed');
      cardFace1.setAttribute('width', width);
      cardFace1.setAttribute('height', height);
      cardFace1.setAttribute('style', "opacity:inherit;font-family: Card;");
      cardFace1.setAttribute('src', "images/cards/"+card+".svg");

      let cardFaceObj = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
      cardFaceObj.setAttribute('x', x);
      cardFaceObj.setAttribute('y', y);
      cardFaceObj.setAttribute('width', width);
      cardFaceObj.setAttribute('height', height);
      cardFaceObj.setAttribute('style', "opacity:"+opacity+";");
      cardFaceObj.appendChild(cardFace1);
      
      return cardFaceObj;
    };



    const createCardBack = (x, y, opacity = 1, width = cardWidth, height = cardHeight) => {
      let cardBack1 = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      cardBack1.setAttribute('x', x);
      cardBack1.setAttribute('y', y);
      cardBack1.setAttribute('width', width);
      cardBack1.setAttribute('height', height);
      cardBack1.setAttribute('style', "opacity:"+opacity+";transition:0.2s linear;");
      cardBack1.setAttribute('href', "images/cards/Back.svg");
      
      return cardBack1;
    };

    const isNullOrUndefined = (obj) => {
      if(obj === null)
        return true;
      else if(obj === 'undefined')
        return true;
      else if(typeof obj === 'undefined')
        return true;
      else return false;
    };


    const showHideCardFaces = (player, show, cardNo) => {
      let fOpacity = 0;
      let bOpacity = 1;
      let fDegree = 180;
      let bDegree = 0;
      let x = 0;
      let y = 0;
      let transformX = 0;
      let transformY = 0;
      if(show){
        fOpacity = 1;
        bOpacity = 0;
        fDegree = 0;
        bDegree = 180;
      }
      if(cardNo == 0){
        x = anchorPos.current[player.seatNo].x;
        y = anchorPos.current[player.seatNo].y;
        transformX = (anchorPos.current[player.seatNo].x+(cardWidth/2));
        transformY = (anchorPos.current[player.seatNo].y+(cardHeight/2));
      }else if(cardNo == 1){
        x = (anchorPos.current[player.seatNo].x + (cardWidth/2));
        y = anchorPos.current[player.seatNo].y;
        transformX = (anchorPos.current[player.seatNo].x+(cardWidth));
        transformY = (anchorPos.current[player.seatNo].y+(cardHeight/2));
      }else if(cardNo == 2){
        x = (anchorPos.current[player.seatNo].x + (cardWidth));
        y = anchorPos.current[player.seatNo].y;
        transformX = (anchorPos.current[player.seatNo].x+(cardWidth * 1.5));
        transformY = (anchorPos.current[player.seatNo].y+(cardHeight/2));
      }
      __seats.current[player.seatNo].cards[cardNo].cardFace.setAttribute('style', "opacity:"+fOpacity+";transform-origin: "+transformX+"px "+transformY+"px;transform:rotateY("+fDegree+"deg);");
      __seats.current[player.seatNo].cards[cardNo].cardFace.setAttribute('x', x);
      __seats.current[player.seatNo].cards[cardNo].cardFace.setAttribute('y', y);

      __seats.current[player.seatNo].cards[cardNo].cardFace.children[0].setAttribute("width", cardWidth);
      __seats.current[player.seatNo].cards[cardNo].cardFace.children[0].setAttribute("height", cardHeight);

      __seats.current[player.seatNo].cards[cardNo].cardBack.setAttribute('style', "opacity:"+bOpacity+";transform-origin: "+transformX+"px "+transformY+"px;transform:rotateY("+bDegree+"deg);");
      __seats.current[player.seatNo].cards[cardNo].cardBack.setAttribute('x', x);
      __seats.current[player.seatNo].cards[cardNo].cardBack.setAttribute('y', y);
    }

    const showFirstCardFaces = (player) => {
      showHideCardFaces(player, true, 0);
    }

    const showSecondCardFaces = (player) => {
      showHideCardFaces(player, true, 1);
    }

    const showThirdCardFaces = (player) => {
      showHideCardFaces(player, true, 2);
    }


    const hideFirstCardFaces = (player) => {
      showHideCardFaces(player, false, 0);
    }

    const hideSecondCardFaces = (player) => {
      showHideCardFaces(player, false, 1);
    }

    const hideThirdCardFaces = (player) => {
      showHideCardFaces(player, false, 2);
    }

    const showAllCardFaces = (player) => {
      showFirstCardFaces(player);
      showSecondCardFaces(player);
      if(player.cards.length == 3){
        showThirdCardFaces(player);
      }
    }

    const hideAllCardFaces = (player) => {
      hideFirstCardFaces(player);
      hideSecondCardFaces(player);
      if(player.cards.length == 3){
        hideThirdCardFaces(player);
      }
    }

    const flipCardFaces = (player, delay = 0) => {
      //showAllCardFaces(player);
      //adding delay in calculation makes misbehaviour
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute('style', "opacity:1;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth/2))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(0deg);transition: 0.2s linear 0s;");
      __seats.current[player.seatNo].cards[0].cardBack.setAttribute('style', "opacity:0;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth/2))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(180deg);transition: 0.2s linear 0s;");
      
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "opacity:1;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(0deg);transition: 0.2s linear 0.2s;");
      __seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "opacity:0;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(180deg);transition: 0.2s linear 0.2s;");

      if(player.cards.length == 3){
        __seats.current[player.seatNo].cards[2].cardFace.setAttribute('style', "opacity:1;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth*1.5))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(0deg);transition: 0.2s linear 0.4s;");
        __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "opacity:0;transform-origin: "+(anchorPos.current[player.seatNo].x+(cardWidth*1.5))+"px "+(anchorPos.current[player.seatNo].y+(cardHeight/2))+"px;transform:rotateY(180deg);transition: 0.2s linear 0.4s;");
      }
      
    }

    const collapseCards = (player) => {
      //showAllCardFaces(player);

      // __seats.current[player.seatNo].cards[0].cardFace.style.transform = "translate("+cardWidth+"px, 0px)";
      // __seats.current[player.seatNo].cards[0].cardBack.style.transform = "translate("+cardWidth+"px, 0px)";
      
      __seats.current[player.seatNo].cards[1].cardFace.style.transform = "translate(-"+(cardWidth/2)+"px, 0px)";
      __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate(-"+(cardWidth/2)+"px, 0px)";

      if(player.cards.length == 3){
        __seats.current[player.seatNo].cards[2].cardFace.style.transform = "translate(-"+cardWidth+"px, 0px)";
        __seats.current[player.seatNo].cards[2].cardBack.style.transform = "translate(-"+cardWidth+"px, 0px)";
      }

      // __seats.current[player.seatNo].cards[0].cardFace.setAttribute('x', anchorPos.current[player.seatNo].x);
      // __seats.current[player.seatNo].cards[0].cardBack.setAttribute('y', anchorPos.current[player.seatNo].y);
      
      // __seats.current[player.seatNo].cards[1].cardFace.setAttribute('x', anchorPos.current[player.seatNo].x);
      // __seats.current[player.seatNo].cards[1].cardBack.setAttribute('y', anchorPos.current[player.seatNo].y);

      // if(player.cards.length == 3){
      //   __seats.current[player.seatNo].cards[2].cardFace.setAttribute('x', anchorPos.current[player.seatNo].x);
      //   __seats.current[player.seatNo].cards[2].cardBack.setAttribute('y', anchorPos.current[player.seatNo].y);
      // }
      
    }

    const collapseCardsWithAnimation = (player) => {
      __seats.current[player.seatNo].cards[1].cardFace.style.transition = "0.2s linear";
      __seats.current[player.seatNo].cards[1].cardBack.style.transition = "0.2s linear";
      __seats.current[player.seatNo].cards[1].cardFace.style.transform = "translate(-"+(cardWidth/2)+"px, 0px)";
      __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate(-"+(cardWidth/2)+"px, 0px)";

      if(player.cards.length == 3){
        __seats.current[player.seatNo].cards[2].cardFace.style.transition = "0.2s linear";
        __seats.current[player.seatNo].cards[2].cardBack.style.transition = "0.2s linear";
        __seats.current[player.seatNo].cards[2].cardFace.style.transform = "translate(-"+cardWidth+"px, 0px)";
        __seats.current[player.seatNo].cards[2].cardBack.style.transform = "translate(-"+cardWidth+"px, 0px)";
      }

      // __seats.current[player.seatNo].cards[1].cardFace.style = "transition:0.2s linear;transform:translate(-"+(cardWidth/2)+"px, 0px) rotateY(180deg;";
      // __seats.current[player.seatNo].cards[1].cardBack.style = "transition:0.2s linear;transform:translate(-"+(cardWidth/2)+"px, 0px)";
      
      // if(player.cards.length == 3){
      //   __seats.current[player.seatNo].cards[2].cardFace.style = "transition:0.2s linear;transform:translate(-"+(cardWidth)+"px, 0px) rotateY(180deg;";
      //   __seats.current[player.seatNo].cards[2].cardBack.style = "transition:0.2s linear;transform:translate(-"+(cardWidth)+"px, 0px)";
      // }
    }

    const expandCardsWithAnimation = (player) => {

      __seats.current[player.seatNo].cards[1].cardFace.style.transition = "0.2s linear";
      __seats.current[player.seatNo].cards[1].cardBack.style.transition = "0.2s linear";
      __seats.current[player.seatNo].cards[1].cardFace.style.transform = "translate(0px, 0px)";
      __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate(0px, 0px)";

      if(player.cards.length == 3){
        __seats.current[player.seatNo].cards[2].cardFace.style.transition = "0.2s linear";
        __seats.current[player.seatNo].cards[2].cardBack.style.transition = "0.2s linear";
        __seats.current[player.seatNo].cards[2].cardFace.style.transform = "translate(0px, 0px)";
        __seats.current[player.seatNo].cards[2].cardBack.style.transform = "translate(0px, 0px)";
      }
      // __seats.current[player.seatNo].cards[1].cardFace.style.transform = "translate("+(cardWidth/2)+"px, 0px)";
      // __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate("+(cardWidth/2)+"px, 0px)";
      
      // if(player.cards.length == 3){
      //   __seats.current[player.seatNo].cards[2].cardFace.style.transform = "translate("+cardWidth+"px, 0px)";
      //   __seats.current[player.seatNo].cards[2].cardBack.style.transform = "translate("+cardWidth+"px, 0px)";
      // }
    }

    const createWinLooseMarker = (player) => {
      let sg = document.getElementById("statusG");

      let winLoose = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      winLoose.setAttribute('y', anchorPos.current[player.seatNo].y + (cardHeight/3));
      winLoose.setAttribute('width', cardWidth * 2);
      winLoose.setAttribute('style', "opacity:0;");

      if(player.cards.length == 3){
        winLoose.setAttribute('x', anchorPos.current[player.seatNo].x);
      }else{
        winLoose.setAttribute('x', anchorPos.current[player.seatNo].x - (cardWidth/4));
      }

      if(player.isWin){
        winLoose.setAttribute('href', "images/winner.svg");
      }else{
        winLoose.setAttribute('href', "images/loser.svg");
      }
      sg.appendChild(winLoose);
      __seats.current[player.seatNo].winLooseMarker = winLoose;
    }

    const showWinLooseMarker = (player) => {
      __seats.current[player.seatNo].winLooseMarker.style.opacity = 1;

      if(player.cards.length == 3){
        __seats.current[player.seatNo].winLooseMarker.setAttribute('x', anchorPos.current[player.seatNo].x);
      }else{
        __seats.current[player.seatNo].winLooseMarker.setAttribute('x', anchorPos.current[player.seatNo].x - (cardWidth/4));
      }

      if(player.isWin){
        __seats.current[player.seatNo].winLooseMarker.setAttribute('href', "images/winner.svg");
      }else{
        __seats.current[player.seatNo].winLooseMarker.setAttribute('href', "images/loser.svg");
      }
    }

    const showWinLooseMarkerWithAnimation = (player, delay) => {
      

      if(player.cards.length == 3){
        __seats.current[player.seatNo].winLooseMarker.setAttribute('x', anchorPos.current[player.seatNo].x);
      }else{
        __seats.current[player.seatNo].winLooseMarker.setAttribute('x', anchorPos.current[player.seatNo].x - (cardWidth/4));
      }

      if(player.isWin){
        __seats.current[player.seatNo].winLooseMarker.setAttribute('href', "images/winner.svg");
      }else{
        __seats.current[player.seatNo].winLooseMarker.setAttribute('href', "images/loser.svg");
      }
      __seats.current[player.seatNo].winLooseMarker.setAttribute('style', "opacity:1;transition:opacity 0.2s linear;");
    }

    // ############### END ####################

    useEffect(() => {
      // setRoomId(rmId);
      // setRoomLevel(rmLevel);
      // socketEmitter.current = socket;
      //do not bind to socket because required data are not ready yet
      if(gameFinishedLoading.current == false){
        return;
      }

      socket.on('dealCard', dealCard);
      socket.on('leaveRoomPending', leaveRoomPending);
      socket.on('leaveRoom', leaveRoom);
      socket.on('joinRoom', joinRoom);
      socket.on('startRound', startRound);
      socket.on('betStart', betStart);
      socket.on('betEnd', betEnd);
      socket.on('playerBet', playerBet);
      socket.on('seeCard', seeCard);
      socket.on('placeCard', placeCard);
      socket.on('playerHitOrStand', playerHitOrStand);
      socket.on('hitOrStand', hitOrStand);
      socket.on('seeDrawCard', seeDrawCard);
      socket.on('placeDrawCard', placeDrawCard);
      socket.on('dealerHit', dealerHit);
      socket.on('seeDealerDrawCard', seeDealerDrawCard);
      socket.on('placeDealerDrawCard', placeDealerDrawCard);
      socket.on('checkCard', checkCard);
      socket.on('checkPlayer', checkPlayer);
      socket.on('gameResult', gameResult);
      socket.on('settlement', settlement);
      socket.on('endRound', endRound);
      socket.on('cardsViewed', cardsViewed);
      return () => {
        socket.off('dealCard', dealCard);
        socket.off('leaveRoomPending', leaveRoomPending);
        socket.off('leaveRoom', leaveRoom);
        socket.off('joinRoom', joinRoom);
        socket.off('startRound', startRound);
        socket.off('betStart', betStart);
        socket.off('betEnd', betEnd);
        socket.off('playerBet', playerBet);
        socket.off('seeCard', seeCard);
        socket.off('placeCard', placeCard);
        socket.off('playerHitOrStand', playerHitOrStand);
        socket.off('hitOrStand', hitOrStand);
        socket.off('seeDrawCard', seeDrawCard);
        socket.off('placeDrawCard', placeDrawCard);
        socket.off('dealerHit', dealerHit);
        socket.off('seeDealerDrawCard', seeDealerDrawCard);
        socket.off('placeDealerDrawCard', placeDealerDrawCard);
        socket.off('checkCard', checkCard);
        socket.off('checkPlayer', checkPlayer);
        socket.off('gameResult', gameResult);
        socket.off('settlement', settlement);
        socket.off('endRound', endRound);
        socket.off('cardsViewed', cardsViewed);
      };
    },[gameFinishedLoading.current]);


    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        playingOnHide = !document.getElementById("bgAudio").paused;
        document.getElementById("bgAudio").pause();
      } else {
        // Resume playing if audio was "playing on hide"
        if (playingOnHide) {
          document.getElementById("bgAudio").play();
        }
      }
    });

    let start = 0;

    const startTimer = () => {
      start = 0;
      requestAnimationFrame(startCountDown);
    };

    const startCountDown = (timeStamp) => {
      if(start == 0){
        start = timeStamp;
      }
      const lapse = timeStamp - start;
      if(lapse < 10000){
        setCountDown(10 - Math.floor(lapse/1000));
        requestAnimationFrame(startCountDown);

      }else{
        setTimerVisibility("hidden");
      }
    };

    let touchCard = null;
    let peekCard = null;
    let peekCardRect = null;
  
    let dragStart = false;
    let preventDrag = false;
    let orgX = 0;
    let orgY = 0;
    let region = "";
    let transformOrigin = "";
    let deg = 0;
    let yChange = 0;
    let direction = 1;
    let cardSpread = false;

    let moused = 0;
    const mouseDown = (e) => {
      touchCard = document.getElementById("touchCard");
      peekCard = __seats.current[playerSeatNoRef.current].cards[0].cardFace;
      peekCardRect = peekCard.getBoundingClientRect();
      deg = 0;
      dragStart = true;
      if(e.touches != null){
        if(e.touches.length == 2){
          transformOrigin = (e.touches[0].clientX)+'px '+(e.touches[0].clientY)+'px';

          orgX = e.touches[1].clientX;
          orgY = e.touches[1].clientY;
        }else{
          orgX = e.touches[0].clientX;
          orgY = e.touches[0].clientY;
          calculateRegion();
        }
        
      }else{
        orgX = e.clientX;
        orgY = e.clientY;
        calculateRegion();
      }
  
      
    }
  
    const mouseUp = () => {
      
      dragStart = false;
      if(preventDrag == false){
        reset();
      }
    }
  
    const mouseLeave = () => {
      dragStart = false;
      if(preventDrag == false){
        reset();
      }
    }
  
    const reset = () => {
      if(peekCard != null){
        touchCard.setAttribute("style", "transition: 0.5s;transform-origin:"+transformOrigin+";transform:rotate(0deg);");
        peekCard.setAttribute("style", "transition: 0.5s;transform-origin:"+transformOrigin+";transform:rotate(0deg);");
      }
      
    }
  
    const mouseMove = (e) => {
      if(dragStart && preventDrag == false){
        let x = 0;
        let y = 0;
        if(e.touches != null){
          if(e.touches.length == 2){
            x = e.touches[1].clientX;
            y = e.touches[1].clientY;
          }else{
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
          }
        }else{
          x = e.clientX;
          y = e.clientY;
        }
        
        deg = (x - orgX) / 5 * direction;
        yChange = (y - orgY) / 5;
        //display.innerHTML = display.innerHTML + e.touches[0].clientX;
        if(region == "touch"){
          if(deg > 50 || yChange > 50){//50
              spreadCard();
          }else{
            touchCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate("+deg+"px);");
            peekCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate("+deg+"px);");
          }
        }else if(region == "leftmiddle" || region == "rightmiddle"){
          if(deg > 50){//50
              spreadCard();
          }else{
            touchCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate("+deg+"px);");
            peekCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate("+deg+"px);");
          }
        }else if(region == "middletop" || region == "middlebottom"){
          if(yChange > 50){//50
            spreadCard();
          }else{
            //setDebugMsg("transforming"+yChange);
            touchCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate(0px, "+yChange+"px);");
            peekCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:translate(0px, "+yChange+"px);");
          }
        }else{
          if(deg > 30){//30
              spreadCard();
          }else{
            touchCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:rotate("+(deg*0.5)+"deg);");
            peekCard.setAttribute("style", "transform-origin:"+transformOrigin+";transform:rotate("+(deg*0.5)+"deg);");
          }
        }
      }
    }



    
  
    const calculateRegion = () => {
      if(orgX > peekCardRect.x + (peekCardRect.width*0.66)){
        region = "right";
      }else if(orgX > peekCardRect.x + (peekCardRect.width*0.33)){
        region = "middle";
      }else{
        region = "left";
      }
        if(orgY > (peekCardRect.y + (peekCardRect.height * 0.66)))
          region += "bottom";
        else if(orgY > peekCardRect.y + (peekCardRect.height * 0.33))
          region += "middle";
        else
          region += "top";
  
      if(region == "lefttop"){
        transformOrigin = (peekCardRect.x+peekCardRect.width)+'px '+(peekCardRect.y+peekCardRect.height)+'px';
        direction = 1;
      }else if(region == "leftmiddle"){
        transformOrigin = (peekCardRect.x + peekCardRect.width) +'px '+(peekCardRect.y+(peekCardRect.height/2))+'px';
        direction = 1;
      }else if(region == "leftbottom"){
        transformOrigin = (peekCardRect.x + peekCardRect.width) +'px '+peekCardRect.y+'px';
        direction = -1;
      }else if(region == "righttop"){
        transformOrigin = peekCardRect.x +'px '+(peekCardRect.y+peekCardRect.height)+'px';
        direction = 1;
      }else if(region == "rightmiddle"){
        transformOrigin = peekCardRect.x +'px '+(peekCardRect.y+(peekCardRect.height/2))+'px';
        direction = 1;
      }else if(region == "rightbottom"){
        transformOrigin = peekCardRect.x+'px '+peekCardRect.y+'px';
        direction = -1;
      }else if(region == "middletop"){
        transformOrigin = peekCardRect.x+(peekCardRect.width/2)+'px '+peekCardRect.y+(peekCardRect.height/2)+'px';
        direction = 1;
      }else if(region == "middlebottom"){
        transformOrigin = peekCardRect.x+(peekCardRect.width/2)+'px '+peekCardRect.y+(peekCardRect.height/2)+'px';
        direction = 1;
      }

      //setDebugMsg(region);
  
    }


    const switchPos = (playerSeatPos) => {
      let a = [5,6,7,0,1,2,3,4];
      let b = [];
      let i = playerSeatPos;
      for(let x = 0; x < 8; x++){
        if(i > 7){
          i = 0;
        }
        b[x] = i++;
      }
      for(let x = 0; x < 8; x++){
        seatPos.current[b[x]] = orgSeatPos[a[x]];
        anchorPos.current[b[x]] = orgAnchorPos[a[x]];
        cardPos.current[b[x]] = orgCardPos[a[x]];
      }
    };

    const playSound = () => {
      //console.log("play");
      document.getElementById("bgAudio").play();
    };

    const pauseSound = () => {
      setMenuDialogVisibility("hidden");
      document.getElementById("bgAudio").pause();
    };

    const jackpotInterval = useRef(null);

    useEffect(() => {
      
      showJackpotAmount();
      if(jackpotInterval.current === null){
        jackpotInterval.current = setInterval(() => {
          showJackpotAmount();
        },30000);
      }

      return () => {
        if(jackpotInterval.current != null){
          clearInterval(jackpotInterval.current);
          jackpotInterval.current = null;
        }
      };
    }, []);

  const showJackpotAmount = async () => {
    const response = await fetch(packageJson.server + 'api/jackpot', {
      method: 'POST',
      body: null
    });
    // Check if the response is successful (status code 200)
    if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if(responseData != null){
          if(responseData.status == "SUCCESS"){
            setJackpot(responseData.data.jackpot);
          }
        }
    } 
  };

      
    //useEffect() for ONE-TIME PROCESS
    useEffect(() => {

      socket.on('requestGameData', requestGameData);
      document.getElementById("bgAudio").play();
      showJackpotAmount();
      getGameData();
      return () => {
        socket.off('requestGameData', requestGameData);
      };
    }, []);



    //send seat plan request to get seat plans
    const getGameData = () => {
      if(socket != null && roomLevel != null && roomId != null){
        requestMessage.data = {roomLevel: roomLevel, roomId: roomId};
        //console.log("requestGameData Request");
        //console.log(requestMessage);
      
        socket.emit('requestGameData', requestMessage);
      }
    }//sendSeatPlan


    
    


    //GAME DATA
    const requestGameData = (response) => {
      if(response != null && response.data != null && response.data.room != null){
        if(response.data.room.isCustom){
          setRoomNumber(" ( Room No:"+response.data.room.roomNumber+" ) ");
        }
        let sg = document.getElementById("statusG");
        for(let player of response.data.room.players){
          if(player.userId == userInfo.userId){
            setUserInfo({...userInfo, balance: player.balance});
            playerSeatNoRef.current = player.seatNo;
            switchPos(playerSeatNoRef.current);
          }
        }
        for(let x = 0; x < 8; x++){
          __seats.current[x] = {};
        }
        //isUserNotLeaving = true;
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);
        initCards();
        drawPlayer(gameRoom.current);
        
        gameFinishedLoading.current = true;
        if(response.data.room.isPlaying == false){
          //if the game is not playing, hide loading dialog
          setLoadingDialogVisibility("hidden");
        }
        //document.getElementById("loadingDialog").style.visibility = "hidden";
      }
    };



    const initCards = () => {
      let cg = document.getElementById("cardsG");
      cg.innerHTML = "";//**** */

      for(let player of gameRoom.current.players){
        if(player.isPlaying){

          __seats.current[player.seatNo].cards = [];
          __seats.current[player.seatNo].cardsSpread = false;
          __seats.current[player.seatNo].cardsMinimise = false;
          __seats.current[player.seatNo].seatNo = player.seatNo;
          __seats.current[player.seatNo].cards[0] = {};
          __seats.current[player.seatNo].cards[1] = {};

          __seats.current[player.seatNo].cards[0].cardFace = createCardFace(player.cards[0], 
              canvasCenterX - (cardWidth/2),
              canvasCenterY  - (cardHeight/2),
            0);

          __seats.current[player.seatNo].cards[0].cardBack = createCardBack( 
              canvasCenterX - (cardWidth/2),
              canvasCenterY  - (cardHeight/2),
            1);
            //__seats.current[player.seatNo].cards[0].cardFace.append(__seats.current[player.seatNo].cards[0].cardBack);

          __seats.current[player.seatNo].cards[1].cardFace = createCardFace(player.cards[1], 
              canvasCenterX - (cardWidth/2),
              canvasCenterY  - (cardHeight/2),
            0);
            //__seats.current[player.seatNo].cards[0].cardFace.append(__seats.current[player.seatNo].cards[1].cardFace);

          __seats.current[player.seatNo].cards[1].cardBack = createCardBack(
              canvasCenterX - (cardWidth/2),
              canvasCenterY  - (cardHeight/2),
            1);

            cg.appendChild(__seats.current[player.seatNo].cards[1].cardFace);
            cg.appendChild(__seats.current[player.seatNo].cards[1].cardBack);
            cg.appendChild(__seats.current[player.seatNo].cards[0].cardFace);
            cg.appendChild(__seats.current[player.seatNo].cards[0].cardBack);
            createTimesText(player);
            createAutoWinSign(player);
            createCardTotalText(player);
            createWinLooseMarker(player);
        }

      }
    };


    //REMOVE PLAYER
    const leaveRoomPending = (response) => {
      if(response != null && response.data != null && response.data.room != null){
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);
        drawPlayer(gameRoom.current);
        }
    };

    //REMOVE PLAYER
    const leaveRoom = (response) => {
      if(response != null && response.userId != null){
        //console.log("leaveRoom Response");
        //console.log(response);
        if(response.userId == userInfo.userId){
          isUserNotLeaving.current = false;
          setTimeout(() => {
            onBack();
          }, 3000);
        }
      }
    };

    const showMenu = () => {
      setMenuDialogVisibility("visible");
    }

    const hideMenu = () => {
      setMenuDialogVisibility("hidden");
    }

    const goBack = () => {
      hideMenu();
      let ld = document.getElementById("leavingDialog");
      ld.setAttribute("style", "visibility:visible;");
      requestMessage = {
        userId: userInfo.userId,
        uer_id: userInfo.id,
        data : {
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("leaveRoom", requestMessage);
      }
    };



    const changeRoom = () => {
      requestMessage = {
        userId: userInfo.userId,
        uer_id: userInfo.id,
        data : {
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("changeRoom", requestMessage);
      }
    };



    //REMOVE PLAYER
    const joinRoom = (response) => {
      if(response != null && response.userId != null){
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);
        drawPlayer(gameRoom.current);
      }
    };



    const hit = () => {
      if(__seats.current[playerSeatNoRef.current].cardsSpread){
        //minimise cards
        if(__seats.current[playerSeatNoRef.current].cardsMinimise == false){
          playerCardsViewed();
          minimiseCard();
        }
        
      }else{
        spreadCard();
        setTimeout(() => {
          playerCardsViewed();
          minimiseCard();
        }, 500);
      }
      //drawPlayer(globalGameRoom);
      // setCardDialogVisibility("hidden");
      requestMessage = {
        userId: userInfo.userId,
        data : {
          isHitting: true,
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("hitOrStand", requestMessage);
      }
    };
      
    

    const stand = () => {
      if(__seats.current[playerSeatNoRef.current].cardsSpread){
        //minimise cards
        if(__seats.current[playerSeatNoRef.current].cardsMinimise == false){
          playerCardsViewed();
          minimiseCard();
        }
        
      }else{
        spreadCard();
        setTimeout(() => {
          playerCardsViewed();
          minimiseCard();
        }, 500);
      }
      //drawPlayer(globalGameRoom);
      // setCardDialogVisibility("hidden");
      requestMessage = {
        userId: userInfo.userId,
        data : {
          isHitting: false,
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("hitOrStand", requestMessage);
      }
      
    };


    const setDealerHitOrStand = (isHitting) => {
      //clear check buttons
      let checkg = document.getElementById("checkG");
      checkg.innerHTML = "";
      //hide hit stand menu
      document.getElementById("dealerHitStandMenu").setAttribute("style", "visibility:hidden;");
      setTimerVisibility("hidden");

      requestMessage = {
        data : {
          isHitting: isHitting,
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("dealerHitOrStand", requestMessage);
      }
    };



    const playerCardsViewed = () => {
      requestMessage = {
        userId: userInfo.userId,
        data : {
          roomId: roomId,
          roomLevel : roomLevel
        }
      }
      if(socket != null){
        socket.emit("cardsViewed", requestMessage);
      }
    };


    //when player finish viewing cards, animate cards expandation to other players
    const cardsViewed = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null){
        
        for(let player of gameRoom.current.players){
          if(player.userId != userInfo.userId && player.userId == response.data.userId && (gameRoom.current.gameStage == "SEE_CARD" || gameRoom.current.gameStage == "SEE_DRAW_CARD")){
            expandCardsWithAnimation(player);
            __seats.current[player.seatNo].cardsSpread = true;
          }
        }
      }
    };
    


    //send seat plan request to get seat plans
    const sendSeatPlan = () => {
      if(socket != null){
        requestMessage.data = {roomLevel: roomLevel, roomId: roomId};
        //console.log("seatPlan Request");
        //console.log(requestMessage);
      
        socket.emit('seatPlan', requestMessage);
      }
    }//sendSeatPlan



    const startRound = (response) => {

      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        isDealer = false;
        for(let player of response.data.room.players){
          if(player.userId == userInfo.userId){
            //userInfo.balance = player.balance;
            setUserInfo({...userInfo, balance: player.balance});
          }
          if(player.userId == userInfo.userId && player.isDealer){
            //setIsDealerState(true);
            isDealer = true;
          }
        }
        //console.log(response);
        gameRoom.current = response.data.room;
        setGameStageState("START ROUND");
        //setGlobalGameRoom(response.data.room);
        //let playerSeatNo = 0;
        for(let player of response.data.room.players){
          if(player.userId == userInfo.userId){
            //userInfo.balance = player.balance;
            setUserInfo({...userInfo, balance: player.balance});
            playerSeatNoRef.current = player.seatNo;
            //switchPos(playerSeatNo);
          }
        }
        //setGameRoom(response.data.room);
        allCardsVisible.current = false;
        drawPlayer(gameRoom.current);
        //let cg = document.getElementById("cardsG");
        //let sg = document.getElementById("statusG");
        //cg.innerHTML = "";
        //sg.innerHTML = "";
      }
    };


    
    //BET START
    const betStart = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        startTimer();
        setGameStageState("BETTING");
        gameStage = "BETTING";
        setTimerVisibility("visible");
        //gameRoom = response.data.room;
        //setGameRoom(response.data.room);
        for(let player of response.data.room.players){
          if(player.isDealer){
            setDealerBalance(player.dealerAmount);
          }
        }
        for(let player of response.data.room.players){
          if(player.userId == userInfo.userId && player.isPlaying && player.isDealer == false){
            setBalance(player.balance);
            setBetAmount(0);
            setBetDialogVisibility("visible");
          }
        }
        
      }
    };



    
    //BET END
    const betEnd = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        //set bet amount
        for(let player of response.data.room.players){
          if(player.isPlaying && player.isDealer == false){
            __seats.current[player.seatNo].betAmount.innerHTML = "$"+player.betAmount;
          }
        }

        setTimerVisibility("hidden");
        setBetDialogVisibility("hidden");
      }
      
    };



    
    //PLAYER BET
    const playerBet = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        gameRoom.current = response.data.room;
        
        __seats.current[response.data.seatNo].betAmount.innerHTML = "$" + response.data.betAmount;
      }
      
    };



    //PLAYER BET
    const setPlayerBet = () => {
      setBetDialogVisibility("hidden");
      __seats.current[playerSeatNoRef.current].betAmount.innerHTML = "$" + betAmount;
      
      requestMessage.userId = userInfo.userId;
      requestMessage.data = {
        betAmount: betAmount, 
        roomLevel: roomLevel, 
        roomId: roomId
      };
      
      //setBetDialogVisibility("hidden");
      if(socket != null){
        socket.emit('playerBet', requestMessage);
      }
    };


    const addBetAmount = (betAmt) => {
      if(betAmount+betAmt > balance){
        setBetAmount(balance);
      }else{
        setBetAmount(betAmount+betAmt);
      }
    };


    const resetBetAmount = () => {
      //console.log("resetBetAmount is called.");
      setBetAmount(0);
    };




    
    //DEAL CARD
    const dealCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && !isNullOrUndefined(response) && !isNullOrUndefined(response.data) && !isNullOrUndefined(response.data.room)){
        setLoadingDialogVisibility("hidden");
        //console.log("dealCard Response.");
        setGameStageState("DEAL CARD");
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);

        
        if(response.data.room.isWarning){
          let ag = document.getElementById("animationG");
          ag.innerHTML = "";
          const warningText = document.createElementNS("http://www.w3.org/2000/svg", "text");
          warningText.setAttribute("x", canvasCenterX);
          warningText.setAttribute("y", canvasCenterY);
          warningText.setAttribute("style","color:red;opacity:1;font-family:arial;font-size:50px;font-weight:bold;");
          warningText.setAttribute("text-anchor","middle");
          warningText.setAttribute("dominan-baseline","middle");
          warningText.setAttribute("fill","red");
          warningText.setAttribute("stroke","red");
          warningText.setAttribute("stroke-width","3");
          if(response.data.room.warningCount == 1){
            warningText.innerHTML = "1st Warning";
          }else if(response.data.room.warningCount == 2){
            warningText.innerHTML = "2nd Warning";
          }else if(response.data.room.warningCount == 3){
            warningText.innerHTML = "3rd Warning";
          }
          ag.appendChild(warningText);
          setTimeout(() => {
            warningText.setAttribute("style", "opacity:0;font-size:60px;transition:1s;transform:all;");
          }, 1500);
        }
        
        let cg = document.getElementById("cardsG");
        // let sg = document.getElementById("statusG");
        // sg.innerHTML = "";
        // cg.innerHTML = "";


        

        initCards();
        // for(let player of gameRoom.current.players){
        //   if(player.isPlaying){

        //     __seats.current[player.seatNo].cards = [];
        //     __seats.current[player.seatNo].cardsSpread = false;
        //     __seats.current[player.seatNo].cardsMinimise = false;
        //     __seats.current[player.seatNo].seatNo = player.seatNo;
        //     __seats.current[player.seatNo].cards[0] = {};
        //     __seats.current[player.seatNo].cards[1] = {};

        //     __seats.current[player.seatNo].cards[0].cardFace = createCardFace(player.cards[0], 
        //         canvasCenterX - (cardWidth/2),
        //         canvasCenterY  - (cardHeight/2),
        //       0);

        //     __seats.current[player.seatNo].cards[0].cardBack = createCardBack( 
        //         canvasCenterX - (cardWidth/2),
        //         canvasCenterY  - (cardHeight/2),
        //       1);
        //       //__seats.current[player.seatNo].cards[0].cardFace.append(__seats.current[player.seatNo].cards[0].cardBack);

        //     __seats.current[player.seatNo].cards[1].cardFace = createCardFace(player.cards[1], 
        //         canvasCenterX - (cardWidth/2),
        //         canvasCenterY  - (cardHeight/2),
        //       0);
        //       //__seats.current[player.seatNo].cards[0].cardFace.append(__seats.current[player.seatNo].cards[1].cardFace);

        //     __seats.current[player.seatNo].cards[1].cardBack = createCardBack(
        //         canvasCenterX - (cardWidth/2),
        //         canvasCenterY  - (cardHeight/2),
        //       1);

        //       cg.appendChild(__seats.current[player.seatNo].cards[1].cardFace);
        //       cg.appendChild(__seats.current[player.seatNo].cards[1].cardBack);
        //       cg.appendChild(__seats.current[player.seatNo].cards[0].cardFace);
        //       cg.appendChild(__seats.current[player.seatNo].cards[0].cardBack);
        //       createTimesText(player);
        //       if(player.isAutoWin){
        //         createAutoWinSign(player);
        //       }
        //       createCardTotalText(player);
        //       createWinLooseMarker(player);
        //       //__seats.current[player.seatNo].cards[1].cardFace.append(__seats.current[player.seatNo].cards[1].cardBack);
        //       console.log(__seats.current);
        //   }

        // }
        console.log(__seats.current);
        //reset first player pos 
        setTimeout(() => {
            let delayCount = 0;
            for(let player of gameRoom.current.players){
              if(player.isPlaying){
                //let player = response.data.room.players[firstPos];
                console.log(player.seatNo);
                console.log(__seats.current);
                //__seats.current[player.seatNo].cards[0].cardFace.setAttribute('style', "transition: 0.2s linear "+(0.2*(delayCount*1))+"s; opacity:0;transform: translate(" + (cardPos.current[player.seatNo].x) + "px," + cardPos.current[player.seatNo].y + "px)");
                __seats.current[player.seatNo].cards[0].cardBack.setAttribute('style', "transition: 0.2s linear "+(0.2*(delayCount*1))+"s; transform: translate(" + (cardPos.current[player.seatNo].x) + "px," + cardPos.current[player.seatNo].y + "px)");
                //__seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "transition: 0.2s linear "+(0.2*(delayCount*1)+(gameRoom.current.noOfPlayersPlaying * 0.2))+"s; opacity:0;transform: translate(" + (cardPos.current[player.seatNo].x + (cardWidth/2)) + "px," + cardPos.current[player.seatNo].y + "px)");
                __seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "transition: 0.2s linear "+(0.2*(delayCount*1)+ (gameRoom.current.noOfPlayersPlaying * 0.2))+"s; transform: translate(" + (cardPos.current[player.seatNo].x + (cardWidth/2)) + "px," + cardPos.current[player.seatNo].y + "px)");
                // setTimeout(() => {
                //   __seats.current[player.seatNo].cards[0].cardBack.style.transform = "translate(" + (cardPos.current[player.seatNo].x) + "px," + cardPos.current[player.seatNo].y + "px)";
                // }, (delayCount*0.2*1000));
                // setTimeout(() => {
                //   __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate(" + (cardPos.current[player.seatNo].x + (cardWidth/2)) + "px," + cardPos.current[player.seatNo].y + "px)";
                // }, (delayCount*0.2*1000) + (gameRoom.current.noOfPlayersPlaying * 0.2 * 1000));
                
                delayCount += 1;
              }
            }
        }, 100);
      }
    };



    
    //SEE CARDS
    const seeCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("SEE CARD");
        playerCardVisible.current = false;
        __cards = [];
        let cg = document.getElementById("cardsG");
        let ag = document.getElementById("animationG");
        let sg = document.getElementById("statusG");
        for(let player of response.data.room.players){
          if(player.isPlaying){
            hideAllCardFaces(player);
          }
        }
        
        //reset first player pos 
        setTimeout(() => {
            let delayCount = 0;
            for(let player of gameRoom.current.players){
              if(player.isPlaying){
                //let player = response.data.room.players[firstPos];
                //collect cards 
                //__seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate( -"+(cardWidth/2)+"px, 0px)";
                collapseCardsWithAnimation(player);
                //__seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "opacity:0;transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                if(player.userId == userInfo.userId){
                  //need time out to separate town animations
                  setTimeout(() => {
                    //draw cards to the bottom
                    __seats.current[player.seatNo].cards[0].cardBack.style.transform = "translate(0px, 300px)";
                    //__seats.current[player.seatNo].cards[0].cardFace.setAttribute('style', "opacity:0;transition: 0.2s linear ; transform: translate(0px, 300px)");
                    __seats.current[player.seatNo].cards[1].cardBack.style.transform = "translate( -"+(cardWidth/2)+"px, 300px)";
                    //__seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "opacity:0;transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 300px)");
                    
                  }, 500);  
                  //need another timeout to wait for the previous animation to finish
                  setTimeout(() => {
                    //show card peek dialog
                    let actions = document.getElementsByName("cardDialogAction");
                    if(player.isDealer){
                      for(let action of actions){
                        action.setAttribute("style", "visibility:hidden");
                      }
                      setCardDialogVisibility("visible");
                      prepareSeeCards(player);
                    }else{
                      for(let action of actions){
                        action.setAttribute("style", "visibility:inherit;");
                      }
                      setCardDialogVisibility("visible");
                      prepareSeeCards(player);
                    }
                  }, 1000);
                }
                
              }
            }
        }, 100);

        startTimer();
        setTimerVisibility("visible");
      }
    };


    const prepareSeeCards = (player) => {

      if(player == null){
        return;
      }

      
      let p = document.getElementById('cardDialogCardsG');
      p.innerHTML = "";
      preventDrag = false;
      let c = player.cards;
      //set the cards to center and attach event han©dler
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("x",  (canvasCenterX - (__hitStandCardWidth/2)));
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("y",  (canvasCenterY - (__hitStandCardHeight/2)));
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[0].cardFace.style.transform = "rotateY(0deg)";
      __seats.current[player.seatNo].cards[0].cardFace.style.opacity = 1;
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("style", "opacity:1;");
      


      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("x",  (canvasCenterX - (__hitStandCardWidth/2)));
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("y",  (canvasCenterY - (__hitStandCardHeight/2)));
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[1].cardFace.style.transform = "rotateY(0deg)";
      __seats.current[player.seatNo].cards[1].cardFace.style.opacity = 0;
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("style", "opacity:inherit;");
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("id","peekcard");

      p.appendChild(__seats.current[player.seatNo].cards[1].cardFace);
      p.appendChild(__seats.current[player.seatNo].cards[0].cardFace);

      let touchObj = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      touchObj.setAttribute('id', "touchCard");
      touchObj.setAttribute('x', (canvasCenterX - (__hitStandCardWidth/2)));
      touchObj.setAttribute('y', (canvasCenterY - (__hitStandCardHeight/2)));
      touchObj.setAttribute('width', __hitStandCardWidth);
      touchObj.setAttribute('height', __hitStandCardHeight);
      touchObj.setAttribute('fill', "transparent");

      touchObj.addEventListener("mousedown",mouseDown);
      touchObj.addEventListener("touchstart",mouseDown);
      touchObj.addEventListener("mousemove",mouseMove);
      touchObj.addEventListener("touchmove",mouseMove);
      touchObj.addEventListener("mouseup",mouseUp);
      touchObj.addEventListener("touchend",mouseUp);
      touchObj.addEventListener("mouseleave",mouseLeave);
      touchObj.addEventListener("touchcancel",mouseLeave);
      p.appendChild(touchObj);
      
      
      //make it visible after setup, to avoid flicker
      __seats.current[player.seatNo].cards[1].cardFace.style.opacity = 1;

    };



    
    //PLACE CARDS
    const placeCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        //console.log("placeCard Response");
        //console.log(response);
        gameRoom.current = response.data.room;
        setGameStageState("SEE CARD");

        let sg = document.getElementById("statusG");

        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.userId == userInfo.userId){
              if(__seats.current[playerSeatNoRef.current].cardsSpread){
                if(__seats.current[player.seatNo].cardsMinimise == false){
                  setTimeout(() => {
                    minimiseCard(player);
                    showTimesTextWithAnimation(player);
                    if(player.isAutoWin){
                      showAutoWinSignWithAnimation(player);
                    }
                  }, 500);
                }else{
                  //current user, cards are already spread
                  showFirstCardFaces(player);
                  showSecondCardFaces(player);
                  showTimesText(player);
                  if(player.isAutoWin){
                    showAutoWinSign(player);
                  }
                }
                
                
                
              }else{
                //current player, cards are not spread yet
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.style.opacity = 1;
                
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.style.opacity = 1;

                spreadCard(player);
                setTimeout(() => {
                  minimiseCard(player);
                }, 500);
              }
            }else if(player.isAutoWin){
              //player is auto win
              hideAllCardFaces(player);
              setTimeout(() => {
                expandCardsWithAnimation(player);
                setTimeout(() => {
                  flipCardFaces(player);
                  showAutoWinSignWithAnimation(player, 0);
                  if(player.isDouble){
                    showTimesTextWithAnimation(player);
                  }
                }, 500);
              }, 100);
            }else {
              //other players
              if(__seats.current[player.seatNo].cardsSpread == false){
                __seats.current[player.seatNo].cardsSpread = true;
                collapseCards(player);
                setTimeout(() => {
                  //__seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "opacity:1;transition: 0.5s linear;transform:translate("+cardWidth/2+"px, 0px);");
                  expandCardsWithAnimation(player);
                }, 100);
              }
            }
          }//playing
        }
      }
    };
    

    
    
    //HIT OR STAND CARDS
    const playerHitOrStand = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        //console.log("playerHitOrStand Response");
        //console.log(response);
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);
        
        //setGameRoom(response.data.room);
        //setUserUpdated(!userUpdated);//to update the card total text
        playerCardVisible.current = false;
      }
    };
    

    
    
    //HIT OR STAND CARDS
    const hitOrStand = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        //console.log("hitOrStand Response");
        //console.log(response);
        gameRoom.current = response.data.room;
        setGameStageState("HIT OR STAND");
        //setGlobalGameRoom(response.data.room);
        
        //setGameRoom(response.data.room);
        //setUserUpdated(!userUpdated);//to update the card total text
        playerCardVisible.current = false;
        __status = [];
        //gameRoom = response.data.room;
        //setSeats(gameRoom.seats);
        let sg = document.getElementById("statusG");
        //sg.innerHTML = "";
        let cg = document.getElementById("cardsG");
        let ag = document.getElementById("animationG");

        

        //init array
        for(let x = 0; x < 8; x++){
          __status[x] = [];
        }
        
        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.isAutoWin){
              showAllCardFaces(player);
              showAutoWinSign(player);
              if(player.isDouble){
                showTimesText(player);
              }
            }else{
              if(player.userId == userInfo.userId){
                showFirstCardFaces(player);
                showSecondCardFaces(player);
                if(player.isDouble){
                  showTimesText(player);
                }
              }else{
                //only show two cards
                hideFirstCardFaces(player);
                hideSecondCardFaces(player);
              }
              //add third card for hit user
              const statusText = document.createElementNS("http://www.w3.org/2000/svg", "text");
              statusText.setAttribute("x", (anchorPos.current[player.seatNo].x + (cardWidth * 0.75)));
              statusText.setAttribute("y", (anchorPos.current[player.seatNo].y + (cardHeight * 0.5)));
              statusText.setAttribute("style","visibility:hidden;opacity:1;font-family:arial;font-size:"+(cardHeight/6)+"px;font-weight:bold;");
              statusText.setAttribute("fill","#ffffff");
              statusText.setAttribute("dominant-baseline","middle");
              statusText.setAttribute("text-anchor","middle");
              if(player.isAutoWin == false){
                statusText.innerHTML = "တော်ပြီ";
              }
  
              const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
              rect.setAttribute("x", anchorPos.current[player.seatNo].x );
              rect.setAttribute("y", anchorPos.current[player.seatNo].y + (cardHeight * 0.5) - (cardHeight/8));
              rect.setAttribute("height", cardHeight/4);
              rect.setAttribute("fill", "url(#yellowButtonGradient)");
              rect.setAttribute("stroke","url(#yellowButtonStrokeGradient)");
              rect.setAttribute("stroke-width","5");
              rect.setAttribute("width", (cardWidth * 1.5));
              rect.setAttribute("style","visibility:hidden;opacity:1;");
              rect.setAttribute("rx",(cardHeight/8));
              rect.setAttribute("ry",(cardHeight/8));
  
              if(player.cards.length == 3){
                hideTimesText(player);
                //rect.setAttribute("width", (cardWidth * 2));
                //statusText.setAttribute("x", anchorPos.current[player.seatNo].x + (cardWidth * 1.5));
                // statusText.setAttribute("fill","#dd0000");
                // statusText.setAttribute("stroke","#ffd700");
                statusText.innerHTML = "ဆွဲမယ်";
                //card three
  
                __seats.current[player.seatNo].cards[2] = {};
  
              __seats.current[player.seatNo].cards[2].cardFace = createCardFace(player.cards[2], 
                  canvasCenterX - (cardWidth/2),
                  canvasCenterY  - (cardHeight/2),
                0);
                cg.prepend(__seats.current[player.seatNo].cards[2].cardFace);
  
              __seats.current[player.seatNo].cards[2].cardBack = createCardBack( 
                  canvasCenterX - (cardWidth/2),
                  canvasCenterY  - (cardHeight/2),
                1);
                cg.prepend(__seats.current[player.seatNo].cards[2].cardBack);
  
                //reset value
                __seats.current[player.seatNo].cardsSpread = false;
                __seats.current[player.seatNo].cardsMinimise = false;
  
              }
            __status[player.seatNo][0] = rect;
            __status[player.seatNo][1] = statusText;
            sg.appendChild(rect);
            ag.appendChild(statusText);
            }
          }
        }//for
      
        
        //reset first player pos 
        setTimeout(() => {
          let delayCount = 0;
          for(let player of response.data.room.players){
            if(player.isPlaying && player.isAutoWin == false){
              setTimeout(() => {
              //status not displayed for dealer
              if(player.isDealer == false){
                __status[player.seatNo][0].setAttribute('style', "visibility:visible;opacity:1;");
                __status[player.seatNo][1].setAttribute('style', "visibility:visible;opacity:1;font-family:arial;font-size:"+(cardHeight/6)+"px;font-weight:bold;");
                setTimeout(() => {
                  __status[player.seatNo][0].setAttribute('style', "visibility:visible;opacity:0;transition: 1s ease-out; transform: opacity;");
                  __status[player.seatNo][1].setAttribute('style', "visibility:visible;opacity:0;transition: 1s ease-out; transform: opacity;font-family:arial;font-size:"+(cardHeight/6)+"px;font-weight:bold;");
                }, 1000);
              }
              
              //cards
              if(player.cards.length == 3){
                setTimeout(() => {
                  __seats.current[player.seatNo].cards[2].cardBack.setAttribute("style", "transition:0.2s linear;transform:translate(" + ((cardPos.current[player.seatNo].x) + (cardWidth)) + "px," + cardPos.current[player.seatNo].y + "px)");
                }, 100);
              }
            }, (delayCount*0.2*1000));
              delayCount += 1;
            }//if
          }//for
        }, 100);
      }
    };

    



    //SEE CARDS
    const seeDrawCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("SEE DRAW CARD");
        playerCardVisible.current = false;
        __cards = [];
        let cg = document.getElementById("cardsG");
        //cg.innerHTML = "";//**** */

        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.userId == userInfo.userId){
              showFirstCardFaces(player);
              showSecondCardFaces(player);
              
              if(player.cards.length == 3){
                hideThirdCardFaces(player);
              }
              
            }else if(player.isAutoWin){
              showAllCardFaces(player);
              showAutoWinSign(player);
            }else{
              //other players
              hideFirstCardFaces(player);
              hideSecondCardFaces(player);
              
              if(player.cards.length == 3){
                hideThirdCardFaces(player);
              }
            }
          }
        }
        
        //reset first player pos 
        setTimeout(() => {
            let delayCount = 0;
            for(let player of gameRoom.current.players){
              if(player.isPlaying){
                //let player = response.data.room.players[firstPos];
                 //__cards[player.seatNo][0].setAttribute('style', "transition: 1.5s linear ; transform: translate(100px,100px)");
                
                if(player.cards.length == 3){
                  //__seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                  //__seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "opacity:0;transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                  if(player.userId == userInfo.userId){
                    __seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                    __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 0px)");
                    setTimeout(() => {
                      __seats.current[player.seatNo].cards[0].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate(0px, 300px)");
                      __seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 300px)");
                      __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 300px)");
                      
                    }, 500);  
                    setTimeout(() => {
                      let actions = document.getElementsByName("cardDialogAction");
                      for(let action of actions){
                        action.setAttribute("style", "visibility:hidden");
                      }
                      setCardDialogVisibility("visible");
                      prepareSeeDrawCards(player);
                    }, 1000);
                  }else{
                    //other players
                    __seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                    __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 0px)");
                    
                  }
                }
                
              }
            }
        }, 10);

        startTimer();
        setTimerVisibility("visible");
      }
    };



    const prepareSeeDrawCards = (player) => {

      if(player == null || (player.cards.length == 2 && gameRoom.current.gameStage == "SEE_DRAW_CARD") ){
        return;
      }
      let p = document.getElementById('cardDialogCardsG');
      p.innerHTML = "";
      preventDrag = false;
      let c = player.cards;
      //set the cards to center and attach event han©dler
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("x",  (canvasCenterX - (__hitStandCardWidth/2)));
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("y",  (canvasCenterY - (__hitStandCardHeight/2)));
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[0].cardFace.setAttribute("style", "opacity:1;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[0].cardFace.children[0].setAttribute("style", "opacity:1;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");

      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("x",  (canvasCenterX - (__hitStandCardWidth/2)));
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("y",  (canvasCenterY - (__hitStandCardHeight/2)));
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[1].cardFace.setAttribute("style", "opacity:0;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[1].cardFace.children[0].setAttribute("style", "opacity:1;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");
      


      __seats.current[player.seatNo].cards[2].cardFace.setAttribute("x",  (canvasCenterX - (__hitStandCardWidth/2)));
      __seats.current[player.seatNo].cards[2].cardFace.setAttribute("y",  (canvasCenterY - (__hitStandCardHeight/2)));
      __seats.current[player.seatNo].cards[2].cardFace.setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[2].cardFace.setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[2].cardFace.setAttribute("style", "opacity:1;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");
      __seats.current[player.seatNo].cards[2].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
      __seats.current[player.seatNo].cards[2].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
      __seats.current[player.seatNo].cards[2].cardFace.children[0].setAttribute("style", "opacity:1;width:"+__hitStandCardWidth+";height:"+__hitStandCardHeight+";");
      // __seats.current[player.seatNo].cards[2].cardFace.setAttribute("id","peekcard");
      
      p.appendChild(__seats.current[player.seatNo].cards[2].cardFace);
      p.appendChild(__seats.current[player.seatNo].cards[1].cardFace);
      p.appendChild(__seats.current[player.seatNo].cards[0].cardFace);


      let touchObj = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      touchObj.setAttribute('id', "touchCard");
      touchObj.setAttribute('x', (canvasCenterX - (__hitStandCardWidth/2)));
      touchObj.setAttribute('y', (canvasCenterY - (__hitStandCardHeight/2)));
      touchObj.setAttribute('width', __hitStandCardWidth);
      touchObj.setAttribute('height', __hitStandCardHeight);
      touchObj.setAttribute('fill', "transparent");

      touchObj.addEventListener("mousedown",mouseDown);
      touchObj.addEventListener("touchstart",mouseDown);
      touchObj.addEventListener("mousemove",mouseMove);
      touchObj.addEventListener("touchmove",mouseMove);
      touchObj.addEventListener("mouseup",mouseUp);
      touchObj.addEventListener("touchend",mouseUp);
      touchObj.addEventListener("mouseleave",mouseLeave);
      touchObj.addEventListener("touchcancel",mouseLeave);
      p.appendChild(touchObj);
      
      
    };



    //PLACE CARDS
    const placeDrawCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("SEE DRAW CARD");

        let sg = document.getElementById("statusG");

        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.userId == userInfo.userId){
              if(__seats.current[playerSeatNoRef.current].cardsSpread){
                //current user, cards are already spread
                
                if(__seats.current[player.seatNo].cardsMinimise == false){
                  setTimeout(() => {
                    minimiseCard(player);
                  }, 500);
                }else{
                  showAllCardFaces(player);
                  showTimesText(player);
                }
                
              }else{
                //current player, cards are not spread yet
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.style.opacity = 1;
                
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.style.opacity = 1;
                
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.style.opacity = 1;

                spreadCard(player);
                setTimeout(() => {
                  minimiseCard(player);
                }, 500);
              }
            }else if(player.isAutoWin){
              //player is auto win
              showAllCardFaces(player);
              showAutoWinSign(player);
              showTimesText(player);
            }else {
              //other players
              if(__seats.current[player.seatNo].cardsSpread == false){
                __seats.current[player.seatNo].cardsSpread = true;
                collapseCards(player);
                setTimeout(() => {
                  //__seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "opacity:1;transition: 0.5s linear;transform:translate("+cardWidth/2+"px, 0px);");
                  expandCardsWithAnimation(player);
                }, 100);
              }else{
                hideAllCardFaces(player);
              }
            }
          }//playing
        }
      }
    };

    
    //CHECK CARD
    const checkCard = (response) => {

      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        startTimer();
        setGameStageState("DEALER CHECK CARD");
        gameStage = "DEALER CHECK CARD";
        gameRoom.current = response.data.room;
        //setGlobalGameRoom(response.data.room);
        setTimerVisibility("visible");
        
        if(userInfo.id == response.data.room.dealerId){
          __cards = [];
          __status = [];
          let checkg = document.getElementById("checkG");
          checkg.innerHTML = "";
          
          //init array
          for(let x = 0; x < 8; x++){
            //declare two dimensional array
            __cards[x] = [];
            __status[x] = [];
          }


          if(userInfo.id == gameRoom.current.dealerId){
            for(let player of response.data.room.players){
              if(player.isPlaying){
                if(player.isDealer && player.id == userInfo.id){
                let menu = document.getElementById("dealerHitStandMenu");
                menu.setAttribute("style", "visibility:visible;");
              }
              if(player.isAutoWin == false && player.isDealer == false){
                //add third card for hit user
  
                const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                const statusText = document.createElementNS("http://www.w3.org/2000/svg", "text");
                statusText.setAttribute("x", seatPos.current[player.seatNo].x);
                statusText.setAttribute("y", seatPos.current[player.seatNo].y);
                statusText.setAttribute("style","visibility:visible;opacity:1;font-family:arial;font-size:"+(seatRadius/2)+"px;font-weight:bold;");
                statusText.setAttribute("fill","#fff");
                statusText.setAttribute("dominant-baseline","middle");
                statusText.setAttribute("text-anchor","middle");
                statusText.innerHTML = "ဖမ်းမယ်";
                statusText.addEventListener("click", () => {
                  statusText.setAttribute("style","visibility:hidden;");
                  rect.setAttribute("style","visibility:hidden;");
                  requestMessage = {
                    player_id : player.id,
                    data : {
                      roomLevel : roomLevel,
                      roomId : roomId
                    }
                  };
                  if(socket != null){
                    socket.emit('checkPlayer', requestMessage);
                  }
                });
  
                rect.setAttribute("x", seatPos.current[player.seatNo].x - (seatRadius*1.5));
                rect.setAttribute("y", seatPos.current[player.seatNo].y - (seatRadius/2));
                rect.setAttribute("height", seatRadius);
                rect.setAttribute("fill", "url(#yellowButtonGradient)");
                rect.setAttribute("width", (seatRadius * 3));
                rect.setAttribute("stroke","url(#yellowButtonStrokeGradient)");
                rect.setAttribute("stroke-width","5");
                rect.setAttribute("style","visibility:visible;");
                rect.setAttribute("rx",(seatRadius/2));
                rect.setAttribute("ry",(seatRadius/2));
                rect.addEventListener("click", () => {
                  statusText.setAttribute("style","visibility:hidden;");
                  rect.setAttribute("style","visibility:hidden;");
                  requestMessage = {
                    player_id : player.id,
                    data : {
                      roomLevel : roomLevel,
                      roomId : roomId
                    }
                  };
                  if(socket != null){
                    socket.emit('checkPlayer', requestMessage);
                  }
                });
  
                
              __status[player.seatNo][0] = rect;
              __status[player.seatNo][1] = statusText;
              checkg.appendChild(rect);
              checkg.appendChild(statusText);
              }
              }
            }//for
          }
        }
      }
    };



    
    const checkPlayer = (response) => {
      if(response != null && response.data != null && response.data.room != null){
        gameRoom.current = response.data.room;
        
        if(userInfo.id == response.data.room.dealerId || userInfo.id == response.player_id){
          let dialog = document.getElementById("checkDialog");
          let cardsG = document.getElementById("checkDialogCardsG");
          let dealerMenuG = document.getElementById("dealerHitStandMenu");
          cardsG.innerHTML = "";

          
          if(response.data.room.isCheckingFinish){
            dealerMenuG.setAttribute("style", "visibility:hidden;");
            setTimerVisibility("hidden");
          }else{
            //reset timer
            startTimer();
          }

          for(let player of response.data.room.players){
            //if((player.isDealer && player.id == userInfo.id) || userInfo.id == response.player_id){
              if(player.isPlaying){
                if(userInfo.id == response.data.room.dealerId || userInfo.id == response.player_id){
                  if(player.isDealer){
                    let c = player.cards;
                    let firstPos = (canvasCenterX - (__checkCardDialogWidth/4) - (__checkCardWidth));
                    if(player.cards.length == 3){
                      firstPos = (canvasCenterX - (__checkCardDialogWidth/4) - (__checkCardWidth*1.5));
                    }
                    for(let x = 0;x < c.length;x++){
                      let cardFace = createCardFace(c[x], (firstPos + (__checkCardWidth*x)),  (canvasCenterY - (__checkCardHeight/2)), 1, __checkCardWidth, __checkCardHeight);
                      cardsG.appendChild(cardFace);
                    }
                  }
        
                  if(player.id == response.player_id){
                    let c = player.cards;
                    let firstPos = (canvasCenterX + (__checkCardDialogWidth/4) - (__checkCardWidth));
                    if(player.cards.length == 3){
                      firstPos = (canvasCenterX + (__checkCardDialogWidth/4) - (__checkCardWidth*1.5));
                    }
                    for(let x = 0;x < c.length;x++){
                      let cardFace = createCardFace(c[x], (firstPos + (__checkCardWidth*x)),  (canvasCenterY - (__checkCardHeight/2)), 1, __checkCardWidth, __checkCardHeight);
                      cardsG.appendChild(cardFace);
                    }
                  }
  
                  //flip cards
                  dialog.setAttribute("style", "visibility:visible;");
                  setTimeout(() => {
                    dialog.setAttribute("style", "visibility:hidden;");
                  }, 3000);
                }
                //show card flip to all other players, except the checked player coz his card is already displayed
                if(userInfo.userId != response.player_id && response.player_id == player.id){
                  flipCardFaces(player);
                  setTimeout(() => {
                    showTimesTextWithAnimation(player);
                    showWinLooseMarkerWithAnimation(player);
                  }, 1000);
                }
              }
            //}
          }
          
        }
      }
    };




    const dealerHit = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        //console.log("hitOrStand Response");
        //console.log(response);
        gameRoom.current = response.data.room;
        setGameStageState("DEALER HIT");

        __status = [];
        //gameRoom = response.data.room;
        //setSeats(gameRoom.seats);
        let sg = document.getElementById("statusG");
        //sg.innerHTML = "";
        let cg = document.getElementById("cardsG");
        let ag = document.getElementById("animationG");
        
        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.isAutoWin){
              showAllCardFaces(player);
              showAutoWinSign(player);
              showTimesText(player);
            }else if(player.isChecked){
              showAllCardFaces(player);
              showWinLooseMarker(player);
              showTimesText(player);
            }else if(player.userId == userInfo.userId){
              showFirstCardFaces(player);
              showSecondCardFaces(player);
              if(player.isDealer){
                //don't do anything, third card is not created yet
              }else if(player.cards.length == 3){
                showThirdCardFaces(player);
              }
              showTimesText(player);
            }else if(player.isDealer){
              //only show two cards, third card is not ready yet
              hideFirstCardFaces(player);
              hideSecondCardFaces(player);

            }
            else{
              //only show two cards
              hideFirstCardFaces(player);
              hideSecondCardFaces(player);
              if(player.cards.length == 3){
                hideThirdCardFaces(player);
              }
            }

            if(player.isDealer){
              hideTimesText(player);

              __seats.current[player.seatNo].cards[2] = {};

              __seats.current[player.seatNo].cards[2].cardFace = createCardFace(player.cards[2], 
                  canvasCenterX - (cardWidth/2),
                  canvasCenterY  - (cardHeight/2),
                0);
                cg.prepend(__seats.current[player.seatNo].cards[2].cardFace);

              __seats.current[player.seatNo].cards[2].cardBack = createCardBack( 
                  canvasCenterX - (cardWidth/2),
                  canvasCenterY  - (cardHeight/2),
                1);
                cg.prepend(__seats.current[player.seatNo].cards[2].cardBack);

                //reset value
                __seats.current[player.seatNo].cardsSpread = false;
                __seats.current[player.seatNo].cardsMinimise = false;

            }
          }
        }//for
      
        
        //reset first player pos 
        setTimeout(() => {
          for(let player of response.data.room.players){
            if(player.isDealer && player.cards.length == 3){
              __seats.current[player.seatNo].cards[2].cardBack.setAttribute("style", "transition:0.2s linear;transform:translate(" + ((cardPos.current[player.seatNo].x) + (cardWidth)) + "px," + cardPos.current[player.seatNo].y + "px)");
            
            }//if
          }//for
        }, 100);
      }
    };




    //SEE CARDS
    const seeDealerDrawCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("SEE DEALER DRAW CARD");
        
        __cards = [];
        let cg = document.getElementById("cardsG");
        //cg.innerHTML = "";//**** */

        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.userId == userInfo.userId){
              showFirstCardFaces(player);
              showSecondCardFaces(player);
              if(player.isDealer){
                hideThirdCardFaces(player);
              }else if(player.cards.length == 3){
                showThirdCardFaces(player);
              }
            }else if(player.isAutoWin){
              showAllCardFaces(player);
              showAutoWinSign(player);
              showTimesText(player);
            }else if(player.isChecked){
              showAllCardFaces(player);
              showTimesText(player);
            }else{
              //other players
              hideFirstCardFaces(player);
              hideSecondCardFaces(player);
              if(player.cards.length == 3){
                hideThirdCardFaces(player);
              }
            }
          }
        }
        
        //reset first player pos 
        setTimeout(() => {
            for(let player of gameRoom.current.players){
              if(player.isDealer && player.cards.length == 3){
                if(player.userId == userInfo.userId){
                  __seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                  __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 0px)");
                  setTimeout(() => {
                    __seats.current[player.seatNo].cards[0].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate(0px, 300px)");
                    __seats.current[player.seatNo].cards[1].cardFace.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 300px)");
                    __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 300px)");
                    
                  }, 500);  
                  setTimeout(() => {
                    let actions = document.getElementsByName("cardDialogAction");
                    for(let action of actions){
                      action.setAttribute("style", "visibility:hidden");
                    }
                    setCardDialogVisibility("visible");
                    prepareSeeDrawCards(player);
                  }, 1000);
                }else if(player.isDealer){
                  //dealer
                  __seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth/2)+"px, 0px)");
                  __seats.current[player.seatNo].cards[2].cardBack.setAttribute('style', "transition: 0.2s linear ; transform: translate( -"+(cardWidth)+"px, 0px)");
                  
                }
              }
            }
        }, 10);

        startTimer();
        setTimerVisibility("visible");
      }
    };


    const placeDealerDrawCard = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("SEE DEALER DRAW CARD");

        let sg = document.getElementById("statusG");

        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(player.userId == userInfo.userId && player.isDealer){
              if(__seats.current[playerSeatNoRef.current].cardsSpread){
                //current user, cards are already spread
                
                if(__seats.current[player.seatNo].cardsMinimise == false){
                  setTimeout(() => {
                    minimiseCard(player);
                  }, 500);
                }else{
                  showAllCardFaces(player);
                  showTimesText(player);
                }
                
              }else{
                //current player, cards are not spread yet
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[0].cardFace.style.opacity = 1;
                
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[1].cardFace.style.opacity = 1;
                
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("x",  canvasCenterX - (__hitStandCardWidth/2));
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("y",  canvasCenterY - (__hitStandCardHeight/2));
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("width", __hitStandCardWidth);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.children[0].setAttribute("height", __hitStandCardHeight);
                __seats.current[playerSeatNoRef.current].cards[2].cardFace.style.opacity = 1;

                spreadCard(player);
                setTimeout(() => {
                  minimiseCard(player);
                }, 500);
              }
            }else if(player.isAutoWin){
              //player is auto win
              showAllCardFaces(player);
              showAutoWinSign(player);
              showTimesText(player);
            }else if(player.isChecked){
              showAllCardFaces(player);
              showWinLooseMarker(player);
              showTimesText(player);
            }else {
              //other players
              if(__seats.current[player.seatNo].cardsSpread == false){
                __seats.current[player.seatNo].cardsSpread = true;
                collapseCards(player);
                setTimeout(() => {
                  //__seats.current[player.seatNo].cards[1].cardBack.setAttribute('style', "opacity:1;transition: 0.5s linear;transform:translate("+cardWidth/2+"px, 0px);");
                  expandCardsWithAnimation(player);
                }, 100);
              }else{
                hideAllCardFaces(player);
              }
            }
          }//playing
        }
      }
    };



    
    //GAME RESULT
    const gameResult = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        gameRoom.current = response.data.room;
        setGameStageState("RESULT");
        setTimerVisibility("hidden");
        

        __cards = [];
        __status = [];
        let checkg = document.getElementById("checkG");
        let sg = document.getElementById("statusG");
        let menu = document.getElementById("dealerHitStandMenu");
        menu.setAttribute("style", "visibility:hidden;");
        checkg.innerHTML = "";
        //sg.innerHTML = "";

        //position cards for the current initial stage
        for(let player of response.data.room.players){
          if(player.isPlaying){
            if(response.data.room.isDealerAutoWin){
              if(player.isDealer){
                showAllCardFaces(player);
                showAutoWinSign(player);
                if(player.isDouble){
                  showTimesText(player);
                }
              }else if(player.userId == userInfo.userId){
                //myself
                showAllCardFaces(player);
                if(player.isAutoWin){
                  showAutoWinSign(player);
                }
                showTimesText(player);
              }else if(player.isAutoWin){
                //myself
                showAllCardFaces(player);
                showAutoWinSign(player);
                showTimesText(player);
              }else{
                //other players
                hideAllCardFaces(player);
              }
            }else if(response.data.room.allPlayersAuto){
              if(player.userId == userInfo.userId){
                //myself
                showAllCardFaces(player);
                showTimesText(player);
                if(player.isAutoWin){
                  showAutoWinSign(player);
                }
              }else {
                //other players
                showAllCardFaces(player);
                showAutoWinSign(player);
                showTimesText(player);
              }
            }else if(player.userId == userInfo.userId){
              showAllCardFaces(player);
              showTimesText(player);
              if(player.isAutoWin){
                showAutoWinSign(player);
              }else if(player.isChecked){
                showWinLooseMarker(player);
              }
            }else if(player.isAutoWin){
              showAllCardFaces(player);
              showAutoWinSign(player);
              showTimesText(player);
            }else if(player.isChecked){
              showAllCardFaces(player);
              showWinLooseMarker(player);
              showTimesText(player);
            }else {
              //other players
              hideAllCardFaces(player);
            }
          }
        }

        
        
        setTimeout(() => {
          let delayCount = 0;
          //use dealer seat here, to start from dealer
          let playerSeat = response.data.room.dealerSeatNo;//use dealer seat here to start from dealer
          for(let i = 0;i < 8;i++){
            if(playerSeat > 7){
              playerSeat = 0;
            }
            for(let player of response.data.room.players){
              if(player.isPlaying && player.seatNo == playerSeat){
                // if(player.seatNo != response.data.room.dealerSeatNo){
                //   //win or loose text
                //   __status[player.seatNo][0].setAttribute('style', "opacity:1;transition: 0.3s linear "+(delayCount + (0.5*delayCount))+"s; transform: opacity;");
                // }
                //card total circle
                // __status[player.seatNo][1].setAttribute('style', "opacity:1;transition: 0.3s linear "+(delayCount + (0.5*delayCount))+"s; transform: opacity;");
                // if(__status[player.seatNo][2] != null){
                //   //card total text
                //   __status[player.seatNo][2].setAttribute('style', "opacity:1;transition: 0.3s linear "+(delayCount + (0.5*delayCount))+"s; transform: opacity;font-family:arial;font-size:"+(cardHeight/4)+"px;font-weight:bold;");
                // }
                if(response.data.room.isDealerAutoWin){
                  setTimeout(() => {
                    if(player.userId == userInfo.userId && player.isDealer == false){
                      showWinLooseMarkerWithAnimation(player,0);
                    }else if(player.isDealer){

                    }else if(player.isAutoWin){
                      showWinLooseMarkerWithAnimation(player,0);
                    }else{
                      flipCardFaces(player);
                      showWinLooseMarkerWithAnimation(player,0);
                    }
                    //apply to all
                    showCardTotalWithAnimation(player, 0);
                    if(player.isDouble){
                      showTimesTextWithAnimation(player,0);
                    }
                  }, delayCount*1000);
                }else if(response.data.room.allPlayersAuto){
                  setTimeout(() => {
                    if(player.isDealer){
                      flipCardFaces(player);
                      showTimesTextWithAnimation(player, 0);
                      if(player.isDouble){
                        showTimesTextWithAnimation(player);
                      }
                    }else{
                      showWinLooseMarkerWithAnimation(player);
                      showTimesText(player);
                    }
                    showCardTotalWithAnimation(player, 0);
                  }, delayCount*1000);
                }else if(player.userId == userInfo.userId){
                  setTimeout(() => {
                    showCardTotalWithAnimation(player, 0);
                    if(player.isDealer == false){
                      showWinLooseMarkerWithAnimation(player, 0);
                    }
                  }, delayCount*1000);
                }else if(player.isAutoWin){
                  setTimeout(() => {
                    showTimesText(player);
                    showCardTotalWithAnimation(player, 0);
                    if(player.isDealer == false){
                      showWinLooseMarkerWithAnimation(player, 0);
                    }
                  }, delayCount*1000);
                }else{
                  //other players
                  setTimeout(() => {
                    flipCardFaces(player);
                    showTimesTextWithAnimation(player, 0);
                    showCardTotalWithAnimation(player, 0);
                    if(player.isDealer == false){
                      showWinLooseMarkerWithAnimation(player, 0);
                    }
                  }, delayCount*1000);
                }
                delayCount += 1;
              }//if
            }//for
            playerSeat += 1;
          }//for
        }, 100);
        
      }
    };



    
    //SETTLEMENT
    const settlement = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        setGameStageState("SETTLEMENT");
        //console.log("settlement Response");
        gameRoom.current = response.data.room;

        //update balance from server
        for(let player of response.data.room.players){
          if(player.userId == userInfo.userId){
            //userInfo.balance = player.balance;
            setUserInfo({...userInfo, balance: player.balance});
          }
        }

        //store the previous dealer balance for animation uses
        let dealer = null;
        for(let d of response.data.room.players){
          if(d.isDealer){
            dealer = d;
          }
        }
        let dealerBalance = dealer.previousDealerAmount;
        //position cards for the current initial stage
        for(let player of response.data.room.players){
          if(player.isPlaying){
            showAllCardFaces(player);
            showTimesText(player);
            showCardTotal(player);
            if(player.isDealer == false){
              showWinLooseMarker(player);
            }
          }
        }

        let __coins = [];
        __status = [];
        //clear previous state
        //let statusG = document.getElementById("statusG");
        let ag = document.getElementById("animationG");
        //cardsG.innerHTML = "";//**** */
        //statusG.innerHTML = "";

        //init array
        for(let x = 0; x < 8; x++){
          //declare two dimensional array
          __coins[x] = [];
          //__status[x] = [];
        }

        //first collect money from looser
        for(let player of response.data.room.players){
          if(player.isPlaying && player.isDealer == false){

            const statusText = document.createElementNS("http://www.w3.org/2000/svg", "text");
            statusText.setAttribute("x", seatPos.current[player.seatNo].x);
            statusText.setAttribute("y", seatPos.current[player.seatNo].y + playerTextPadding);
            statusText.setAttribute("style","visibility:hidden;opacity:1;font-family:arial;font-size:"+(playerCircleR*2)+"px;font-weight:bold;");
            statusText.setAttribute("dominant-baseline","middle");
            statusText.setAttribute("text-anchor","middle");
            statusText.innerHTML = ("$" + player.winLooseAmount);

            if(player.isWin){
              statusText.setAttribute("fill","#00dd00");
            }else{
              statusText.setAttribute("fill","#dd0000");
            }


            ag.appendChild(statusText);
            __status[player.seatNo] = statusText;

            if(player.isWin){
              for(let i = 0; i < 5;i++){
                let chip = document.createElementNS("http://www.w3.org/2000/svg", "image");
                chip.setAttribute("x", seatPos.current[gameRoom.current.dealerSeatNo].x - playerCircleR);
                chip.setAttribute("y", seatPos.current[gameRoom.current.dealerSeatNo].y + playerTextPadding);
                chip.setAttribute("width",(playerCircleR/2));
                chip.setAttribute("style","opacity:1;");
                chip.setAttribute("href","images/chip.svg");
                ag.appendChild(chip);
                __coins[player.seatNo][i] = chip;
              }
            }else{
              for(let i = 0; i < 5;i++){
                let chip = document.createElementNS("http://www.w3.org/2000/svg", "image");
                chip.setAttribute("x", seatPos.current[player.seatNo].x - playerCircleR);
                chip.setAttribute("y", seatPos.current[player.seatNo].y + playerTextPadding);
                chip.setAttribute("width", (playerCircleR/2));
                chip.setAttribute("style","opacity:1;");
                chip.setAttribute("href","images/chip.svg");
                ag.appendChild(chip);
                __coins[player.seatNo][i] = chip;
              }
            }
          }
        }//for
        
        setTimeout(() => {
          let delayCount = 0;
          let delayChip = 0;
          let playerSeat = response.data.room.firstPlayerSeatNo;
          let dealerSeatNo = response.data.room.dealerSeatNo;
          let intervals = [null,null,null,null,null,null,null,null];

          //loop for loosers
          let seatNo = "";
          for(let player of gameRoom.current.players){
            if(player.isPlaying && player.isWin == false && player.isDealer == false){
              seatNo += " X " + player.seatNo;
              delayChip = 0;
              if(__coins[player.seatNo].length > 0){
                // __status[player.seatNo].setAttribute('style', "visibility:visible;opacity:1;transition: 0s linear " + delayCount + "s;font-family:arial;font-size:"+(playerCircleR*2)+"px;font-weight:bold;");
                __status[player.seatNo].setAttribute('style', "visibility:visible;opacity:0;transition: 4s linear " + (delayCount) + "s;transform: translate(0px, -100px);font-family:arial;font-size:"+(playerCircleR*2)+"px;font-weight:bold;");
                for(let x = 0;x < 5; x++){
                  __coins[player.seatNo][x].setAttribute('style', "opacity:1;transition: 1s linear "+(delayCount + (0.1*delayChip))+"s; transform: translate(" + (seatPos.current[gameRoom.current.dealerSeatNo].x - seatPos.current[player.seatNo].x) + "px,"+ (seatPos.current[gameRoom.current.dealerSeatNo].y - seatPos.current[player.seatNo].y) +"px);");
                  delayChip += 1;
                }
  
                setTimeout(() => {
                  //let dealerAmountText = document.getElementById(("delAmt_"+gameRoom.current.dealerSeatNo));
                  // let playerAmountText = document.getElementById(("plyAmt_"+player.SeatNo));
                  // dealerBalance += player.winLooseAmountDealerDisplay;
                  //dealerAmountText.innerHTML = "$" + dealerBalance;
                  //playerAmountText.innerHTML = "$" + player.Balance;

                  let dealerInc = parseInt((player.winLooseAmountDealerDisplay/10));
                  let playerDec = parseInt((player.winLooseAmount/10));
                  let count = 0;
                  let dBal = dealerBalance;
                  let pBal = player.balance + player.winLooseAmount;
                  for(let x = 0; x < 10; x++){
                    setTimeout(() => {
                        dBal += parseInt(dealerInc);
                        pBal -= playerDec;
                        __seats.current[player.seatNo].balance.innerHTML = "$" + pBal;
                        __seats.current[gameRoom.current.dealerSeatNo].betAmount.innerHTML = "$"+dBal;
                    }, x * 100);
                  }
                  dealerBalance += player.winLooseAmountDealerDisplay;

                }, delayCount * 1000);
                delayCount += 2;
              }
            }
          }//for

          //loop for winners
          for(let player of gameRoom.current.settlementPlayers){
            if(player.isPlaying && player.isWin && player.isDealer == false){
              delayChip = 0;
              if(__coins[player.seatNo].length > 0){
                // __status[player.seatNo].setAttribute('style', "visibility:visible;opacity:1;transition: 0s linear " + delayCount + "s;font-family:arial;font-size:"+(playerCircleR*2)+"px;font-weight:bold;");
                __status[player.seatNo].setAttribute('style', "visibility:visible;opacity:0;transition: 4s linear " + (delayCount) + "s;transform: translate(0px, -100px);font-family:arial;font-size:"+(playerCircleR*2)+"px;font-weight:bold;");
                for(let x = 0;x < 5; x++){
                  __coins[player.seatNo][x].setAttribute('style', "transition: 0.5s linear "+(delayCount + (0.1*delayChip))+"s; opacity:1;transform: translate(" + (seatPos.current[player.seatNo].x - seatPos.current[gameRoom.current.dealerSeatNo].x) + "px,"+ (seatPos.current[player.seatNo].y - seatPos.current[gameRoom.current.dealerSeatNo].y) +"px);");
                  delayChip += 1;
                }
  
                setTimeout(() => {

                  let dealerDec = parseInt((player.winLooseAmountDealerDisplay/10));
                  let playerInc = parseInt((player.winLooseAmount/10));
                  let count = 0;
                  let dBal = dealerBalance;
                  let pBal = player.balance - player.winLooseAmount;
                  for(let x = 0; x < 10; x++){
                    setTimeout(() => {
                        dBal -= dealerDec;
                        pBal += parseInt(playerInc);
                        __seats.current[player.seatNo].balance.innerHTML = "$" + pBal;
                        __seats.current[gameRoom.current.dealerSeatNo].betAmount.innerHTML = "$"+dBal;
                    }, x * 100);
                  }
                  dealerBalance -= player.winLooseAmountDealerDisplay;
                }, delayCount * 1000);
                delayCount += 2;
              }
            }
          }//for
        }, 100);
        
      }
    };



    const endRound = (response) => {
      if(isUserNotLeaving.current && gameFinishedLoading.current && response != null && response.data != null && response.data.room != null){
        setLoadingDialogVisibility("hidden");
        //console.log("settlement Response");

      
        gameRoom.current = response.data.room;
        setGameStageState("END ROUND");

        let sg = document.getElementById("statusG");
        sg.innerHTML = "";

        //position cards for the current initial stage
        for(let player of response.data.room.players){
          if(player.isPlaying){
            showAllCardFaces(player);
          }
        }

        //first collect money from looser
        
        
        setTimeout(() => {
          //cover with back face
          let cardBack = createCardBack(
            canvasCenterX - (cardWidth/2),
            canvasCenterY  - (cardHeight/2),
          1);
          document.getElementById("cardsG").appendChild(cardBack);
          
          let delay = 0;
          for(let x = 0; x < __seats.current.length; x++){
            if(__seats.current[x].cards != null && __seats.current[x].cards.length > 0){
              
              __seats.current[x].cards[0].cardFace.setAttribute('style', "opacity:1;transition:0.2s linear "+(delay*0.3)+"s;x:"+(canvasCenterX - (cardWidth/2))+";y:"+(canvasCenterY-(cardHeight/2))+";");
              __seats.current[x].cards[1].cardFace.setAttribute('style', "opacity:1;transition:0.2s linear "+((delay*0.3)+0.2)+"s;x:"+(canvasCenterX - (cardWidth/2))+";y:"+(canvasCenterY-(cardHeight/2))+";");
              if(__seats.current[x].cards.length == 3){
                __seats.current[x].cards[2].cardFace.setAttribute('style', "opacity:1;transition:0.2s linear "+((delay*0.3)+0.3)+"s;x:"+(canvasCenterX - (cardWidth/2))+";y:"+(canvasCenterY-(cardHeight/2))+";");
              }
              delay += 1;
            }
          }//for

          //loop for winners
          
        }, 500);
        setTimeout(() => {
          let cg = document.getElementById("cardsG");
          let sg = document.getElementById("statusG");
          let ag = document.getElementById("animationG");
          sg.innerHTML = "";
          cg.innerHTML = "";
          ag.innerHTML = "";
        }, 1500);
        
      }
    };


    //useEffect() for player seating
    const drawPlayer = (gameRoomLocal) => {
      console.log("drawPlayer is called");
      if(isUserNotLeaving.current && gameRoomLocal != null &&__seats.current.length > 0){
        //console.log("useEffect Seat Plan is called.");
        //clear previous state
        let playerG = document.getElementById("playerG");
        // let statusG = document.getElementById("statusG");
        // let cardsG = document.getElementById("cardsG");
        //console.log(gameRoom);
        if(isUserNotLeaving.current && gameRoomLocal != null && gameRoomLocal.players.length > 0){
          //cardsG.innerHTML = "";//**** */
          playerG.innerHTML = "";
          //statusG.innerHTML = "";
          
          //console.log("X");
          for(let player of gameRoomLocal.players){
            //console.log("V");
            //seat is occupied
            let seatNo = player.seatNo;

            let avatarBg = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            if(player.userId == userInfo.userId){
              avatarBg.setAttribute("x", seatPos.current[seatNo].x - playerCircleR - 4);
              avatarBg.setAttribute("y", seatPos.current[seatNo].y - (playerCircleR) - 4);
              avatarBg.setAttribute("width", (playerCircleR * 6) + 8);
              avatarBg.setAttribute("height", (playerCircleR * 2) + 8);
            }else{
              avatarBg.setAttribute("x", seatPos.current[seatNo].x - playerCircleR - 4);
              avatarBg.setAttribute("y", seatPos.current[seatNo].y - playerCircleR - (playerCircleR));
              avatarBg.setAttribute("width", (playerCircleR * 2) + 8);
              avatarBg.setAttribute("height", (playerCircleR * 2) + 4 + (playerCircleR));
            }
            avatarBg.setAttribute("rx", 2);
            avatarBg.setAttribute("ry", 2);
            avatarBg.setAttribute("fill", "url(#avatarBg)");
            playerG.appendChild(avatarBg);

            let avatarClipPath = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            avatarClipPath.setAttribute("x", seatPos.current[seatNo].x - playerCircleR);
            avatarClipPath.setAttribute("y", seatPos.current[seatNo].y - playerCircleR);
            avatarClipPath.setAttribute("width", playerCircleR * 2);
            avatarClipPath.setAttribute("height", playerCircleR * 2);

            let clipPath = document.createElementNS("http://www.w3.org/2000/svg", "clipPath");
            clipPath.setAttribute("id", "clip-path-"+player.seatNo);
            clipPath.appendChild(avatarClipPath);
            playerG.appendChild(clipPath);

            //avatar
            let avatar = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            //avatar.setAttribute("id", "cardcover-" + x);
            avatar.setAttribute('x', seatPos.current[player.seatNo].x - (playerCircleR));
            avatar.setAttribute('y', seatPos.current[player.seatNo].y - (playerCircleR));
            avatar.setAttribute('width', (playerCircleR*2));
            avatar.setAttribute('href', "images/"+ player.avatar);
            avatar.setAttribute('clip-path', "url(#clip-path-"+player.seatNo+")");
            playerG.appendChild(avatar);


            if(player.isDealer){
              let dealerMark = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              dealerMark.setAttribute('x', seatPos.current[player.seatNo].x);
              if(player.userId == userInfo.userId){
                dealerMark.setAttribute('y', (seatPos.current[player.seatNo].y - (playerCircleR)));
              }else{
                dealerMark.setAttribute('y', (seatPos.current[player.seatNo].y - (playerCircleR*2)));
              }
              dealerMark.setAttribute('fill', "gold");
              dealerMark.setAttribute('stroke', "black");
              dealerMark.setAttribute('text-anchor', "middle");
              dealerMark.setAttribute('style', "font-size:"+(seatRadius*1.3)+"px;");
              dealerMark.innerHTML = "&#127913;";
              playerG.appendChild(dealerMark);
            }
            
            //user leaving
            if(player.isLeaving){
              const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
              rect.setAttribute("x", seatPos.current[player.seatNo].x - (seatRadius*1.5));
              rect.setAttribute("y", seatPos.current[player.seatNo].y - (seatRadius/2));
              rect.setAttribute("height", seatRadius);
              rect.setAttribute("fill", "#f00");
              rect.setAttribute("width", (seatRadius*3));
              rect.setAttribute("stroke","black");
              rect.setAttribute("style","opacity:1;");
              rect.setAttribute("rx","5");
              rect.setAttribute("ry","5");
              playerG.appendChild(rect);

              const userLeavingText = document.createElementNS("http://www.w3.org/2000/svg", "text");
              userLeavingText.setAttribute("x", seatPos.current[player.seatNo].x);
              userLeavingText.setAttribute("y", seatPos.current[player.seatNo].y);
              userLeavingText.setAttribute("style","font-family:arial;font-size:"+(seatRadius/2)+"px;font-weight:bold;");
              userLeavingText.setAttribute("fill","#fff");
              userLeavingText.setAttribute("text-anchor","middle");
              userLeavingText.setAttribute("dominant-baseline","middle");
              userLeavingText.innerHTML = "ထွက်မယ်";
              playerG.appendChild(userLeavingText);
            }
            

            //user id
            const userIdText = document.createElementNS("http://www.w3.org/2000/svg", "text");
            if(player.userId == userInfo.userId){
              userIdText.setAttribute("x", seatPos.current[player.seatNo].x + (playerCircleR*3));
              userIdText.setAttribute("y", seatPos.current[player.seatNo].y - (playerCircleR) + 4);
              userIdText.setAttribute("dominant-baseline", "hanging");
            }else{
              userIdText.setAttribute("x", seatPos.current[player.seatNo].x);
              userIdText.setAttribute("y", seatPos.current[player.seatNo].y - (playerCircleR*1.5));
            }
            userIdText.setAttribute("style","font-family:arial;font-size:"+(playerCircleR/2.5)+"px;");
            userIdText.setAttribute("fill","#fff");
            if(player.isDealer){
              userIdText.innerHTML = player.userId + "( Dealer )";
              userIdText.setAttribute("fill","#ffdd00");
            }else{
              userIdText.innerHTML = player.userId;
            }
            
            userIdText.setAttribute("text-anchor","middle");
            userIdText.setAttribute("dominant-baseline","middle");
            playerG.appendChild(userIdText);


            
            let chip = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            chip.setAttribute('x', seatPos.current[player.seatNo].x - playerCircleR);
            chip.setAttribute('y', seatPos.current[player.seatNo].y + playerTextPadding);
            chip.setAttribute('width', seatRadius/2);
            chip.setAttribute('href', "images/chip.svg");
            playerG.appendChild(chip);

            //bet amount
            const betAmtText = document.createElementNS("http://www.w3.org/2000/svg", "text");
            if(player.userId == userInfo.userId){
              betAmtText.setAttribute("x", (seatPos.current[player.seatNo].x + playerCircleR + 4));
              betAmtText.setAttribute("y", seatPos.current[player.seatNo].y);
            }else{
              betAmtText.setAttribute("x", (seatPos.current[player.seatNo].x - playerCircleR));
              betAmtText.setAttribute("y", seatPos.current[player.seatNo].y + (playerCircleR) + 2);
            }
            betAmtText.setAttribute("dominant-baseline", "hanging");
            betAmtText.setAttribute("style","font-family:arial;font-size:"+(seatRadius/2)+"px;font-weight:bold;font-color:red;");
            betAmtText.setAttribute("fill","#fff");
            if(player.isDealer){
              betAmtText.setAttribute("id",("delAmt_"+player.seatNo));
            }else{
              betAmtText.setAttribute("id",("betAmt_"+player.seatNo));
            }
            if(gameRoomLocal.gameStage != "SETTLEMENT"){
              if(player.isDealer){
                betAmtText.innerHTML = "$"+ player.dealerAmount;
              }else{
                betAmtText.innerHTML = "$"+ player.betAmount;
              }
            }else{
              if(player.isDealer){
                betAmtText.innerHTML = "$"+ player.previousDealerAmount;
              }else{
                betAmtText.innerHTML = "$"+ player.betAmount;
              }

            }
            __seats.current[player.seatNo].betAmount = betAmtText;
            playerG.appendChild(betAmtText);

            //balance amount
            let balanceAmtText = document.createElementNS("http://www.w3.org/2000/svg", "text");
            if(player.isDealer == false){
              balanceAmtText.setAttribute("id",("plyAmt_"+player.seatNo));
            }
            if(player.userId == userInfo.userId){
              balanceAmtText.setAttribute("x", (seatPos.current[player.seatNo].x + playerCircleR + 4));
              balanceAmtText.setAttribute("y", (seatPos.current[player.seatNo].y + (playerCircleR)));
              balanceAmtText.setAttribute("dominant-baseline", "auto");
            }else{
              balanceAmtText.setAttribute("x", (seatPos.current[player.seatNo].x - playerCircleR));
              balanceAmtText.setAttribute("y", (seatPos.current[player.seatNo].y + (playerCircleR * 2)));
            }
            balanceAmtText.setAttribute("style","font-family:arial;font-size:"+(seatRadius/2.5)+"px;");
            balanceAmtText.setAttribute("fill","#fd0");
            //balanceAmtText.setAttribute("text-anchor","middle");
            // if(gameRoomLocal.gameStage == "SETTLEMENT"){
            //   balanceAmtText.innerHTML = ("$"+player.previousBalance);
            // }else{
            //   balanceAmtText.innerHTML = ("$"+player.balance);
            // }
            balanceAmtText.innerHTML = ("$"+player.balance);
            
            
            __seats.current[player.seatNo].balance = balanceAmtText;
            playerG.appendChild(__seats.current[player.seatNo].balance);

          }
        }
      }
    };



    //useEffect() 2 for layout
    useEffect(() => {
      if(isUserNotLeaving.current){
        //console.log("useEffect Main is called.");
        
        const svg = svgRef.current;
        //clear previous 
        tableGRef.current.innerHTML = "";
        
  
        //set svg full screen
        svg.setAttribute("width", canvasWidth - 10);
        svg.setAttribute("height", canvasHeight - 10);
        svg.setAttribute("background-color", "#f00");
  
        //set table background color
        const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        rect.setAttribute("x", "0");
        rect.setAttribute("y", "0");
        rect.setAttribute("width", canvasWidth);
        rect.setAttribute("height", canvasHeight);
        rect.setAttribute("fill", "#160000");
        tableGRef.current.appendChild(rect);
  
        //set table center radial gradient ellipse
        const gradient = document.createElementNS('http://www.w3.org/2000/svg', 'radialGradient');
        gradient.setAttribute('id', 'ellipseGradient');
        gradient.setAttribute('cx', '50%');
        gradient.setAttribute('cy', '50%');
        gradient.setAttribute('r', '60%');
        gradient.innerHTML = `
        <stop offset="60%" style="stop-color:#000;stop-opacity:1" />
        <stop offset="100%" style="stop-color:#000;stop-opacity:0.1" />
        `;
        tableGRef.current.appendChild(gradient);
  
        const ellipse = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
        ellipse.setAttribute("rx", canvasWidth*0.5);
        ellipse.setAttribute("ry", canvasHeight * 0.5);
        ellipse.setAttribute("cx", canvasWidth/2);
        ellipse.setAttribute("cy", canvasHeight/2);
        ellipse.setAttribute('fill', 'url(#ellipseGradient)');
        //tableGRef.current.appendChild(ellipse);

        const tableBorderOutterShadow = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorderOutterShadow.setAttribute("x", rectX - rectStrokeWidth);
        tableBorderOutterShadow.setAttribute("y", rectY - rectStrokeWidth);
        tableBorderOutterShadow.setAttribute("width", rectWidth + (rectStrokeWidth * 2));
        tableBorderOutterShadow.setAttribute("height", rectHeight + rectStrokeWidth * 2);
        tableBorderOutterShadow.setAttribute("rx", rectRX * 1.1);
        tableBorderOutterShadow.setAttribute("ry", rectRY * 1.1);
        //tableBorderOutterShadow.setAttribute("stroke-width", rectStrokeWidth);
        //tableBorderOutterShadow.setAttribute("stroke", "url(#tableBorder)");
        tableBorderOutterShadow.setAttribute("fill", "url(#ellipseGradient)");
        tableGRef.current.appendChild(tableBorderOutterShadow);


        //create a table rectangle
        const tableSheet = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableSheet.setAttribute("x", rectX);
        tableSheet.setAttribute("y", rectY);
        tableSheet.setAttribute("width", rectWidth);
        tableSheet.setAttribute("height", rectHeight);
        tableSheet.setAttribute("rx", rectRX);
        tableSheet.setAttribute("ry", rectRY);
        tableSheet.setAttribute("fill", "url(#linePattern)");
        tableGRef.current.appendChild(tableSheet);

        //set table center radial gradient ellipse
        const tableSheetGradient = document.createElementNS('http://www.w3.org/2000/svg', 'radialGradient');
        tableSheetGradient.setAttribute('id', 'tableSheetGradient');
        tableSheetGradient.setAttribute('cx', '50%');
        tableSheetGradient.setAttribute('cy', '50%');
        tableSheetGradient.setAttribute('r', '50%');
        tableSheetGradient.innerHTML = `
        <stop offset="0%" style="stop-color:#d55;stop-opacity:0.5" />
        <stop offset="100%" style="stop-color:#d55;stop-opacity:0" />
        `;
        tableGRef.current.appendChild(tableSheetGradient);
  
        const tableSpotLight = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
        tableSpotLight.setAttribute("rx", rectWidth*0.4);
        tableSpotLight.setAttribute("ry", rectHeight * 0.4);
        tableSpotLight.setAttribute("cx", canvasWidth/2);
        tableSpotLight.setAttribute("cy", canvasHeight/2);
        tableSpotLight.setAttribute('fill', 'url(#tableSheetGradient)');
        tableGRef.current.appendChild(tableSpotLight);
  
        //set table background color
        const polygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
        //polygon.setAttribute("points", "50,10 80,50 50,90 20,50");
        polygon.setAttribute("points", " "+(canvasCenterX-100)+","+canvasCenterY+","+canvasCenterX+","+(canvasCenterY-30)+","+(canvasCenterX+100)+","+canvasCenterY+","+canvasCenterX+","+(canvasCenterY+30)+" ");
        polygon.setAttribute("fill", "#4a4");
        polygon.setAttribute("stroke", "none");
        polygon.setAttribute("stroke-width", "2");
        //tableGRef.current.appendChild(polygon);
  
        //create center label text
        const centerLabel = document.createElementNS("http://www.w3.org/2000/svg", "text");
        centerLabel.setAttribute("x", canvasCenterX);
        centerLabel.setAttribute("y", canvasCenterY);
        centerLabel.setAttribute("style","font-family:arial;font-size:"+(rectWidth/15)+"px;");
        centerLabel.setAttribute("fill","#722");
        centerLabel.setAttribute("stroke","#711");
        centerLabel.setAttribute("stroke-width","3");
        centerLabel.setAttribute("text-anchor","middle");
        centerLabel.setAttribute("dominant-baseline","middle");
        centerLabel.innerHTML = "7 STARS";
        tableGRef.current.appendChild(centerLabel);
  
        //create center deck of cards
        var localPNGImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        localPNGImage.setAttribute('x', canvasCenterX - (cardWidth/2) - 4);
        localPNGImage.setAttribute('y', canvasCenterY - (cardHeight/2));
        localPNGImage.setAttribute('width', cardWidth);
        localPNGImage.setAttribute('height', cardHeight);
        localPNGImage.setAttribute('href', "images/cards/Back.svg");
        tableGRef.current.appendChild(localPNGImage);
        localPNGImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        localPNGImage.setAttribute('x', canvasCenterX - (cardWidth/2) - 2);
        localPNGImage.setAttribute('y', canvasCenterY - (cardHeight/2));
        localPNGImage.setAttribute('width', cardWidth);
        localPNGImage.setAttribute('height', cardHeight);
        localPNGImage.setAttribute('href', "images/cards/Back.svg");
        tableGRef.current.appendChild(localPNGImage);
        localPNGImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        localPNGImage.setAttribute('x', canvasCenterX - (cardWidth/2));
        localPNGImage.setAttribute('y', canvasCenterY - (cardHeight/2));
        localPNGImage.setAttribute('width', cardWidth);
        localPNGImage.setAttribute('height', cardHeight);
        localPNGImage.setAttribute('href', "images/cards/Back.svg");
        tableGRef.current.appendChild(localPNGImage);

        // const backButton = document.createElementNS("http://www.w3.org/2000/svg", "image");
        // backButton.setAttribute("x", canvasWidth/40);
        // backButton.setAttribute("y", canvasWidth/40);
        // backButton.setAttribute("width", canvasWidth/40);
        // backButton.setAttribute("height", canvasWidth/40);
        // backButton.setAttribute("href", "/images/MenuIcon.svg");
        // backButton.addEventListener('click', () => {
        //   goBack();
        // });
        // tableGRef.current.appendChild(backButton);

        // const menuButton = document.createElementNS("http://www.w3.org/2000/svg", "image");
        // menuButton.setAttribute("x", canvasWidth - (canvasWidth/40));
        // menuButton.setAttribute("y", canvasWidth/40);
        // menuButton.setAttribute("width", canvasWidth/40);
        // menuButton.setAttribute("height", canvasWidth/40);
        // menuButton.setAttribute("href", "/images/MenuIcon.svg");
        // menuButton.addEventListener('click', () => {
        //   showMenu();
        // });
        // tableGRef.current.appendChild(menuButton);

        for(let x = 0; x < 8; x++){
          const seat1 = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          seat1.setAttribute("cx", seatPos.current[x].x - (seatRadius/2));
          seat1.setAttribute("cy", seatPos.current[x].y - (seatRadius/2));
          seat1.setAttribute("r", seatRadius);
          seat1.setAttribute("stroke", seatStrokeColor);
          seat1.setAttribute("stroke-width", seatStrokeWidth);
          seat1.setAttribute("fill", seatFillColor);
          //tableGRef.current.appendChild(seat1);
        }

        const tableBorderInnerShade = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorderInnerShade.setAttribute("x", rectX + (rectStrokeWidth * 0.75));
        tableBorderInnerShade.setAttribute("y", rectY + (rectStrokeWidth * 0.75));
        tableBorderInnerShade.setAttribute("width", rectWidth - (rectStrokeWidth * 1.5));
        tableBorderInnerShade.setAttribute("height", rectHeight - (rectStrokeWidth * 1.5));
        tableBorderInnerShade.setAttribute("rx", rectRX * 0.9);
        tableBorderInnerShade.setAttribute("ry", rectRY * 0.9);
        tableBorderInnerShade.setAttribute("stroke-width", tableBorderShadowStrokeWidth);
        tableBorderInnerShade.setAttribute("stroke", "#622");
        tableBorderInnerShade.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableBorderInnerShade);

        const tableBorderInnerShadow = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorderInnerShadow.setAttribute("x", rectX + (rectStrokeWidth * 0.5));
        tableBorderInnerShadow.setAttribute("y", rectY + (rectStrokeWidth * 0.5));
        tableBorderInnerShadow.setAttribute("width", rectWidth - (rectStrokeWidth * 1));
        tableBorderInnerShadow.setAttribute("height", rectHeight - (rectStrokeWidth * 1));
        tableBorderInnerShadow.setAttribute("rx", rectRX * 0.9);
        tableBorderInnerShadow.setAttribute("ry", rectRY * 0.9);
        tableBorderInnerShadow.setAttribute("stroke-width", tableBorderShadowStrokeWidth);
        tableBorderInnerShadow.setAttribute("stroke", "url(#tableBorderInnerShadow)");
        tableBorderInnerShadow.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableBorderInnerShadow);
  
        //create a table rectangle
        const tableBorderRing = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorderRing.setAttribute("x", rectX + 1);
        tableBorderRing.setAttribute("y", rectY + 1);
        tableBorderRing.setAttribute("width", rectWidth - 2);
        tableBorderRing.setAttribute("height", rectHeight - 2);
        tableBorderRing.setAttribute("rx", rectRX);
        tableBorderRing.setAttribute("ry", rectRY);
        tableBorderRing.setAttribute("stroke-width", rectStrokeWidth);
        tableBorderRing.setAttribute("stroke", "url(#tableBorderRing)");
        tableBorderRing.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableBorderRing);
  
        //create a table rectangle
        const tableBorder = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorder.setAttribute("x", rectX);
        tableBorder.setAttribute("y", rectY);
        tableBorder.setAttribute("width", rectWidth);
        tableBorder.setAttribute("height", rectHeight);
        tableBorder.setAttribute("rx", rectRX);
        tableBorder.setAttribute("ry", rectRY);
        tableBorder.setAttribute("stroke-width", rectStrokeWidth);
        tableBorder.setAttribute("stroke", "url(#tableBorderPattern)");
        tableBorder.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableBorder);
  
        //create a table rectangle
        const tableBorderGradient = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableBorderGradient.setAttribute("x", rectX);
        tableBorderGradient.setAttribute("y", rectY);
        tableBorderGradient.setAttribute("width", rectWidth);
        tableBorderGradient.setAttribute("height", rectHeight);
        tableBorderGradient.setAttribute("rx", rectRX);
        tableBorderGradient.setAttribute("ry", rectRY);
        tableBorderGradient.setAttribute("stroke-width", rectStrokeWidth);
        tableBorderGradient.setAttribute("stroke", "url(#tableBorderGradient)");
        tableBorderGradient.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableBorderGradient);

         //create a table rectangle
         const tableBorderReflection = document.createElementNS("http://www.w3.org/2000/svg", "rect");
         tableBorderReflection.setAttribute("x", rectX - (rectStrokeWidth/2));
         tableBorderReflection.setAttribute("y", rectY - (rectStrokeWidth/2));
         tableBorderReflection.setAttribute("width", rectWidth + (rectStrokeWidth));
         tableBorderReflection.setAttribute("height", rectHeight + (rectStrokeWidth));
         tableBorderReflection.setAttribute("rx", rectRX * 1.1);
         tableBorderReflection.setAttribute("ry", rectRY * 1.1);
         tableBorderReflection.setAttribute("stroke-width", tableBorderReflectionStrokeWidth);
         tableBorderReflection.setAttribute("stroke", "url(#tableBorderReflection)");
         tableBorderReflection.setAttribute("fill", "none");
         tableGRef.current.appendChild(tableBorderReflection);

         
  
        //create a table inner rectangle
        const tableInnerRect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        tableInnerRect.setAttribute("x", innerRectX);
        tableInnerRect.setAttribute("y", innerRectY);
        tableInnerRect.setAttribute("width", innerRectWidth);
        tableInnerRect.setAttribute("height", innerRectHeight);
        tableInnerRect.setAttribute("rx", rectRX * 0.8);
        tableInnerRect.setAttribute("ry", rectRY * 0.8);
        tableInnerRect.setAttribute("stroke-width", innerRectStrokeWidth);
        tableInnerRect.setAttribute("stroke", innerRectStroke);
        tableInnerRect.setAttribute("fill", "none");
        tableGRef.current.appendChild(tableInnerRect);
  
        //prepareSeats();
  
       
      }

    },[]);
  
    
       
  
    return (
        <>
        <audio id="bgAudio" autoPlay loop>
          <source src="music.mp3" type="audio/mpeg" />
        </audio>
        <svg id="svg" ref={svgRef}>
          <defs>

        <style>
          {/*             
            @font-face {
                font-family: "Card";
                src: url("CardCharactersNarrowFigures.ttf") format("truetype");
                }
            text{
                font-family: "Card";
                fill: #000;
            } 
             */}
        </style>

          <pattern id="linePattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect x="0" y="0" width="10" height="10" fill="#802020" />
      <line x1="0" y1="0" x2="10" y2="10" stroke="#7a1a1a" strokeWidth="2" />
    </pattern>


<pattern id="tableBorderPattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
<rect x="0" y="0" width="10" height="10" fill="#090909" />

  <line x1="1" y1="0" x2="9" y2="10.0" stroke="#000" strokeWidth="1" />
  <line x1="0" y1="3" x2="9" y2="0" stroke="#000" strokeWidth="1" />
  <line x1="1" y1="10.0" x2="10.0" y2="7" stroke="#000" strokeWidth="1" />
</pattern>
            <clipPath id="betButton1ClipPath">
              <circle cx={chipButtonX} cy={chipButtonY} r={chipButtonR} />
            </clipPath>
            <clipPath id="betButton2ClipPath">
              <circle cx={chipButtonX + (chipButtonR*2) + chipButtonPadding} cy={chipButtonY} r={chipButtonR} />
            </clipPath>
            <clipPath id="betButton3ClipPath">
              <circle cx={chipButtonX + (chipButtonR*4) + (chipButtonPadding*2)} cy={chipButtonY} r={chipButtonR} />
            </clipPath>
            <clipPath id="betButton4ClipPath">
              <circle cx={chipButtonX + (chipButtonR*6) + (chipButtonPadding*3)} cy={chipButtonY} r={chipButtonR} />
            </clipPath>
            <clipPath id="betButton5ClipPath">
              <circle cx={chipButtonX + (chipButtonR*8) + (chipButtonPadding*4)} cy={chipButtonY} r={chipButtonR} />
            </clipPath>

          <linearGradient id="avatarBg" x1="100%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{stopColor : "#6699aa"}} />
            <stop offset="80%" style={{stopColor : "#000033"}} />
          </linearGradient>
          <linearGradient id="titleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ffdd99"}} />
            <stop offset="100%" style={{stopColor : "#dd8800"}} />
          </linearGradient>
          <linearGradient id="titleStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#aa8800"}} />
            <stop offset="70%" style={{stopColor : "#000000"}} />
          </linearGradient>
          <linearGradient id="tableBorderGradient" x1="50%" y1="100%" x2="50%" y2="0%">
            <stop offset="0%" style={{stopColor : "rgba(222,222,222,0.1)"}} />
            <stop offset="100%" style={{stopColor : "rgba(222,222,222,0.05)"}} />
          </linearGradient>
          <linearGradient id="tableBorderRing" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="0%" style={{stopColor : "#a22"}} />
            <stop offset="50%" style={{stopColor : "#622"}} />
            <stop offset="100%" style={{stopColor : "#a22"}} />
          </linearGradient>
          <linearGradient id="tableBorderReflection" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#000"}} />
            <stop offset="100%" style={{stopColor : "#555"}} />
          </linearGradient>
          <linearGradient id="tableBorderInnerShadow" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#000"}} />
            <stop offset="100%" style={{stopColor : "#060606"}} />
          </linearGradient>
          <linearGradient id="redButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#dd0000"}} />
            <stop offset="100%" style={{stopColor : "#aa0000"}} />
          </linearGradient>
          <linearGradient id="redButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#990000"}} />
            <stop offset="70%" style={{stopColor : "#330000"}} />
          </linearGradient>
          <linearGradient id="greenButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#00aa00"}} />
            <stop offset="100%" style={{stopColor : "#008800"}} />
          </linearGradient>
          <linearGradient id="greenButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#008800"}} />
            <stop offset="70%" style={{stopColor : "#003300"}} />
          </linearGradient>
          <linearGradient id="yellowButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ddbb00"}} />
            <stop offset="100%" style={{stopColor : "#aa7700"}} />
          </linearGradient>
          <linearGradient id="yellowButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ccaa00"}} />
            <stop offset="70%" style={{stopColor : "#996600"}} />
          </linearGradient>
          </defs>
          <g ref={tableGRef}></g>
          <g visibility={timerVisibility} >
          {/* <text x={canvasCenterX} y={canvasCenterY - (timerWidthHeight*1.5)} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={timerWidthHeight/2} fontWeight={"bold"} > */}
          <text x={canvasCenterX} y={canvasCenterY - (timerWidthHeight*1.5)} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={timerWidthHeight/2} fontWeight={"bold"} >
              {gameStageState}
          </text>
          <circle cx={canvasCenterX} cy={canvasCenterY + (cardHeight*0.6) + (timerWidthHeight/2)} r={timerWidthHeight/2} fill="#f00" stroke="#a00" strokeWidth={3}></circle>
            <text x={canvasCenterX} y={canvasCenterY + (cardHeight*0.6) + (timerWidthHeight/2)} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={timerWidthHeight/2} fontWeight={"bold"} >
              {countDown}
            </text>
          </g>
          <g id="playerG"></g>
          <g id="playerMoneyG"></g>
          <g id="cardsG"></g>
          <g id="statusG"></g>
          <g id="checkG"></g>
          <g id="animationG" ref={animationG}></g>

          <image onClick={() => showMenu()} href="images/menuicon.svg" width={canvasHeight/10} height={canvasHeight/10} x={10} y={10} />

        
        <rect x={canvasWidth - (jackPotBGWidth * 1.5)} y={(jackPotBGHeight/2)} rx={jackPotBGHeight/2} ry={jackPotBGHeight/2} width={jackPotBGWidth} height={jackPotBGHeight} fill="url(#titleGradient)" stroke="url(#titleStrokeGradient)" strokeWidth="5" />
        <text x={canvasWidth - (jackPotBGWidth)} y={(jackPotBGHeight*1)} fill="black" dominantBaseline={"middle"} textAnchor={'middle'}  fontSize={jackPotMoneyFontSize} fontWeight={"bold"}   >
        🏆 $ {jackpot}
        </text>
        <text x={canvasWidth - (jackPotBGWidth) - 10} y={ (jackPotBGHeight) + jackPotBGHeight} dominantBaseline={"hanging"} textAnchor="start" fill="#fff" stroke='#700' strokeWidth={jackPotFontSize/15} fontFamily='impact' fontSize={jackPotFontSize} fontWeight={"bold"} fontStyle={"italic"}  >
          JACK POT
        </text>
        <text x={10} y={ (canvasHeight - 10)} dominantBaseline={"auto"}   fill="#fff" stroke='#700' strokeWidth={jackPotFontSize/15} fontFamily='impact' fontSize={jackPotFontSize} fontWeight={"bold"} fontStyle={"italic"}  >
          Level : {roomName} {roomNumber}
        </text>
          <g id="betDialog" visibility={betDialogVisibility} >
            <rect x={dialogX} y={dialogY} rx={dialogRX} ry={dialogRY} width={dialogWidth} height={dialogHeight} fill={dialogFillColor} stroke={dialogStroke} strokeWidth={dialogStrokeWidth}/>
            <text x={dialogTitleX} y={dialogTitleY} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={dialogHeight/15}>
              BETTING
            </text>
            <circle cx={dialogX + timerWidthHeight} cy={dialogY + timerWidthHeight} r={timerWidthHeight/2} fill="#f00" stroke="#a00" strokeWidth={3}></circle>
            <text x={dialogX + timerWidthHeight} y={dialogY + timerWidthHeight} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={timerWidthHeight/2} fontWeight={"bold"} >
              {countDown}
            </text>
            <text x={dialogX + dialogPadding} y={balanceTextY}  fill="white" fontSize={dialogWidth/50}>
              Dealer Balance: ${dealerBalance}
            </text>
            <text x={canvasCenterX} y={balanceTextY}  fill="white" textAnchor="middle" fontSize={dialogWidth/50}>
              Player Balance: ${balance}
            </text>
            <text x={canvasCenterX + (dialogWidth/4)} y={betAmountTextY} fill="white" fontSize={dialogWidth/50}>
              Bet Amount: ${betAmount}
            </text>
            <image onClick={() => addBetAmount(100)}  href="images/chip1.svg" width={chipButtonR*2} x={chipButtonX - chipButtonR} y={chipButtonY-chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            <image onClick={() => addBetAmount(200)}  href="images/chip2.svg" width={chipButtonR*2} x={chipButtonX + (chipButtonR) + chipButtonPadding} y={chipButtonY - chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            <image onClick={() => addBetAmount(500)}  href="images/chip3.svg" width={chipButtonR*2} x={chipButtonX + (chipButtonR*3) + (chipButtonPadding*2)} y={chipButtonY-chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            <image onClick={() => addBetAmount(1000)}  href="images/chip4.svg" width={chipButtonR*2} x={chipButtonX + (chipButtonR*5) + (chipButtonPadding*3)} y={chipButtonY - chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            <image onClick={() => addBetAmount(10000)}  href="images/chip4.svg" width={chipButtonR*2} x={chipButtonX + (chipButtonR*7) + (chipButtonPadding*4)} y={chipButtonY - chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            <image onClick={() => addBetAmount(100000000)}  href="images/chip5.svg" width={chipButtonR*2} x={chipButtonX + (chipButtonR*9) + (chipButtonPadding*5)} y={chipButtonY - chipButtonR} height={chipButtonR*2} stroke="green" strokeWidth="3"  />
            
            <text onClick={() => addBetAmount(100)} x={chipButtonX} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              100
            </text>
            <text onClick={() => addBetAmount(200)} x={chipButtonX + (chipButtonR*2) + chipButtonPadding} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              200
            </text>
            <text onClick={() => addBetAmount(500)} x={chipButtonX + (chipButtonR*4) + (chipButtonPadding*2)} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              500
            </text>
            <text onClick={() => addBetAmount(1000)} x={chipButtonX + (chipButtonR*6) + (chipButtonPadding*3)} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              1000
            </text>
            <text onClick={() => addBetAmount(10000)} x={chipButtonX + (chipButtonR*8) + (chipButtonPadding*4)} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              10,000
            </text>
            <text onClick={() => addBetAmount(100000000)} x={chipButtonX + (chipButtonR*10) + (chipButtonPadding*5)} y={chipButtonY} height={chipButtonR*2}  fill="white" fontSize={chipButtonR/3} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              MAX
            </text>

            <foreignObject x={dialogX + dialogPadding} y={chipButtonY + (chipButtonR * 2)} width={dialogWidth - (dialogPadding*2)} height={50}>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
              MIN&nbsp;
              <Slider aria-label="Volume" value={betAmount} min={0} max={balance} valueLabelDisplay="on" onChange={(e, newValue) => setBetAmount(newValue)} />
              &nbsp;MAX
            </Stack>
            </foreignObject>

            <rect onClick={() => resetBetAmount()} x={resetButtonX} y={resetButtonY} rx={resetButtonHeight/2} ry={resetButtonHeight/2} width={resetButtonWidth} height={resetButtonHeight} fill="url(#redButtonGradient)" stroke="url(#redButtonStrokeGradient)" strokeWidth={5} />
            <text onClick={() => resetBetAmount()} x={resetButtonX+(resetButtonWidth/2)} y={resetButtonY+(resetButtonHeight/2)} height={chipButtonR*2}  fill="white" fontSize={resetButtonHeight/2} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              RESET
            </text>

            <rect onClick={() => setPlayerBet()}   x={betButtonX} y={betButtonY} rx={resetButtonHeight/2} ry={resetButtonHeight/2} width={betButtonWidth} height={betButtonHeight} fill="url(#greenButtonGradient)" stroke="url(#greenButtonStrokeGradient)" strokeWidth={5} />
            <text onClick={() => setPlayerBet()}   x={betButtonX+(betButtonWidth/2)} y={betButtonY+(betButtonHeight/2)} fill="white" fontSize={betButtonHeight/2} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              BET
            </text>
          </g>

          <g id="cardDialog" visibility={cardDialogVisibility} >
          
            <rect id="cardDialogBg" x={0} y={0} rx={dialogRX} ry={dialogRY} width={canvasWidth} height={canvasHeight} fill="rgba(0,0,0,0.5)" stroke={dialogStroke} strokeWidth={dialogStrokeWidth}/>
            <circle cx={dialogX + timerWidthHeight} cy={dialogY + timerWidthHeight} r={timerWidthHeight/2} fill="#f00" stroke="#a00" strokeWidth={3}></circle>
            <text x={dialogX + timerWidthHeight} y={dialogY + timerWidthHeight} dominantBaseline="middle" textAnchor="middle" fill="white" fontSize={timerWidthHeight/2} fontWeight={"bold"} >
              {countDown}
            </text>
            <line x1={dialogX} y1={dialogY + dialogTitleX + 100}  x2={dialogX + dialogWidth} y2={dialogY + dialogTitleX + 100} strokeWidth={3} stroke="#f00" />
            
            
            
            
            <rect name="cardDialogAction" onClick={() => hit()} x={hitButtonX} y={hitButtonY} rx={hitButtonHeight/2} ry={hitButtonHeight/2} width={hitButtonWidth} height={hitButtonHeight} fill="url(#greenButtonGradient)" stroke="url(#greenButtonStrokeGradient)" strokeWidth={5} />
            <text name="cardDialogAction" onClick={() => hit()} x={hitButtonX+(hitButtonWidth/2)} y={hitButtonY+(hitButtonHeight/2)}  fill="#fff" fontSize="15" fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              ဆွဲမယ်
            </text>

            <rect name="cardDialogAction" onClick={() => stand()}   x={standButtonX} y={standButtonY} rx={standButtonHeight/2} ry={standButtonHeight/2} width={standButtonWidth} height={standButtonHeight} fill="url(#redButtonGradient)" stroke="url(#redButtonStrokeGradient)" strokeWidth={5} />
            <text name="cardDialogAction" onClick={() => stand()}   x={standButtonX+(standButtonWidth/2)} y={standButtonY+(standButtonHeight/2)} fill="#fff" fontSize="15" fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              တော်ပြီ
            </text>
          </g>

          <g id="dealerHitStandMenu" visibility={"hidden"}>
            <rect onClick={() => setDealerHitOrStand(true)} x={canvasCenterX-hitButtonWidth-10} y={canvasCenterY-(hitButtonHeight/2)} rx={hitButtonHeight/2} ry={hitButtonHeight/2} width={hitButtonWidth} height={hitButtonHeight} fill="url(#greenButtonGradient)" stroke="url(#greenButtonStrokeGradient)" strokeWidth={5} />
            <text onClick={() => setDealerHitOrStand(true)} x={canvasCenterX - (hitButtonWidth/2) - 10} y={canvasCenterY}  fill="#fff" fontSize={hitButtonHeight/2} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              ဆွဲမယ်
            </text>

            <rect onClick={() => setDealerHitOrStand(false)}   x={canvasCenterX + 10} y={canvasCenterY-(hitButtonHeight/2)} rx={standButtonHeight/2} ry={standButtonHeight/2} width={standButtonWidth} height={standButtonHeight} fill="url(#redButtonGradient)" stroke="url(#redButtonStrokeGradient)" strokeWidth={5} />
            <text onClick={() => setDealerHitOrStand(false)}   x={canvasCenterX + (hitButtonWidth/2) + 10} y={canvasCenterY} fill="#fff" fontSize={hitButtonHeight/2} fontWeight={"bold"} dominantBaseline="middle" textAnchor="middle" >
              တော်ပြီ
            </text>
          </g>

          <g id="cardDialogCardsG"></g>

          <g id="checkDialog" visibility={"hidden"} >
            
            <rect x={__checkCardDialogX} y={__checkCardDialogY} rx={dialogRX} ry={dialogRY} width={__checkCardDialogWidth} height={__checkCardDialogHeight} fill={dialogFillColor} stroke={dialogStroke} strokeWidth={dialogStrokeWidth}/>
            <text x={canvasCenterX - (__checkCardDialogWidth/4)} y={__checkCardDialogY + __checkCardDialogPadding}  fill="white" fontSize={__checkCardDialogTitleSize} fontWeight={"bold"} dominantBaseline="hanging" textAnchor="middle" >
              Dealer
            </text>
            <text x={canvasCenterX + (__checkCardDialogWidth/4)} y={__checkCardDialogY + __checkCardDialogPadding}  fill="white" fontSize={__checkCardDialogTitleSize} fontWeight={"bold"} dominantBaseline="hanging" textAnchor="middle" >
              Player
            </text>
            <g id="checkDialogCardsG">
            </g>
            <image x={(canvasCenterX - (__checkCardDialogWidth/4) - (cardWidth/2) - (__checkCardWidth/2))} y={canvasCenterY + (__checkCardHeight*0.5) + 10} width={cardWidth*2} href="images/winner.svg"  />
            <image x={(canvasCenterX + (__checkCardDialogWidth/4) - (cardWidth/2) - (__checkCardWidth/2))} y={canvasCenterY + (__checkCardHeight*0.5) + 10} width={cardWidth*2} href="images/loser.svg"   />
          </g>

          <g id="menuDialog" visibility={menuDialogVisibility}>
            
            

            <rect onClick={() => hideMenu()}   x={0} y={0} rx={0} ry={0} width={canvasWidth} height={canvasHeight} fill={"rgba(0,0,0,0.5)"} />
            <rect x={0} y={0} rx={dialogRX} ry={dialogRY} width={canvasWidth/5} height={canvasHeight*0.4} fill={"black"} stroke={dialogStroke} strokeWidth={dialogStrokeWidth}/>
            <text onClick={() => goBack()} x={20} y={canvasHeight*0.05} fill="#fc9" fontSize={canvasHeight*0.04} fontWeight={"bold"} dominantBaseline="middle" >❌  ထွက်</text>
            <text onClick={() => changeRoom()} x={20} y={canvasHeight*0.15} fill="#fc9" fontSize={canvasHeight*0.04} fontWeight={"bold"} dominantBaseline="middle" >♻️  အခန်းပြောင်း</text>
            <text onClick={() => pauseSound()} x={20} y={canvasHeight*0.25} fill="#fc9" fontSize={canvasHeight*0.04} fontWeight={"bold"} dominantBaseline="middle" >🔊  တီးလုံးသံ</text>
            {/* <text x={20} y={canvasHeight*0.35} fill="#fc9" fontSize={canvasHeight*0.04} fontWeight={"bold"} dominantBaseline="middle" >📣  စကားသံ</text> */}
          </g>

          <g id="loadingDialog" visibility={loadingDialogVisibility}>
            <rect x1={0} y={0} width={canvasWidth} height={canvasHeight} fill="black" fillOpacity={0.5} ></rect>
            <text x={canvasCenterX} y={canvasCenterY} dominantBaseline={"middle"} textAnchor="middle" fill="#fff" style={{fontSize: "50px", fontWeight: "bold"}} >
              LOADING, PLEASE WAIT!
            </text>
          </g>

          <g id="leavingDialog" style={{visibility: "hidden"}}>
            <rect x1={0} y={0} width={canvasWidth} height={canvasHeight} fill="black" fillOpacity={0.5} ></rect>
            <text x={canvasCenterX} y={canvasCenterY} dominantBaseline={"middle"} textAnchor="middle" fill="#fff" style={{fontSize: "50px", fontWeight: "bold"}} >
              LEAVING, PLEASE WAIT!
            </text>
          </g>
        </svg>
        </>
    );
};

export default GameRoom;
