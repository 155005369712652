import React, { useRef, useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

//Custom Imports
import { useUserContext } from './UserContext';
import {SocketContext} from './SocketContext';

import packageJson from '../package.json';
import { regEx } from './Constant';

const StyledButton = styled(Button)({
    borderRadius: '50%',
    width: '100%', // Occupy the whole width of the container
    height: '100px', // Set a fixed height for the buttons
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem', // Adjust the font size as needed
  });
  
  const StyledContainer = styled(Container)({
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

const LoginScreen = ({ onLoginSuccess, onRegisterClick }) => {

  //user info
  const {userInfo, setUserInfo} = useUserContext();
  const [statusMsg, setStatusMsg] = useState("");
  const userIdRef = useRef(null);
  const [userIdError, setUserIdError] = useState(false);
  const [userIdHelperText, setUserIdHelperText] = useState("");
  const passwordRef = useRef(null);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const { socket } = useContext(SocketContext); 
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");



  const canvasWidth = document.body.clientWidth;
  const canvasHeight = window.innerHeight;

  let formData = null;




  const handleLogin = () => {
    let userId = userIdRef.current.value;
    let password = passwordRef.current.value;
    let validationFail = false;

    if(userId.trim() === ""){
      setUserIdError(true);
      setUserIdHelperText("User ID, required.");
      validationFail = true;
    }else if(regEx.userId.test(userId) == false){
      setUserIdError(true);
      setUserIdHelperText("User ID, invalid character.");
      validationFail = true;
    }else{
      setUserIdError(false);
      setUserIdHelperText("");
    }

    if(password.trim() === ""){
      setPasswordError(true);
      setPasswordHelperText("Password, required.");
      validationFail = true;
    }else if(regEx.password.test(password) == false){
      setPasswordError(true);
      setPasswordHelperText("Password, invalid character.");
      validationFail = true;
    }else{
      setPasswordError(false);
      setPasswordHelperText("");
    }

    if(!validationFail){
      login(userId, password);
    }
  };

  const handleRegister = () => {
    onRegisterClick();
  };


  const login = async (userId, password) => {
    try {
      formData = new FormData();
      formData.append('userId', userId);
      formData.append('password', password);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/login', {
            method: 'POST',
            body: formData
            
        });
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                //console.log(responseData.data.user);
                if(userInfo === null || typeof(userInfo) === "undefined"){
                  let user = {
                    id : parseInt(responseData.data.user.id),
                    userId : responseData.data.user.userId,
                    avatar : responseData.data.user.avatar,
                    phone : responseData.data.user.phone,
                    balance : parseInt(responseData.data.user.balance),
                    agentCode : responseData.data.user.agentCode,
                    paymentName : responseData.data.user.paymentName,
                    paymentMethod : responseData.data.user.paymentMethod,
                    paymentAccount : responseData.data.user.paymentAccount,
                    backgroundMusic : responseData.data.user.backgroundMusic,
                    voice : responseData.data.user.voice,
                    roomName: "xxx"
                  }
                  setUserInfo(user);
                  //login successful
                  onLoginSuccess();
                }
                
              }else{
                setMessage(responseData.msg);
                setOpen(true);
              }
            }else{
              setStatusMsg("No server response.");
            }
        } else {
          //document.getElementById("div").innerText = response;
          setStatusMsg("Unknown error.");
        }
    } catch (error) {
      setStatusMsg("Unknown error.");
      //document.getElementById("div").innerText = error;
    }
  };

  
  socket.on("connect_error", (err) => {
    // the reason of the error, for example "xhr poll error"
    //console.log(err.message);
  
    // some additional description, for example the status code of the initial HTTP response
    //console.log(err.description);
  
    // some additional context, for example the XMLHttpRequest object
    //console.log(err.context);
  });


  return (
    <div id="div" style={{height: "100vh", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundImage: "url('./images/girl10.jpg')", backgroundSize: "contain"}}>
      
      <Grid container spacing={2} alignItems={'center'} style={{height: "100vh"}} >
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={5}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <TextField 
             className='textField'
              inputRef={userIdRef} 
              label="User ID" 
              error={userIdError}
              helperText={userIdHelperText}
              fullWidth margin="normal" />
            <TextField 
             className='textField'
              inputRef={passwordRef} 
              label="စကားဝှက်" 
              type="password" 
              error={passwordError}
              helperText={passwordHelperText}
              fullWidth margin="normal" />
                <Button className='button' variant="contained" color="primary" onClick={handleRegister}>
                မှတ်ပုံတင်မည်
              </Button>&nbsp;&nbsp;&nbsp;
                <Button className='button' variant="contained" color="primary" onClick={handleLogin}>
                ဝင်မည်
              </Button>
           
              <br/><br/>
              <Typography style={{fontSize: "12px",color:"red", margin: "10px"}} gutterBottom>
                
            </Typography>
          </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>

    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} >
    <DialogTitle size="sm"  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>Message</DialogTitle>
<DialogContent>
  <DialogContentText sx={{padding: "30px"}}>
    {message}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => setOpen(false)}  className='button' size="small" variant="contained" color="primary">
    Close
  </Button>
</DialogActions>
      </Dialog>
    </div>

  );
};

export default LoginScreen;
