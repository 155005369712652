import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Avatar, Switch, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper,
Grid, TextField, Button, Container, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { styled } from '@mui/system';

import EmailIcon from '@mui/icons-material/Email';
import { useUserContext } from './UserContext';
import "./Constant";

import packageJson from "../package.json";
import { regEx } from './Constant';
import { Check } from '@mui/icons-material';

const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index} style={{paddingTop: "50px"}}>
      {value === index && <div>{children}</div>}
    </div>
  );
  

  const StyledButton = styled(Button)({
    borderRadius: '50%',
    width: '100%', // Occupy the whole width of the container
    height: '100px', // Set a fixed height for the buttons
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem', // Adjust the font size as needed
  });
  
  const StyledContainer = styled(Container)({
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
  

export default function Profile({onLogout}) {

    const {userInfo, setUserInfo} = useUserContext();

    const canvasWidth = document.body.clientWidth;
    const canvasHeight = window.innerHeight;
    const canvasCenterX = canvasWidth / 2;
    const canvasCenterY = canvasHeight / 2;

    const paymentNameRef = useRef(null);
    const paymentMethodRef = useRef(null);
    const paymentAccountRef = useRef(null);
    const currentPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const [paymentNameError, setPaymentNameError] = useState(false);
    const [paymentNameHelperText, setPaymentNameHelperText] = useState("");
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [paymentMethodHelperText, setPaymentMethodHelperText] = useState("");
    const [paymentAccountError, setPaymentAccountError] = useState(false);
    const [paymentAccountHelperText, setPaymentAccountHelperText] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordHelperText, setCurrentPasswordHelperText] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordHelperText, setNewPasswordHelperText] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("");
    const amountRef = useRef(null);
    const [amountError, setAmountError] = useState(false);
    const [amountHelperText, setAmountHelperText] = useState("");
    const [statusMsg, setStatusMsg] = useState("");

    const backgroundMusicRef = useRef(null);
    const voiceRef = useRef(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [avatarDialogVisible, setAvatarDialogVisible] = useState(false);


  const [tabIndex, setTabIndex] = useState(0);


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');


    const logout = () => {
      onLogout();
    }
  
  
    const handleChange = (event, newValue) => {
      setTabIndex(newValue);
    };

    const changePassword = () => {
        let currentPassword = currentPasswordRef.current.value;
        let newPassword = newPasswordRef.current.value;
        let confirmPassword = confirmPasswordRef.current.value;
        
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        setConfirmPasswordError(false);
        setCurrentPasswordHelperText("");
        setNewPasswordHelperText("");
        setConfirmPasswordHelperText("");

        if(currentPassword.trim() === ""){
          setCurrentPasswordError(true);
          setCurrentPasswordHelperText("Password, required.");
          return;
        }else if(regEx.password.test(currentPassword) == false){
          setCurrentPasswordError(true);
          setCurrentPasswordHelperText("Password, invalid character.");
          return;
        }

        if(newPassword.trim() === ""){
          setNewPasswordError(true);
          setNewPasswordHelperText("Password, required.");
          return;
        }else if(regEx.password.test(newPassword) == false){
          setNewPasswordError(true);
          setNewPasswordHelperText("Password, invalid character.");
          return;
        }

        if(confirmPassword.trim() === ""){
          setConfirmPasswordError(true);
          setConfirmPasswordHelperText("Password, required.");
          return;
        }else if(regEx.password.test(confirmPassword) == false){
          setConfirmPasswordError(true);
          setConfirmPasswordHelperText("Password, invalid character.");
          return;
        }

        if(newPassword.trim() != confirmPassword.trim()){
          setConfirmPasswordError(true);
          setConfirmPasswordHelperText("Password, do not match.");
          return;
        }

        changePasswordRequest();
      };


      const changePasswordRequest = async () => {
        try {
          let formData = new FormData();
          formData.append('userId', userInfo.userId);
          formData.append('currentPassword', currentPasswordRef.current.value);
          formData.append('newPassword', newPasswordRef.current.value);
          formData.append('confirmPassword', confirmPasswordRef.current.value);
            // Make a GET request to the URL
            const response = await fetch(packageJson.server + 'api/changepassword', {
                method: 'POST',
                body: formData
            });
            
            // Check if the response is successful (status code 200)
            if (response.ok) {
                // Parse the JSON response
                const responseData = await response.json();
                if(responseData != null){
                  if(responseData.status == "SUCCESS"){
                    setStatusMsg("Password saved!");
                    setDialogVisible(true);
                  }else{
                    setStatusMsg(responseData.msg);
                    setDialogVisible(true);
                  }
                }else{
                    setStatusMsg("Password cannot be saved!");
                    setDialogVisible(true);
                }
            } else {
              setStatusMsg("Password cannot be saved!");
              setDialogVisible(true);
            }
        } catch (error) {
          setStatusMsg("Password cannot be saved!");
          setDialogVisible(true);
        }
      };

      const changePayment = () => {
          let paymentName = paymentNameRef.current.value;
          let paymentMethod = paymentMethodRef.current.value;
          let paymentAccount = paymentAccountRef.current.value;
          
          setPaymentNameError(false);
          setPaymentMethodError(false);
          setPaymentAccountError(false);
          setPaymentNameHelperText("");
          setPaymentMethodHelperText("");
          setPaymentAccountHelperText("");
  
          if(paymentName.trim() === ""){
            setPaymentNameError(true);
            setPaymentNameHelperText("Payment Name, required.");
            return;
          }else if(regEx.alphabetWithSpace.test(paymentName) == false){
            setPaymentNameError(true);
            setPaymentNameHelperText("Payment Name, invalid character.");
            return;
          }
  
          if(paymentMethod.trim() === ""){
            setPaymentMethodError(true);
            setPaymentMethodHelperText("Payment Method, required.");
            return;
          }else if(regEx.alphabetWithSpace.test(paymentMethod) == false){
            setPaymentMethodError(true);
            setPaymentMethodHelperText("Payment Method, invalid character.");
            return;
          }
  
          if(paymentAccount.trim() === ""){
            setPaymentAccountError(true);
            setPaymentAccountHelperText("Payment Account, required.");
            return;
          }else if(regEx.alphNumWithSpace.test(paymentAccount) == false){
            setPaymentAccountError(true);
            setPaymentAccountHelperText("Payment Account, invalid character.");
            return;
          }
  
          changePaymentRequest();
        };
  
  
        const changePaymentRequest = async () => {
          try {
            let formData = new FormData();
            formData.append('userId', userInfo.userId);
            formData.append('paymentName', paymentNameRef.current.value);
            formData.append('paymentMethod', paymentMethodRef.current.value);
            formData.append('paymentAccount', paymentAccountRef.current.value);
              // Make a GET request to the URL
              const response = await fetch(packageJson.server + 'api/updatepayment', {
                  method: 'POST',
                  body: formData
              });
              
              // Check if the response is successful (status code 200)
              if (response.ok) {
                  // Parse the JSON response
                  const responseData = await response.json();
                  if(responseData != null){
                    if(responseData.status == "SUCCESS"){
                      userInfo.paymentName = paymentNameRef.current.value;
                      userInfo.paymentMethod = paymentMethodRef.current.value;
                      userInfo.paymentAccount = paymentAccountRef.current.value;
                      setStatusMsg("Payment saved!");
                      setDialogVisible(true);
                    }else{
                      setStatusMsg("Payment failed!");
                      setDialogVisible(true);
                    }
                  }else{
                    setStatusMsg("Payment failed!");
                    setDialogVisible(true);
                  }
              } else {
                setStatusMsg("Payment failed!");
                setDialogVisible(true);
              }
          } catch (error) {
            setStatusMsg("Payment failed!");
            setDialogVisible(true);
          }
        };

        const saveSetting = async () => {
          try {
            let formData = new FormData();
            formData.append('userId', userInfo.userId);
            formData.append('backgroundMusic', backgroundMusicRef.current.checked);
            formData.append('voice', voiceRef.current.checked);
              // Make a GET request to the URL
              const response = await fetch(packageJson.server + 'api/savesetting', {
                  method: 'POST',
                  body: formData
              });
              
              // Check if the response is successful (status code 200)
              if (response.ok) {
                  // Parse the JSON response
                  const responseData = await response.json();
                  if(responseData != null){
                    if(responseData.status == "SUCCESS"){
                      userInfo.backgroundMusic = backgroundMusicRef.current.checked;
                      userInfo.voice = voiceRef.current.checked;
                      setStatusMsg("Setting saved!");
                      setDialogVisible(true);
                    }else{
                      setStatusMsg("Setting failed1!");
                      setDialogVisible(true);
                    }
                  }else{
                    setStatusMsg("Setting failed2!");
                    setDialogVisible(true);
                  }
              } else {
                setStatusMsg("Setting failed3!"+ response.statusMsg);
                setDialogVisible(true);
              }
          } catch (error) {
            setStatusMsg("Setting failed4!");
            setDialogVisible(true);
          }
        };

        const changeAvatar = async (avatar) => {
          try {
            let formData = new FormData();
            formData.append('userId', userInfo.userId);
            formData.append('avatar', avatar);
              // Make a GET request to the URL
              const response = await fetch(packageJson.server + 'api/changeavatar', {
                  method: 'POST',
                  body: formData
              });
              // Check if the response is successful (status code 200)
              if (response.ok) {
                  // Parse the JSON response
                  const responseData = await response.json();
                  if(responseData != null){
                    if(responseData.status == "SUCCESS"){
                      userInfo.avatar = avatar;

                      setStatusMsg("Avatar saved!");
                      setDialogVisible(true);
                    }else{
                      setStatusMsg("Avatar failed1!");
                      setDialogVisible(true);
                    }
                  }else{
                    setStatusMsg("Avatar failed2!");
                    setDialogVisible(true);
                  }
              } else {
                setStatusMsg("Avatar failed3!"+ response.statusMsg);
                setDialogVisible(true);
              }
          } catch (error) {
            setStatusMsg("Avatar failed4!");
            setDialogVisible(true);
          }
        };

  return (
    <div   style={{height: "120vh"}}>
        
      <Tabs
        value={tabIndex}
        onChange={handleChange}
      >
        <Tab label="ပရိုဖိုင်" style={{color : "rgb(255,200,0)"}} className='tab' />
        <Tab label="စကားဝှက်" style={{color : "rgb(255,200,0)"}} className='tab' />
        <Tab label="ဘဏ်အကောင့်" style={{color : "rgb(255,200,0)"}} className='tab' />
      </Tabs>

      <TabPanel value={tabIndex} index={0} >
        <Grid container spacing={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
          <div style={{borderRadius: "3%", backgroundColor: "rgba(0,0,0,0.5)", padding: "10px"}}>
          <Grid container spacing={3} style={{alignItems: "center", alignContent: "center", justifyContent: "center", justifyItems: "center"}}>
          <Grid item xs={4}>
                  <Avatar sx={{ width: 150, height: 150 }} src={"images/"+userInfo.avatar}  />
            <br/>
            <Button size='small' className='button' variant="contained" color="primary" onClick={() => setAvatarDialogVisible(true)} >
                Avatar ပြောင်းပါ
              </Button>
            </Grid>
          <Grid item xs={8}>
            <Grid container direction="column" spacing={1}>
              <Grid item>User ID : {userInfo.userId}</Grid>
              <Grid item>အေးဂျင့်ကုဒ် : {userInfo.agentCode}</Grid>
              <Grid item>လက်ကျန်ငွေ : {userInfo.balance}</Grid>
              <Grid item>နောက်ခံတေးဂီတ : <Switch inputRef={backgroundMusicRef} size="medium" defaultChecked={(userInfo.backgroundMusic === '1')}></Switch></Grid>
              <Grid item>ကစားသူအသံ : <Switch inputRef={voiceRef} size="medium" defaultChecked={( userInfo.voice === '1')}></Switch></Grid>
              <Grid item>
              <Button size='small' className='button' variant="contained" color="primary" onClick={() => saveSetting()} >
                သိမ်းပါ
              </Button>&nbsp;
            <Button size='small'  className='button' variant="contained" color="primary" onClick={() => logout()} >
                ထွက်ပါ
              </Button>
              </Grid>
            </Grid>
            
          </Grid>
          </Grid>
          
            
          </div>
              </Grid>
          <Grid item xs={2}></Grid>
      
        </Grid>
      </TabPanel>

        
      <TabPanel value={tabIndex} index={1} style={{overflow: "scroll", height: "150vh"}}>
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} sx={{padding: 0}}>
          <TextField 
              readOnly size="small"
                  className='textField'
                inputRef={currentPasswordRef} 
                label="လက်ရှိစကားဝှက်"
                error={currentPasswordError}
                helperText={currentPasswordHelperText}
                fullWidth margin="normal" />
              <TextField  size='small'
                  className='textField'
                inputRef={newPasswordRef} 
                label="စကားဝှက်အသစ်" 
                error={newPasswordError}
                helperText={newPasswordHelperText}
                fullWidth margin="normal" />
                <TextField  size='small'
                    className='textField'
                  inputRef={confirmPasswordRef} 
                  label="စကားဝှက်အတည်ပြု" 
                  error={confirmPasswordError}
                  helperText={confirmPasswordHelperText}
                  fullWidth margin="normal" />
              <Button  size='small' className='button' variant="contained" color="primary" onClick={changePassword}>
                  စကားဝှက်ပြောင်းပါ
                </Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Grid container spacing={3}>
        <Grid item xs={4}></Grid>
      <Grid item xs={4}>
          <Grid container justifyContent="center" style={{ display: 'flex', alignItems: 'center' }}>
            <TextField  size='small'
                className='textField'
              inputRef={paymentNameRef} 
              label="အကောင့်ပိုင်ရှင်အမည်"
              defaultValue={userInfo.paymentName}
              error={paymentNameError}
              helperText={paymentNameHelperText}
              fullWidth margin="normal" />
            <TextField  size='small'
                className='textField'
              inputRef={paymentMethodRef} 
              label="ငွေလွဲနည်းလမ်း" 
              
              defaultValue={userInfo.paymentMethod}
              error={paymentMethodError}
              helperText={paymentMethodHelperText}
              fullWidth margin="normal" />
              <TextField  size='small'
                  className='textField'
                inputRef={paymentAccountRef} 
                label="ငွေလွဲအကောင့်" 
                defaultValue={userInfo.paymentAccount}
                error={paymentAccountError}
                helperText={paymentAccountHelperText}
                fullWidth margin="normal" />
            <Button  size='small' className='button' variant="contained" color="primary" onClick={changePayment}>
                ငွေလွဲနည်းလမ်းသိမ်းပါ
              </Button>
          </Grid>
      </Grid>
          <Grid item xs={4}></Grid>
    </Grid>
        </TabPanel>

<Dialog open={dialogVisible} onClose={() => setDialogVisible(false)} fullWidth={true} maxWidth={'sm'}>
<DialogTitle size="sm"  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>Message</DialogTitle>
<DialogContent>
  <DialogContentText sx={{padding: "30px"}}>
    {statusMsg}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => setDialogVisible(false)}  className='button' size="small" variant="contained" color="primary">
    Close
  </Button>
</DialogActions>
</Dialog>


<Dialog open={avatarDialogVisible} onClose={() => setAvatarDialogVisible(false)} fullWidth={true} >
        <DialogTitle>Choose Avatar</DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifyContent="center" columnSpacing={5} rowSpacing={5}>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar1.png" onClick={() => {setAvatarDialogVisible(false); changeAvatar("avatar1.png");}} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar2.png" onClick={() => changeAvatar("avatar2.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar3.png" onClick={() => changeAvatar("avatar3.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar4.png" onClick={() => changeAvatar("avatar4.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar5.png" onClick={() => changeAvatar("avatar5.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar6.png" onClick={() => changeAvatar("avatar6.png")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* Button to close the dialog */}
          <Button onClick={() => setAvatarDialogVisible(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  
}