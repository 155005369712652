import React, { useRef, useState, useContext, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {InputAdornment, ButtonGroup, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Typography, IconButton, Badge, Paper, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';

import {Email, Person, Delete} from '@mui/icons-material';
import { useUserContext } from './UserContext';
import {SocketContext} from './SocketContext';
import packageJson from '../package.json';
import { regEx } from './Constant';

const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
  
  

export default function TabsWrappedLabel() {

  const {userInfo, setUserInfo} = useUserContext();

  const userIdRef = useRef(null);
  const [userId, setUserId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [amount, setAmount] = useState(0);
  const amountRef = useRef(null);
  const [amountError, setAmountError] = useState(false);
  const [amountHelperText, setAmountHelperText] = useState("");
    

  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [sendDialogVisible, setSendDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [friendRequest, setFriendRequest] = useState(null);
  const [friends, setFriends] = useState(null);
  const [transfers, setTransfers] = useState(null);
  const [searchResult, setSearchResult] = useState(Array());
  const [requested, setRequested] = useState(false);
  
    const handleChange = (event, newValue) => {
      setTabIndex(newValue);
    };

    const handleDialogOpen = () => {
      setOpen(true);
    };

    const handleDialogClose = () => {
      setOpen(false);
    };

    const handleRequest = () => {
      setOpen(false);

    };


  useEffect(() => {
    
    getFriendData();
  }, []);



  const getFriendData = async () => {
    try {
      let formData = new FormData();
      formData.append('id', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/frienddata', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setFriendRequest(responseData.data.request);
                setFriends(responseData.data.friend);
                setTransfers(responseData.data.transfer);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const searchFriend = async () => {
    try {
      let formData = new FormData();
      formData.append('requestTo', userId);
      formData.append('requestBy', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/searchplayer', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setSearchResult(responseData.data.player);
                setRequested(responseData.data.requested);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const sendRequest = async (id) => {
    try {
      setOpen(false);
      setSearchResult(null);
      setUserId("");
      let formData = new FormData();
      formData.append('friend_id', id);
      formData.append('user_id', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/sendrequest', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                //setSearchResult(responseData.data.player);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const approveRequest = async (id) => {
    try {
      let formData = new FormData();
      formData.append('friend_id', id);
      formData.append('user_id', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/approverequest', {
            method: 'POST',
            body: formData
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                getFriendData();
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const rejectRequest = async (id) => {
    try {
      let formData = new FormData();
      formData.append('friend_id', id);
      formData.append('user_id', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/rejectrequest', {
            method: 'POST',
            body: formData
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                getFriendData();
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const showSendMoneyDialog = (id, userId) => {
    setSelectedUserId(userId);
    setSelectedId(id);
    setSendDialogVisible(true);
  };


  const showDeleteFriendDialog = (id, userId) => {
    setSelectedUserId(userId);
    setSelectedId(id);
    setDeleteDialogVisible(true);
  };
  

  const sendMoney = () => {
    if(amountRef.current.value > userInfo.balance){
      setAmountError(true);
      setAmountHelperText("More than your balance");
      return;
    }else if(regEx.num.test(amount) == false){
      setAmountError(true);
      setAmountHelperText("Only numbers.");
      return;
    }
    sendMoneyRequest();
    setSendDialogVisible(false);
  };

  const sendMoneyRequest = async () => {
    try {
      let formData = new FormData();
      formData.append('from_user_id', userInfo.id);
      formData.append('to_user_id', selectedId);
      formData.append('amount', amountRef.current.value);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/sendmoney', {
            method: 'POST',
            body: formData
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                getFriendData();
                userInfo.balance = responseData.data.balance;
                setUserInfo({...userInfo, balance: responseData.data.balance});
              }else{ 
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
    }
  };



  const deleteFriend = async () => {
    try {
      setDeleteDialogVisible(false);
      let formData = new FormData();
      formData.append('user_id', userInfo.id);
      formData.append('friend_id', selectedId);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/deletefriend', {
            method: 'POST',
            body: formData
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setFriends(responseData.data.friend);
              }else{ 
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
    }
  };

  return (
    <>
    <Box   style={{}}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
      >
        <Tab label="သူငယ်ချင်းစာရင်း" />
        <Tab label="သူငယ်ချင်းဖြစ်ခွင့်" icon={<Badge badgeContent={ (friendRequest != null ? friendRequest.length : 0)} color='error' />} ></Tab>
        <Tab label="ငွေလွဲသမိုင်း" />
      </Tabs>

      <TabPanel value={tabIndex} index={0} >

      <Button  className='button' variant="contained" color="primary" onClick={handleDialogOpen}>
                + သူငယ်ချင်းအသစ်ထဲ့ပါ
              </Button>
        <TableContainer style={{height: "50vh"}} >
        <Table stickyHeader component={Paper} style={{backgroundColor: "rgba(200,170,0,1)"}}>
          <TableHead>
            <TableRow key="h1">
              <TableCell></TableCell>
              <TableCell>သူငယ်ချင်း ID</TableCell>
              <TableCell>သူငယ်ချင်းစဖြစ်သည့်နေ့</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {friends != null && friends.map((row) => (
              <TableRow key={row.id}  >
                <TableCell>
                  <Person />
                </TableCell>
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.friend_since}</TableCell>
                <TableCell>
                <Button className="button" variant='contained' size='small' onClick={() => {showSendMoneyDialog(row.id, row.userId);}} >ငွေလွဲပါ</Button>&nbsp;&nbsp;
                <Button className="button" variant='contained' size='small' startIcon={<Delete />} onClick={() => {showDeleteFriendDialog(row.id, row.userId);}} >ဖျက်ပါ</Button>
                </TableCell>
              </TableRow>
            ))}
            {Array.isArray(friends) && friends.length <= 0 && 
              <TableRow key="x" >
                <TableCell>
                </TableCell>
                <TableCell>သူငယ်ချင်းမရှိပါ။</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
        <TableContainer style={{height: "50vh"}}>
        <Table stickyHeader component={Paper} style={{backgroundColor: "rgba(200,170,0,1)"}}>
          <TableHead>
            <TableRow key="k2">
              <TableCell></TableCell>
              <TableCell style={{backgroundColor: "none"}} >သူငယ်ချင်းအမည်</TableCell>
              <TableCell>တောင်းဆိုသည့်နေ့</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              
            {friendRequest != null && friendRequest.map((row) => (
              <TableRow key={row.id}  >
                <TableCell>
                  <Person />
                </TableCell>
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.request_on}</TableCell>
                <TableCell>
                  <ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button onClick={() => approveRequest(row.id)}>လက်ခံသည်</Button>
                    <Button onClick={() => rejectRequest(row.id)}>ငြင်းဆိုသည်</Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
            {Array.isArray(friendRequest) && friendRequest.length <= 0 && 
              <TableRow key="x" >
                <TableCell>
                </TableCell>
                <TableCell>တောင်းဆိုချက်မရှိသေးပါ။</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
        <TableContainer style={{height: "50vh"}}>
        <Table stickyHeader component={Paper} style={{backgroundColor: "rgba(200,170,0,1)"}}>
          <TableHead>
            <TableRow key="h2">
              <TableCell>သူငယ်ချင်း ID</TableCell>
              <TableCell>နေ့စွဲ</TableCell>
              <TableCell>ပမာဏ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers != null && transfers.map((row) => (
              <TableRow key={row.id}  >
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.transfer_on}</TableCell>
                <TableCell>{row.amount}</TableCell>
              </TableRow>
            ))}
            {Array.isArray(transfers) && transfers.length <= 0 && 
              <TableRow key="x" >
                <TableCell>
                </TableCell>
                <TableCell>ငွေလွဲမရှိသေးပါ။</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>
    </Box>

    <Dialog open={open} onClose={handleDialogClose} fullWidth={true} maxWidth={'md'}>
    <DialogTitle sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>Add Friend</DialogTitle>
    <DialogContent sx={{padding: "10px"}}>
      
      <Grid container display="flex" flexDirection="column">
        <Grid item sx={{padding: "10px"}}>
        သူငယ်ချင်း ID &nbsp;
      <TextField ref={userIdRef} value={userId} onChange={e => setUserId(e.target.value)} size='small' type="text"/>&nbsp;
      <Button onClick={searchFriend} size="small"  className='button' variant="contained" color="primary">
        ရှာပါ
      </Button>
        </Grid>
        <Grid item>
        <TableContainer style={{height: "50vh"}} >
        <Table stickyHeader component={Paper} style={{backgroundColor: "rgba(200,170,0,1)"}}>
          <TableHead>
            <TableRow key="h2">
              <TableCell>သူငယ်ချင်း ID</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResult != null && searchResult.map((row) => (
              <TableRow key={row.id}  >
                <TableCell>{row.userId}</TableCell>
                <TableCell>
                  {requested == 0 && <Button className="button" variant='contained' size='small' onClick={() => {sendRequest(row.id);}}>တောင်းဆိုပါ</Button>}
                  {requested > 0 && <Button className="button disabled" variant='contained' size='small' >တောင်းဆိုထားပါသည်</Button>}
                </TableCell>
              </TableRow>
            ))}
            {Array.isArray(searchResult) && searchResult.length <= 0 && 
              <TableRow key="x" >
                <TableCell>မရှိပါ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            {searchResult == null && 
              <TableRow key="x" >
                <TableCell>မရှိပါ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      {/* Button to close the dialog */}
      <Button onClick={handleDialogClose}  className='button' size="small" variant="contained" color="primary">
        ပိတ်ပါ
      </Button>
    </DialogActions>
  </Dialog>

<Dialog open={sendDialogVisible} onClose={() => setSendDialogVisible(false)} fullWidth={true} maxWidth={'sm'}>
<DialogTitle  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>‌ငွေလွဲပါ</DialogTitle>
<DialogContent sx={{}}>
  
  <Grid container display="flex" flexDirection="row" sx={{padding: "30px"}}>
    <Grid item xs={6} sx={{padding: "10px"}} >
    ပို့ဆောင်ရန် &nbsp;
    </Grid>
    <Grid item xs={6}>
    {selectedUserId}
    </Grid>
    <Grid item xs={6} sx={{padding: "10px"}}>
    အသုံးပြုနိုင်သည့်ပမာဏ&nbsp;
    </Grid>
    <Grid item xs={6}>
    {userInfo.balance}
    </Grid>
    <Grid item xs={6} sx={{padding: "10px"}} >
    လွဲမည့်ပမာဏ &nbsp;
    </Grid>
    <Grid item xs={6}>
    <TextField InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  inputRef={amountRef} error={amountError} helperText={amountHelperText} size='small' type="text"/>&nbsp;
    </Grid>
  </Grid>
</DialogContent>
<DialogActions>
  <Button onClick={() => sendMoney()}  className='button' size="small" variant="contained" color="primary">
    ငွေလွဲပါ
  </Button>
  <Button onClick={() => setSendDialogVisible(false)}  className='button' size="small" variant="contained" color="primary">
    ပိတ်ပါ
  </Button>
</DialogActions>
</Dialog>

<Dialog open={deleteDialogVisible} onClose={() => setDeleteDialogVisible(false)} fullWidth={true} maxWidth={'sm'}>
<DialogTitle  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>‌သူငယ်ချင်းအဖြစ်မှပယ်ဖျက်ရန်</DialogTitle>
<DialogContent sx={{}}>
  
  
  <DialogContentText sx={{padding: "30px"}}>
  သူငယ်ချင်းအဖြစ်မှ ဖျက်ချင်ပါသလား?
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => deleteFriend()}  className='button' size="small" variant="contained" color="primary">
    ဖျက်ပါ
  </Button>
  <Button onClick={() => setDeleteDialogVisible(false)}  className='button' size="small" variant="contained" color="primary">
    ပိတ်ပါ
  </Button>
</DialogActions>
</Dialog>
  </>
  );

  
}