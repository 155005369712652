import React, { useRef, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, TextField, Grid, Badge, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import {Person, Autorenew} from '@mui/icons-material';

//Custom Imports
import { SocketContext } from './SocketContext';

import packageJson from "../package.json";
import { regEx } from './Constant';
const steps = ['Personal Info', 'Payment Info'];

export default function Register({ onRegisterBack }) {
  const [activeStep, setActiveStep] = React.useState(0);

  //user info
  const [statusMsg, setStatusMsg] = useState("");
  const userIdRef = useRef(null);
  const [userIdError, setUserIdError] = useState(false);
  const [userIdHelperText, setUserIdHelperText] = useState("");
  const passwordRef = useRef(null);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const confirmPasswordRef = useRef(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("");
  const phoneRef = useRef(null);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");
  const agentCodeRef = useRef(null);
  const [agentCodeError, setAgentCodeError] = useState(false);
  const [agentCodeHelperText, setAgentCodeHelperText] = useState("");
  const paymentUserNameRef = useRef(null);
  const [paymentUserNameError, setPaymentUserNameError] = useState(false);
  const [paymentUserNameHelperText, setPaymentUserNameHelperText] = useState("");
  const paymentMethodRef = useRef(null);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [paymentMethodHelperText, setPaymentMethodHelperText] = useState("");
  const paymentAccountRef = useRef(null);
  const [paymentAccountError, setPaymentAcountError] = useState(false);
  const [paymentAccountHelperText, setPaymentAccountHelperText] = useState("");
  let requestMessage = {};
  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState("avatar1.png");


  const { socket } = useContext(SocketContext);



  const canvasWidth = document.body.clientWidth;
  const canvasHeight = window.innerHeight;
  const canvasCenterX = canvasWidth / 2;
  const canvasCenterY = canvasHeight / 2;
  const [progress, setProgress] = useState(0);
  const textFieldHeight = canvasHeight / 40;

  let formData = null;



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    onRegisterBack();
  };

  const handleRegister = () => {
    let userId = userIdRef.current.value;
    let password = passwordRef.current.value;
    let confirmPassword = confirmPasswordRef.current.value;
    let phone = phoneRef.current.value;
    let agentCode = agentCodeRef.current.value;
    let paymentUserName = paymentUserNameRef.current.value;
    let paymentMethod = paymentMethodRef.current.value;
    let paymentAccount = paymentAccountRef.current.value;
    let validationFail = false;

    if (userId.trim() === "") {
      setUserIdError(true);
      setUserIdHelperText("User ID, required.");
      validationFail = true;
      return;
    } else if (regEx.userId.test(userId) == false) {
      setUserIdError(true);
      setUserIdHelperText("User ID, invalid character.");
      validationFail = true;
      return;
    } else {
      setUserIdError(false);
      setUserIdHelperText("");
    }

    if (password.trim() === "") {
      setPasswordError(true);
      setPasswordHelperText("Password, required.");
      validationFail = true;
      return;
    } else if (regEx.password.test(password) == false) {
      setPasswordError(true);
      setPasswordHelperText("Password, invalid character.");
      validationFail = true;
      return;
    } else {
      setPasswordError(false);
      setPasswordHelperText("");
    }

    if (confirmPassword != password) {
      setConfirmPasswordError(true);
      setConfirmPasswordHelperText("Password, not the same.");
      validationFail = true;
      return;
    } else {
      setConfirmPasswordError(false);
      setConfirmPasswordHelperText("");
    }

    if (phone.trim() === "") {
      setPhoneError(true);
      setPhoneHelperText("Phone, required.");
      validationFail = true;
      return;
    } else if (regEx.phone.test(phone) == false) {
      setPhoneError(true);
      setPhoneHelperText("Phone, invalid character.");
      validationFail = true;
      return;
    } else {
      setPhoneError(false);
      setPhoneHelperText("");
    }

    if (agentCode.trim() === "") {
      setAgentCodeError(true);
      setAgentCodeHelperText("Agent Code, required.");
      validationFail = true;
      return;
    } else if (regEx.alphabet.test(agentCode) == false) {
      setAgentCodeError(true);
      setAgentCodeHelperText("Agent Code, invalid character.");
      validationFail = true;
      return;
    } else {
      setAgentCodeError(false);
      setAgentCodeHelperText("");
    }

    if (paymentUserName.trim() === "") {
      setPaymentUserNameError(true);
      setPaymentUserNameHelperText("Payment User Name, required.");
      validationFail = true;
    } else if (regEx.alphabetWithSpace.test(paymentUserName) == false) {
      setPaymentUserNameError(true);
      setPaymentUserNameHelperText("Payment User Name, invalid character.");
      validationFail = true;
    } else {
      setPaymentUserNameError(false);
      setPaymentUserNameHelperText("");
    }

    if (paymentMethod.trim() === "") {
      setPaymentMethodError(true);
      setPaymentMethodHelperText("Payment Method, required.");
      validationFail = true;
    } else if (regEx.alphabetWithSpace.test(paymentMethod) == false) {
      setPaymentMethodError(true);
      setPaymentMethodHelperText("Payment Method, invalid character.");
      validationFail = true;
    } else {
      setPaymentMethodError(false);
      setPaymentMethodHelperText("");
    }

    if (paymentAccount.trim() === "") {
      setPaymentAcountError(true);
      setPaymentAccountHelperText("Payment Account, required.");
      validationFail = true;
    } else if (regEx.account.test(paymentAccount) == false) {
      setPaymentAcountError(true);
      setPaymentAccountHelperText("Payment Account, invalid character.");
      validationFail = true;
    } else {
      setPaymentAcountError(false);
      setPaymentAccountHelperText("");
    }

    if (!validationFail) {
      formData = new FormData();
      formData.append('userid', userId);
      formData.append('password', password);
      formData.append('phone', phone);
      formData.append('confirm_password', confirmPassword);
      formData.append('agent_code', agentCode);
      formData.append('payment_user_name', paymentUserName);
      formData.append('payment_method', paymentMethod);
      formData.append('payment_account', paymentAccount);
      formData.append('avatar', avatar);
      register();
    }

  };//func



  const register = async () => {
    try {
      // Make a GET request to the URL
      const response = await fetch(packageJson.server + 'api/register', {
        method: 'POST',
        body: formData

      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if (responseData != null) {
          if (responseData.status == "SUCCESS") {
            //register successful
            onRegisterBack();
          } else {
            setStatusMsg(responseData.msg);
          }
        } else {
          setStatusMsg("No server response.");
        }
      } else {
        setStatusMsg("Response status not OK.");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const avatarOneClicked = () => {
    document.getElementById("avatarOne").style = "border: solid 5px #f00;margin: 10px;width:100px;";
    document.getElementById("avatarTwo").style = "border: solid 5px #000;margin: 10px;width:100px;";
    avatar = "avatar1.png";
  }

  const showAvator = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  return (
    <div id="div" style={{ height: "100vh", padding: "10px", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundImage: "url('./images/girl10.jpg')", backgroundSize: "contain" }}>


      <Box display="flex" sx={{ width: '100%', height: "90vh", borderRadius: "25px", padding: "20px", backgroundColor: "rgba(255,255,255,0.3)" }}>

        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Avatar sx={{ bgcolor: "#f00" }}>
                      <Autorenew />
                    </Avatar>
                  }>
                  <Avatar sx={{ width: 100, height: 100 }} src={"images/"+avatar} onClick={showAvator} />
                </Badge>

              </Grid>
              <Grid item xs={5}>

                <Grid container direction="column" spacing={2}>
                  <Grid item >
                    <TextField size="small"
                      className='textField'
                      inputRef={userIdRef}
                      label="Username"
                      error={userIdError}
                      helperText={userIdHelperText}
                      fullWidth />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={passwordRef}
                      label="စကားဝှက်"
                      type="password"
                      error={passwordError}
                      helperText={passwordHelperText}
                      fullWidth />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={confirmPasswordRef}
                      label="စကားဝှက် အတည်ပြု"
                      type="password"
                      error={confirmPasswordError}
                      helperText={confirmPasswordHelperText}
                      fullWidth />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={phoneRef}
                      label="ဖုန်း"
                      error={phoneError}
                      helperText={phoneHelperText}
                      fullWidth />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={agentCodeRef}
                      label="အေးဂျင့်ကုဒ်"
                      error={agentCodeError}
                      helperText={agentCodeHelperText}
                      fullWidth />
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={5}>

                <Grid container direction="column" spacing={2}>
                  <Grid item >
                    <TextField size="small"
                      className='textField'
                      inputRef={paymentUserNameRef}
                      label="ငွေလွှဲအကောင့်ပိုင်ရှင်"
                      fullWidth
                      error={paymentUserNameError}
                      helperText={paymentUserNameHelperText}
                      placeholder="e.g. U Ko Ko" />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={paymentMethodRef}
                      label="ငွေလွှဲနည်းလမ်း"
                      fullWidth
                      error={paymentMethodError}
                      helperText={paymentMethodHelperText}
                      placeholder="e.g. KBZ Pay or KBZ Saving Account" />
                  </Grid>
                  <Grid item>
                    <TextField size="small"
                      className='textField'
                      inputRef={paymentAccountRef}
                      label="ငွေလွှဲအကောင့်"
                      fullWidth
                      error={paymentAccountError}
                      helperText={paymentAccountHelperText}
                      placeholder="e.g. 09-98761234 or 1234-5678-800" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="flex-end">
            
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

            {statusMsg}&nbsp;
              <Button className='button' onClick={handleRegister} variant="contained">
                 မှတ်ပုံတင်ပါ
              </Button>&nbsp;
              <Button className='button' onClick={handleCancel} variant="contained">
                ပယ်ဖျက်မည်
              </Button>
            </Box>
          </Grid>
        </Grid>

        
      </Box>


    <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <DialogTitle>Choose Avatar</DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifyContent="center" columnSpacing={5} rowSpacing={5}>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar1.png" onClick={() => setAvatar("avatar1.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar2.png" onClick={() => setAvatar("avatar2.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar3.png" onClick={() => setAvatar("avatar3.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar4.png" onClick={() => setAvatar("avatar4.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar5.png" onClick={() => setAvatar("avatar5.png")} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
                  <Avatar sx={{ width: 100, height: 100 }} src="images/avatar6.png" onClick={() => setAvatar("avatar6.png")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* Button to close the dialog */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
