import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCardIcon from '@mui/icons-material/AddCard';
import PeopleIcon from '@mui/icons-material/People';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import EmailIcon from '@mui/icons-material/Email';
import CasinoIcon from '@mui/icons-material/Casino';
import Friends from './Friends';
import MessagesScreen from './Messages'; // Add this line
import RoomList from './RoomList';
import CashIn from './CashIn';
import CashOut from './CashOut';
import Profile from './Profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useUserContext } from './UserContext';
import packageJson from "../package.json";

import "./Style.css";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const MainScreen = ({onScreenChange}) => {
  const {userInfo, setUserInfo} = useUserContext();

  const canvasWidth = document.body.clientWidth;
  const canvasHeight = window.innerHeight;
  const canvasCenterX = canvasWidth / 2;
  const canvasCenterY = canvasHeight / 2;
  const headerHeight = canvasHeight / 8;
  const jackPotBGHeight = headerHeight * 0.5;
  const jackPotBGWidth = jackPotBGHeight * 7;
  const balanceBGHeight = headerHeight;
  const balanceBGWidth = balanceBGHeight * 3;

  const gameNameFontSize = headerHeight;
  const jackPotFontSize = jackPotBGHeight * 0.8;
  const jackPotMoneyFontSize = jackPotBGHeight * 0.8;
  const balanceFontSize = jackPotBGHeight * 0.8;

  const [selectedTab, setSelectedTab] = useState('roomlist'); // Default tab
  const [playerBalance, setPlayerBalance] = useState(0);

  const [jackpot, setJackpot] = useState(0);
  const [friendRequest, setFriendRequest] = useState(0);
  const [messages, setMessages] = useState(0);


  const handleTabChange = (event, newValue) => {
    //console.log("handleTabChange is called.");
    setSelectedTab(newValue);
  };

  const handleLevelSelected = (value) => {
    //console.log("handleLevelSelected is called.");
    setSelectedTab("roomlist");
  };

  const handleRoomSelectedEvent = (value, rmId, rmLevel, rmName) => {
    //console.log("handleRoomListEvent is called.");
    if(value == "back")
      setSelectedTab("roomlevel");
    else if(value == "room")
      onScreenChange("gameroom", rmId, rmLevel, rmName);
    
  };

  const handleLogout = () => {
    onScreenChange("logout");
  };

  let interval = null;
  useEffect(() => {
    getIntervalData();
    if(interval === null){
      interval = setInterval(() => {
        getIntervalData();
      },1000);
    }
  }, []);


  

  const getIntervalData = async () => {
    let formData = new FormData();
    formData.append('userId', userInfo.userId);
    const response = await fetch(packageJson.server + 'api/intervaldata', {
      method: 'POST',
      body: formData
    });
    // Check if the response is successful (status code 200)
    if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if(responseData != null){
          if(responseData.status == "SUCCESS"){
            setJackpot(responseData.data.jackpot);
            //alert(userInfo.roomName);
            //userInfo.balance = responseData.data.balance;
            //setUserInfo(userInfo);
            if(userInfo != null && typeof(userInfo) !== "undefined"){
              setUserInfo({...userInfo, balance: responseData.data.balance});
            }
            setFriendRequest(responseData.data.friendRequest);
            setMessages(responseData.data.messages);
          }
        }
    } 
  };

  return (
    <div style={{backgroundAttachment: "fixed", padding: 0, margin: 0, backgroundRepeat: "no-repeat", backgroundImage: "url('./images/girl13.jpg')", backgroundSize: "contain"}}>
      <div style={{position: "fixed", backgroundColor: "black", zIndex: "99"}}>
      <svg width={canvasWidth} height={headerHeight} xmlns="http://www.w3.org/2000/svg">

<defs>
  <linearGradient id="titleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
    <stop offset="30%" style={{stopColor : "#ffdd99"}} />
    <stop offset="100%" style={{stopColor : "#dd8800"}} />
  </linearGradient>
  <linearGradient id="titleStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
    <stop offset="30%" style={{stopColor : "#aa8800"}} />
    <stop offset="70%" style={{stopColor : "#000000"}} />
  </linearGradient>
</defs>
<rect x={canvasCenterX - (jackPotBGWidth/2)} y="10" rx={jackPotBGHeight/2} ry={jackPotBGHeight/2} width={jackPotBGWidth} height={jackPotBGHeight} fill="url(#titleGradient)" stroke="url(#titleStrokeGradient)" strokeWidth="5" />
<text x="10" y={headerHeight/2} dominantBaseline={"middle"} fill="url(#titleGradient)" stroke="url(#titleStrokeGradient)" fontSize={gameNameFontSize} strokeWidth="2"  >
  7 Stars
</text>
<text x={canvasCenterX} y={10 + (jackPotBGHeight/2)} fill="black" dominantBaseline={"middle"} textAnchor='middle'  fontSize={jackPotMoneyFontSize} fontWeight={"bold"}   >
🏆 $ {jackpot}
</text>
<text x={canvasCenterX - (jackPotBGWidth * 0.8)} y={10 + (jackPotBGHeight/2)} dominantBaseline={"middle"} textAnchor="middle" fill="#fff" stroke='#700' strokeWidth={2} fontFamily='impact' fontSize={jackPotFontSize} fontWeight={"bold"} fontStyle={"italic"}  >
  JACK POT
</text>
{/* <rect x={canvasWidth - (balanceBGWidth)} y="0" rx={5} ry={5} width={balanceBGWidth} height={balanceBGHeight} fill="#06a" stroke="#aaf" strokeWidth="2" /> */}
<text x={canvasWidth - (balanceFontSize * 4)} y={10 + (balanceFontSize / 2)} fill="#fc9"  fontSize={balanceFontSize} dominantBaseline={"middle"} textAnchor="middle" fontWeight={"bold"}   >
💰 $ {userInfo.balance}
</text>

</svg>
      </div>

      <Container  style={{paddingTop: headerHeight, height:"100vh", overflow: "scroll"}}>
          {selectedTab === 'roomlist' && <RoomList onRoomSelected={handleRoomSelectedEvent} />}
          {selectedTab === 'cashin' && <CashIn />}
          {selectedTab === 'cashout' && <CashOut />}
          {selectedTab === 'friends' && <Friends />}
          {selectedTab === 'messages' && <MessagesScreen />} 
          {selectedTab === 'profile' && <Profile onLogout={handleLogout} />} 
       </Container>

      <BottomNavigation
        value={selectedTab}
        onChange={handleTabChange}
        style={{ position: 'fixed', bottom: 0, width: '100%' }}
        sx={{ backgroundColor: 'rgba(255,200,0,0.3)' }} >
        <BottomNavigationAction className='tabButton' label="Game" value="roomlist" icon={<CasinoIcon  />} />
        <BottomNavigationAction className='tabButton' label="ငွေသွင်း" value="cashin" icon={<AddCardIcon  />} />
        <BottomNavigationAction className='tabButton' label="ငွေထုတ်" value="cashout" icon={<CreditScoreIcon />} />
        <BottomNavigationAction className='tabButton' label="သူငယ်ချင်း" value="friends" icon={<Badge badgeContent={friendRequest} color="error" invisible={friendRequest == 0 ? true : false}>
  <PeopleIcon color="action" />
</Badge>} />
        <BottomNavigationAction className='tabButton' label="စာ" value="messages" icon={<Badge badgeContent={messages} color="error" invisible={messages == 0 ? true : false}>
  <EmailIcon color="action" />
</Badge>} /> 
        <BottomNavigationAction className='tabButton' label="ပရိုဖိုင်" value="profile" icon={<PersonIcon  />} /> 
      </BottomNavigation>
    </div>
  );
};

export default MainScreen;
