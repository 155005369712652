import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper,
  Grid, TextField, Button, Container, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import { styled } from '@mui/system';

import EmailIcon from '@mui/icons-material/Email';
import { useUserContext } from './UserContext';
import "./Constant";

import packageJson from "../package.json";
import { regEx } from './Constant';

const TabPanel = ({ value, index, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <div>{children}</div>}
  </div>
);


const StyledButton = styled(Button)({
  borderRadius: '50%',
  width: '100%', // Occupy the whole width of the container
  height: '100px', // Set a fixed height for the buttons
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem', // Adjust the font size as needed
});

const StyledContainer = styled(Container)({
  flexGrow: 1,
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});


export default function CashIn() {

  const { userInfo, setUserInfo } = useUserContext();

  const userIdRef = useRef(null);
  const [userIdError, setUserIdError] = useState(false);
  const [userIdHelperText, setUserIdHelperText] = useState("");
  const amountRef = useRef(null);
  const [amountError, setAmountError] = useState(false);
  const [amountHelperText, setAmountHelperText] = useState("");
  const [statusMsg, setStatusMsg] = useState("");

  const [histories, setHistories] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);

  const [updateTable, setUpdateTable] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');


  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleRequest = () => {
    let userId = userIdRef.current.value;
    let amount = amountRef.current.value;
    let validationFail = false;

    if (userId.trim() === "") {
      setUserIdError(true);
      setUserIdHelperText("User ID, required.");
      validationFail = true;
    } else if (regEx.userId.test(userId) == false) {
      setUserIdError(true);
      setUserIdHelperText("User ID, invalid character.");
      validationFail = true;
    } else {
      setUserIdError(false);
      setUserIdHelperText("");
    }

    if (amount.trim() === "") {
      setMessage("ငွေပမာဏထဲ့ပါ။");
      setOpen(true);
      validationFail = true;
    } else if (regEx.num.test(amount) == false) {

      setMessage("ငွေပမာဏကို ဂဏန်းရိုက်ထဲ့ပါ။");
      setOpen(true);
      validationFail = true;
    } else if (amount == 0) {

      setMessage("ငွေပမာဏထဲ့ပါ။");
      setOpen(true);
      validationFail = true;
    } else if (parseInt(amount) < 3000) {

      setMessage("အနည်းဆုံးငွေသွင်းရန်ပမာဏမှာ  ၃၀၀၀ကျပ် ဖြစ်ပါသည်။");
      setOpen(true);
      validationFail = true;
    } else {
      setAmountError(false);
      setAmountHelperText("");
    }

    if (!validationFail) {
      cashIn();
    }
  };

  const handleDialogClose = () => {
    setOpen(false);

  };


  const cashIn = async () => {
    try {
      let formData = new FormData();
      formData.append('userId', userInfo.userId);
      formData.append('amount', amountRef.current.value);
      // Make a GET request to the URL
      const response = await fetch(packageJson.server + 'api/cashin', {
        method: 'POST',
        body: formData

      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if (responseData != null) {
          if (responseData.status == "SUCCESS") {
            setUpdateTable(!updateTable);
            setMessage("ငွေထဲ့သွင်းမှု အောင်မြင်ပါသည်။");
            setOpen(true);
          } else {
            setMessage(responseData.msg);
            setOpen(true);
          }
        } else {
          setMessage("ERROR!");
          setOpen(true);
        }
      } else {
        setMessage("ERROR!");
        setOpen(true);
      }
    } catch (error) {
      setMessage("ERROR!");
      setOpen(true);
    }
  };


  useEffect(() => {
    getHistories();
  }, [updateTable]);

  useEffect(() => {
    let interval = setInterval(() => {
      getHistories();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
      
  });



  const getHistories = async () => {
    try {
      let formData = new FormData();
      formData.append('player_id', userInfo.id);
      // Make a GET request to the URL
      const response = await fetch(packageJson.server + 'api/cashinhistory', {
        method: 'POST',
        body: formData

      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if (responseData != null) {
          if (responseData.status == "SUCCESS") {
            setHistories(responseData.data.histories);
          } else {

          }
        } else {

        }
      } else {

      }
    } catch (error) {

    }
  };

  return (
    <div style={{}}>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label="ငွေသွင်း" style={{ color: "rgb(255,200,0)" }} className='tab' />
          <Tab label="ငွေသွင်းသမိုင်း" style={{ color: "rgb(255,200,0)" }} className='tab' />
        </Tabs>
        <TabPanel value={tabIndex} index={0} >


          <Grid container spacing={3}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="center" style={{ paddingTop: "30px", display: 'flex', alignItems: 'center' }}>
                <TextField
                  readOnly
                  size='small'
                  className='textField'
                  inputRef={userIdRef}
                  label="User ID"
                  aria-readonly={true}
                  value={userInfo.userId}
                  fullWidth margin="normal" />
                <TextField
                  size='small'
                  className='textField'
                  inputRef={amountRef}
                  label="ပမာဏ"
                  error={amountError}
                  helperText={amountHelperText}
                  fullWidth margin="normal" />
                <Button size='small' className='button' variant="contained" color="primary" onClick={handleRequest}>
                  ငွေသွင်းပါ
                </Button>
                <Grid container justifyContent="center">
                  <Typography style={{ fontSize: 12 + "px", color: "red", margin: "10px" }} gutterBottom>
                    &nbsp;{statusMsg}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <TableContainer style={{ height: "50vh" }}>
            <Table stickyHeader style={{ backgroundColor: "rgba(200,170,0,1)" }}>
              <TableHead>
                <TableRow key="h2">
                  <TableCell>No</TableCell>
                  <TableCell>Trax ID</TableCell>
                  <TableCell>ပမာဏ</TableCell>
                  <TableCell>တောင်းဆိုတယ့်နေ့</TableCell>
                  <TableCell>လက်ခံ/ငြင်းပယ်သည့်နေ့</TableCell>
                  <TableCell>အခြေအနေ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {histories != null && histories.map((history) => (
                  <TableRow key={history.id}>
                    <TableCell>{history.index}</TableCell>
                    <TableCell>{history.traxnId}</TableCell>
                    <TableCell>{history.amount}</TableCell>
                    <TableCell>{history.request_on}</TableCell>
                    <TableCell>{history.approve_on}</TableCell>
                    {history.status == "APPROVE" && <TableCell style={{ color: "#0f0" }}>{history.status}</TableCell>}
                    {history.status == "REJECT" && <TableCell style={{ color: "#f00" }}>{history.status}</TableCell>}
                    {history.status != "APPROVE" && history.status != "REJECT" && <TableCell style={{ color: "#fff" }}>{history.status}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} >
        <DialogTitle size="sm" sx={{ border: "solid #a60 1px", backgroundColor: "#e90" }}>Message</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ padding: "30px" }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className='button' size="small" variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );


}