import React, { useEffect, useState, useRef } from 'react';


const SplashScreen = ({ onFinish }) => {


  const canvasWidth = document.body.clientWidth;
  const canvasHeight = window.innerHeight;
  const canvasCenterX = canvasWidth / 2;
  const canvasCenterY = canvasHeight / 2;
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    // const inter = setInterval(() => {
    //   setProgress(progress + ((canvasWidth - 200)/150));
    // }, 300);
    const timer = setTimeout(() => {
      //clearInterval(inter);
      onFinish();
    }, 5000); // Display splash screen for 3 seconds

    return () => clearTimeout(timer);
  }, [onFinish, progress]);



  return (
    <svg style={{backgroundColor: "black"}} width={canvasWidth} height={canvasHeight}>
        <defs>
        <linearGradient id="titleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="30%" style={{stopColor : "#ffdd99"}} />
          <stop offset="100%" style={{stopColor : "#dd8800"}} />
        </linearGradient>
        <linearGradient id="titleStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
          <stop offset="30%" style={{stopColor : "#aa8800"}} />
          <stop offset="70%" style={{stopColor : "#000000"}} />
        </linearGradient>
      </defs>
        <image x={canvasCenterX - ((canvasHeight*0.8)/2)} y={(canvasHeight*0.15/2)} height={canvasHeight*0.8} href="./images/star1.jpg"></image>
        <text x="20" y={10} dominantBaseline={"hanging"} fill="url(#titleGradient)" stroke="url(#titleStrokeGradient)" fontSize={canvasHeight/10} strokeWidth="2"  >
        7 Stars
      </text>
      <text x={canvasWidth - 10} y={10} dominantBaseline={"hanging"} textAnchor='end' fill="white"  fontSize={canvasHeight/40} strokeWidth="2"  >
        Version : 1.0
      </text>
        <rect x={100} y={canvasHeight - 40} rx={5} ry={5} width={canvasWidth - 200} height={15} fill='none' stroke='#fdd017' strokeWidth={1}></rect>
        <rect x={100} y={canvasHeight - 40} rx={5} ry={5} width={progress} height={15}>
          <animate attributeName="fill" values="#fff027;" dur="5s" repeatCount="indefinite" />
          <animate attributeName="width" from={0} to={canvasWidth-200} dur="5s"  />
        </rect>
    </svg>
  );
};

export default SplashScreen;
