import React, { useContext, useState } from 'react';
import Splash from './Splash';
import Login from './Login';
import MainScreen from './MainScreen';
import Register from './Register';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import GameRoom from './GameRoom';
import { SocketContext } from './SocketContext';
import packageJson from '../package.json';

import "./App.css";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const App = () => {
  const [showLogIn, setShowLogIn] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [showGame, setShowGame] = useState(false);
  const [showMain, setShowMain] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomLevel, setRoomLevel] = useState("");
  const [roomName, setRoomName] = useState("");

  const {socket} = useContext(SocketContext);

  const handleSplashFinish = () => {
    setShowSplash(false);
    setShowLogIn(true);
  };

  const handleLoginSuccess = () => {
    setShowLogIn(false);
    setShowMain(true);
    showMessageDialog();
  };

  const handleRegisterClick = () => {
    setShowLogIn(false);
    setShowRegister(true);
  };

  const handleRegister = () => {
    setShowRegister(false);
    setShowLogIn(true);
  };

  const handleRegisterBack = () => {
    setShowRegister(false);
    setShowLogIn(true);
  };

  const handleScreenChange = (value, rmId = "", rmLevel = "", rmName = "") => {
    if(value == "gameroom"){
      setRoomId(rmId);
      setRoomLevel(rmLevel);
      setRoomName(rmName);
      setShowGame(true);
      setShowMain(false);
    }else if(value == "logout"){
      setShowGame(false);
      setShowMain(false);
      setShowRegister(false);
      setShowLogIn(true);
    }else{
      setShowGame(false);
      setShowMain(true);
    }
  };

  const handleBack = () => {
    setShowGame(false);
    setShowMain(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  }

  const showMessageDialog = async () => {
    try {
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/getPopupMessage', {
            method: 'POST',
            body: null
            
        });
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setMessage(responseData.data.message);
                setShowDialog(true);
              }
            }else{
              
            }
        }
    } catch (error) {
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
    <div>

      {showSplash && <Splash onFinish={handleSplashFinish} />}

{showGame && ( <GameRoom onBack={handleBack} rmId={roomId} rmLevel={roomLevel} rmName={roomName} /> )}

{showMain && (<MainScreen onScreenChange={handleScreenChange} /> )}

      {showLogIn && ( <Login onLoginSuccess={handleLoginSuccess} onRegisterClick={handleRegisterClick} /> )}

      {showRegister && ( <Register onRegisterBack={handleRegisterBack} /> )}


    </div>

    <Dialog open={showDialog} onClose={handleClose} fullWidth={true} maxWidth="md" >
        <DialogTitle sx={{backgroundColor: "#e90", border: "solid 2px #a60", fontFamily: "Pyidaungsu MM"}} >ကြေညာချက်</DialogTitle>
        <DialogContent>
         
          <DialogContentText sx={{padding: "30px"}}>
          {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* Button to close the dialog */}
          <Button className="button" onClick={handleClose} color='primary' variant='contained' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default App;
