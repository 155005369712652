// MessagesScreen.js
import React, { useRef, useEffect, useState, useContext } from "react";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper,
Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import { useUserContext } from "./UserContext";

import packageJson from "../package.json";

const MessagesScreen = () => {


  const canvasWidth = document.body.clientWidth;
  const canvasHeight = window.innerHeight;

  const {userInfo, setUserInfo} = useUserContext();

  const [messages, setMessages] = useState(null);
  
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  // Function to open the dialog and set the message
  const openDialog = (message, id) => {
    setMessage(message);
    readMessage(id);
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    
    getMessages();
  }, []);



  const getMessages = async () => {
    try {
      let formData = new FormData();
      formData.append('id', userInfo.id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/messages', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setMessages(responseData.data.messages);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const readMessage = async (id) => {
    try {
      let formData = new FormData();
      formData.append('user_id', userInfo.id);
      formData.append('id', id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/readmessage', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setMessages(responseData.data.messages);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };


  const deleteMessage = async (id) => {
    try {
      let formData = new FormData();
      formData.append('user_id', userInfo.id);
      formData.append('id', id);
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/deletemessage', {
            method: 'POST',
            body: formData
            
        });
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                setMessages(responseData.data.messages);
              }else{
                
              }
            }else{
              
            }
        } else {
          
        }
    } catch (error) {
      
    }
  };
  

  return (
    <div  style={{}}>
      <Container >
      <Typography variant="h4" align="center" gutterBottom>
        စာများ
      </Typography>

      <TableContainer component={Paper}   style={{ height: "50vh"}}>
        <Table  stickyHeader component={Paper} style={{backgroundColor: "rgba(200,170,0,1)"}}>
          <TableHead  >
            <TableRow >
              <TableCell  ></TableCell>
              <TableCell >စာ</TableCell>
              <TableCell >နေ့စွဲ</TableCell>
              <TableCell  ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages != null && messages.length > 0 && messages.map((row) => (
              <TableRow key={row.id} >
                <TableCell>
                  <EmailIcon />
                </TableCell>
                <TableCell>{row.message.substring(0, 20) + " ..."}</TableCell>
                <TableCell>{row.create_on}</TableCell>
                <TableCell sx={{display: "flex", justifyContent:"flex-end"}} >
                <Button className="button" variant='contained' size='small' onClick={() => {openDialog(row.message, row.id);}} >Read</Button>&nbsp;
                <Button className="button" variant='contained' size='small' sx={{color: "white", backgroundColor: "red"}} onClick={() => {deleteMessage(row.id);}} >Delete</Button>
                </TableCell>
              </TableRow>
            ))}
            {Array.isArray(messages) && messages.length <= 0 && 
              <TableRow key="x" >
                <TableCell>
                </TableCell>
                <TableCell>စာမရှိပါ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
    <Dialog open={open} onClose={handleClose} fullWidth={true} >
    <DialogTitle size="sm"  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>Message</DialogTitle>
<DialogContent>
  <DialogContentText sx={{padding: "30px"}}>
    {message}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => setOpen(false)}  className='button' size="small" variant="contained" color="primary">
    Close
  </Button>
</DialogActions>
      </Dialog>
    </div>
  );
};

export default MessagesScreen;
