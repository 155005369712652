import React, {useEffect, useContext, useState, useRef} from 'react';

//custom import

import { useUserContext } from './UserContext';
import { requestMessage } from './Constant';
import { SocketContext } from './SocketContext';

import packageJson from '../package.json';
import { DialogContentText, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { fontSize } from '@mui/system';
//import { Textsms } from '@mui/icons-material';
//import { request } from 'express';
import { regEx } from './Constant';
import userEvent from '@testing-library/user-event';


const RoomList = ({ onRoomSelected }) => {
  const { socket } = useContext(SocketContext);

  //user info
  const {userInfo, setUserInfo} = useUserContext();
  const [rooms, setRooms] = useState(null);
  const [statusMsg, setStatusMsg] = useState("");
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const roomNumberRef = useRef(null);
  const pinNumberRef = useRef(null);
  const roomSelected = useRef(true);
  const selectedLevel = useRef(1);
  const levelOneRect = useRef(null);
  const levelTwoRect = useRef(null);
  const levelThreeRect = useRef(null);
  const levelFourRect = useRef(null);
  const levelFiveRect = useRef(null);
  const gameRoomSetupId = useRef(0);

  let requestMessage = {};
  let responseMessage = {};

  const [customDialogVisibility, setCustomDialogVisibility] = useState("hidden");
  const [createDialogVisibility, setCreateDialogVisibility] = useState("hidden");

  const canvasWidth = document.body.clientWidth * 0.9;
  const canvasHeight = window.innerHeight * 0.9;
  const canvasCenterX = canvasWidth / 2;
  const canvasCenterY = canvasHeight / 2;
  const chipWidthHeight = canvasHeight * 0.6 / 2;
  const chipHorizontalSpacing = ((canvasWidth - canvasCenterX - (chipWidthHeight * 2)) / 2) ;
  const chipVerticalSpacing = ((canvasHeight - (chipWidthHeight * 2)) / 3);

  const chipOneX = canvasCenterX - chipWidthHeight;
  const chipTwoX = canvasCenterX + chipHorizontalSpacing;
  const chipThreeX = canvasCenterX + chipHorizontalSpacing + chipWidthHeight + chipHorizontalSpacing;

  const chipOneY = canvasHeight*0.05;
  const chipTwoY = chipWidthHeight + chipVerticalSpacing;

  const chipThreeY = canvasHeight*0.05;


  const titleSize = chipWidthHeight * 0.15;
  const textSize = chipWidthHeight * 0.12;

  let orgX = canvasCenterX - chipWidthHeight;
  let orgY = (canvasHeight*0.05) + (chipWidthHeight/2);
  let posX = 0;
  let posY = 0;
  let pos = 0;

  //custome room login
  let __customDialogWidth = canvasWidth * 0.8;
  let __customDialogHeight = canvasHeight * 0.7;
  let __customDialogRxRy = __customDialogHeight / 10;
  let __customDialogX = canvasCenterX - (__customDialogWidth/2);
  let __customDialogY = canvasCenterY - (__customDialogHeight/2);
  let __customDialogOutterPadding = __customDialogHeight / 10;
  let __customDialogInnerPadding = __customDialogHeight / 20;
  let __customDialogStrokeWidth = __customDialogHeight / 20;

  let __lineHeight = __customDialogHeight / 9;
  let __textSize = __lineHeight * 0.3;
  let __textBoxTextSize = __lineHeight * 0.8;

  let __actionButtonHeight = __customDialogHeight / 10;
  let __actionButtonWidth = __actionButtonHeight * 3;
  let __actionButtonRxRy = __actionButtonHeight / 2;
  let __actionButtonStrokeWidth = __actionButtonHeight / 10;
  let __actionButtonFontSize = __actionButtonHeight / 2.5;

  let __createButtonX = __customDialogX + __customDialogOutterPadding;
  let __createButtonY = canvasCenterY + (__customDialogHeight/2) - __customDialogOutterPadding - __actionButtonHeight;

  let __joinButtonX = __customDialogX + __customDialogWidth - __customDialogOutterPadding - __actionButtonWidth;
  let __joinButtonY = canvasCenterY + (__customDialogHeight/2) - __customDialogOutterPadding - __actionButtonHeight;

  let __titleFontSize = __customDialogHeight / 10;
  let __createRoomTitleX = __customDialogX + (__customDialogWidth / 4);
  let __createRoomTitleY = __customDialogY ;

  let __joinRoomTitleX = __customDialogX + (__customDialogWidth / 4 * 3);
  let __joinRoomTitleY = __customDialogY ;

  let [roomNumber, setRoomNumber] = useState("------");
  let [pinNumber, setPinNumber] = useState("----");
  let __roomNumberX = canvasCenterX + __customDialogInnerPadding;
  let __roomNumberY = (__customDialogY + __customDialogOutterPadding);
  // let __roomNumberWidth = ((__customDialogWidth / 2) - __customDialogOutterPadding - __customDialogInnerPadding);
  let __roomNumberHeight = __lineHeight;
  let __roomNumberWidth = __lineHeight * 6;

  let __pinNumberX = canvasCenterX + __customDialogInnerPadding;
  let __pinNumberY = (__customDialogY + __customDialogOutterPadding + (__lineHeight * 2));
  // let __pinNumberWidth = (__roomNumberWidth / 6 * 4);
  let __pinNumberHeight = __lineHeight;
  let __pinNumberWidth = __lineHeight * 4;

  let __inputWidthHeight = __lineHeight;
  let __inputX = canvasCenterX + __customDialogInnerPadding;
  let __inputY = __pinNumberY + __pinNumberHeight + __customDialogInnerPadding;

  let __inputTextGap = __inputWidthHeight + __customDialogInnerPadding;
  let __inputTextX = __inputX + (__inputWidthHeight/2);
  let __inputTextY = __inputY + (__inputWidthHeight/2);
  let __inputTextSize = __inputWidthHeight * 0.7;




  //custom room setup
  let __setUpDialogWidth = 0;
  let __setupDialogHeight = 0;

  let __levelRectWidthHeight = __customDialogHeight / 4;
  let __levelRectRxRy = __levelRectWidthHeight / 10;
  let __levelRectHeaderHeight = __levelRectWidthHeight / 3;
  let __levelRectBodyHeight = __levelRectWidthHeight - __levelRectHeaderHeight;
  let __levelRectTitleFontSize = __levelRectHeaderHeight * 0.8;
  let __levelRectFontSize = __levelRectBodyHeight / 4;
  let __levelRectHSpacing = ((__customDialogWidth / 2) - __customDialogOutterPadding - __customDialogInnerPadding - (__levelRectWidthHeight*2))/2;
  let __levelRectVSpacing = ((__customDialogHeight) - (__customDialogOutterPadding*2) - (__levelRectWidthHeight*3))/2;
  let __levelRectX = __customDialogX + __customDialogOutterPadding;
  let __levelRectY = __customDialogY + __customDialogOutterPadding;


  let x = [];
  let y = [];
  for(let i = 0; i < 6; i++){
    x[i] = orgX + ((posX * chipHorizontalSpacing) + (posX * chipWidthHeight));
    y[i] = orgY + ((posY * chipVerticalSpacing) + (posY * chipWidthHeight));
    if(posX == 2){
      posX = 0;
      posY = 1;
    }else{
      posX += 1;
    }
  }
  //console.log(x);
  //console.log(y);

  const showCustomDialog = () => {
    setCustomDialogVisibility("visiblie");
  };


  const handleRoomSelect = (level, roomName, minimumBalance) => {
    if(userInfo.balance < minimumBalance){
      setAlertDialogVisible(true);
      setStatusMsg("လက်ကျန်ငွေ မလုံလောက်ပါ။");
      return;
    }

    if(roomName === "CUSTOM"){
      showCustomDialog();
      return;
    }

    requestMessage.data = {
      userId : userInfo.userId,
      player_id : userInfo.id,
      avatar : userInfo.avatar,
      balance : userInfo.balance,
      roomLevel : level
    };//use array index, thus 0 is for Level 1
    // Connect to the Socket.IO server
    if(socket != null){
      socket.emit('joinRoom', requestMessage);
      // Listen for server messages
      socket.on('joinRoom', (response) => {
          if(response.status == "SUCCESS"){

            if(userInfo != null && typeof(userInfo) !== "undefined"){
              setUserInfo(userInfo => {return {...userInfo, seatNo : response.data.seatNo,
                roomLevel : response.data.room.roomLevel,
                roomId : response.data.room.roomId,
                roomName : "aaaaa"}});

              // Perform  logic
              onRoomSelected("room", response.data.room.roomId, response.data.room.roomLevel, response.data.room.roomName);
            }
              
          }else{

          }
      });
    }
  };

  
  
  useEffect(() => {
    getRoomSetUp();
  }, []);


  const getRoomSetUp = async (userId, password) => {
    try {
        // Make a GET request to the URL
        const response = await fetch(packageJson.server + 'api/roomsetup', {
            method: 'POST',
            body: ""
            
        });
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // Parse the JSON response
            const responseData = await response.json();
            if(responseData != null){
              if(responseData.status == "SUCCESS"){
                //console.log("ok");
                setRooms(responseData.data.room_setup);
              }else{
                //console.log(responseData.msg);
              }
            }else{
              //console.log("No server response.");
            }
        } else {
          //console.log("Something wrong.");
        }
    } catch (error) {
        //console.error('Error fetching data:', error);
    }
  };

  const showCreateDialog = () => {
    roomSelected.current = false;
    setCustomDialogVisibility('hidden');
    setCreateDialogVisibility('visible');
  };

  const keyPress = (key) => {
    if(roomSelected.current){
      let index = roomNumber.indexOf("-");
      let result = roomNumber;
      let pad = "";
      if(key == "delete"){
        if(index == 0){
          
        }else if(index > 0){
          result = roomNumber.substring(0, index - 1);
          pad = "".padEnd((6 - index + 1),"-");
        }else{
          result = roomNumber.substring(0,5);
          pad = "-";
        }
      }else{
        if(index == 0){
          result = key;
          pad = "".padEnd((6 - index - 1),"-");
        }else if(index > 0){
          result = roomNumber.substring(0, index) + key;
          pad = "".padEnd((6 - index - 1),"-");
        }else{
          result = roomNumber;
          pad = "";
        }
      }
      setRoomNumber(result + pad);
    }else{
      let index = pinNumber.indexOf("-");
      let result = pinNumber;
      let pad = "";
      if(key == "delete"){
        if(index == 0){
          
        }else if(index > 0){
          result = pinNumber.substring(0, index - 1);
          pad = "".padEnd((4 - index + 1),"-");
        }else{
          result = pinNumber.substring(0,3);
          pad = "-";
        }
      }else{
        if(index == 0){
          result = key;
          pad = "".padEnd((4 - index - 1),"-");
        }else if(index > 0){
          result = pinNumber.substring(0, index) + key;
          pad = "".padEnd((4 - index - 1),"-");
        }else{
          result = pinNumber;
          pad = "";
        }
      }
      setPinNumber(result + pad);
    }

  };


  const focusChanged = (control) => {
    if(control == "room"){
      roomNumberRef.current.style.stroke = "#fec";
      pinNumberRef.current.style.stroke = "#fd9";
      roomSelected.current = true;
    }else{
      roomNumberRef.current.style.stroke = "#fd9";
      pinNumberRef.current.style.stroke = "#fec";
      roomSelected.current = false;
    }
  };

  const levelChanged = (level) => {
    if(level == 1){
      selectedLevel.current = 1;
      levelOneRect.current.style.stroke = "#ffeedd";
      levelTwoRect.current.style.stroke = "#995500";
      levelThreeRect.current.style.stroke = "#995500";
      levelFourRect.current.style.stroke = "#995500";
      levelFiveRect.current.style.stroke = "#995500";
    }else if(level == 2){
      selectedLevel.current = 2;
      levelOneRect.current.style.stroke = "#995500";
      levelTwoRect.current.style.stroke = "#ffeeaa";
      levelThreeRect.current.style.stroke = "#995500";
      levelFourRect.current.style.stroke = "#995500";
      levelFiveRect.current.style.stroke = "#995500";
    }else if(level == 3){
      selectedLevel.current = 3;
      levelOneRect.current.style.stroke = "#995500";
      levelTwoRect.current.style.stroke = "#995500";
      levelThreeRect.current.style.stroke = "#ffeedd";
      levelFourRect.current.style.stroke = "#995500";
      levelFiveRect.current.style.stroke = "#995500";
    }else if(level == 4){
      selectedLevel.current = 4;
      levelOneRect.current.style.stroke = "#995500";
      levelTwoRect.current.style.stroke = "#995500";
      levelThreeRect.current.style.stroke = "#995500";
      levelFourRect.current.style.stroke = "#ffeedd";
      levelFiveRect.current.style.stroke = "#995500";
    }else if(level == 5){
      selectedLevel.current = 5;
      levelOneRect.current.style.stroke = "#995500";
      levelTwoRect.current.style.stroke = "#995500";
      levelThreeRect.current.style.stroke = "#995500";
      levelFourRect.current.style.stroke = "#995500";
      levelFiveRect.current.style.stroke = "#ffeedd";
    }
  };

  const createRoom = async () => {

    if(regEx.num.test(pinNumber) == false){
      setAlertDialogVisible(true);
      setStatusMsg("PIN နံပတ်ရိုက်ထဲ့ပါ။");
      return;
    }

    //check minimum balance
    for(let room of rooms){
      if(room.roomLevel == selectedLevel.current){
        gameRoomSetupId.current = room.id;
        if(parseInt(userInfo.balance) < parseInt(room.minimumBalance)){
          setAlertDialogVisible(true);
          setStatusMsg("လက်ကျန်ငွေ မလုံလောက်ပါ။");
          return;
        }
      }
    }
    // Connect to the Socket.IO server
    try{

      let formData = new FormData();
      formData.append('gameRoomSetupId',  gameRoomSetupId.current);
      formData.append('pinNumber', pinNumber);
      // Make a GET request to the URL
      const response = await fetch(packageJson.server + 'api/createcustomroom', {
          method: 'POST',
          body: formData
          
      });
      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();
        if(responseData != null){
          if(responseData.status == "SUCCESS"){
            setRoomNumber(responseData.data.roomNumber);
            setAlertDialogVisible(true);
            setStatusMsg("Room No: "+responseData.data.roomNumber+"       PIN No:" + pinNumber);
            setCreateDialogVisibility("hidden");
            setCustomDialogVisibility("visible");
          }else{
            setAlertDialogVisible(true);
            setStatusMsg("Failed!");
          }
        }else{
          setAlertDialogVisible(true);
          setStatusMsg("Response is empty!");
        }
      } else {
        setAlertDialogVisible(true);
        setStatusMsg("Connection Error!");
      }
    } catch (error) {
      setAlertDialogVisible(true);
      setStatusMsg("Unknown Error!");
    }
  };


  const joinCustomRoom = () => {

    requestMessage.data = {
      userId : userInfo.userId,
      player_id : userInfo.id,
      avatar : userInfo.avatar,
      balance : userInfo.balance,
      roomNumber : roomNumber,
      pinNumber : pinNumber
    };//use array index, thus 0 is for Level 1
    // Connect to the Socket.IO server
    if(socket != null){
      socket.emit('joinCustomRoom', requestMessage);
      // Listen for server messages
      socket.on('joinCustomRoom', (response) => {
          if(response.status == "SUCCESS"){
            if(userInfo != null && typeof(userInfo) !== "undefined"){
              // setUserInfo(userInfo => {return {...userInfo, seatNo : response.data.seatNo,
              //   roomLevel : response.data.room.roomLevel,
              //   roomId : response.data.room.roomId,
              //   roomName : "aaaaa"}});

              // Perform  logic
              onRoomSelected("room", response.data.room.roomId, response.data.room.roomLevel, response.data.room.roomName);
            }
              
          }else{
            //rooom join faile, show reason
            setAlertDialogVisible(true);
            setStatusMsg(response.message);
          }
      });

      socket.on('joinRoom', (response) => {
        if(response.status == "SUCCESS"){
          if(userInfo != null && typeof(userInfo) !== "undefined"){
            // setUserInfo(userInfo => {return {...userInfo, seatNo : response.data.seatNo,
            //   roomLevel : response.data.room.roomLevel,
            //   roomId : response.data.room.roomId,
            //   roomName : "aaaaa"}});

            // Perform  logic
            onRoomSelected("room", response.data.room.roomId, response.data.room.roomLevel, response.data.room.roomName);
          }
            
        }else{
          //rooom join faile, show reason
          setAlertDialogVisible(true);
          setStatusMsg(response.message);
        }
    });
    }
  };


  return (
    <div  style={{margin: 0, padding: 0, backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundImage: "url('images/girl13.jpg')", backgroundSize: "contain"}}>
      <svg x1={0} y1={0} width={canvasWidth } height={canvasHeight*0.9} style={{margin: 0, padding: 0}}>
        <defs>
          <radialGradient id='radialGradient' cx={0.5} cy={0.5} r={0.5}>
        <stop offset="0%" style={{stopColor: "#fff"}} />
        <stop offset="100%"  style={{stopColor: "#fd9"}} />
          </radialGradient>
          <radialGradient id='radialDarkGradient' cx={0.5} cy={0.5} r={0.5}>
        <stop offset="0%" style={{stopColor: "#ddd"}} />
        <stop offset="100%"  style={{stopColor: "#fd9"}} />
          </radialGradient>
        <pattern id="bulbPattern" x="-20" y="0" width="0.04" height="0.08" patternUnits="objectBoundingBox">
          <circle cx="10" cy="10" r="10" fill="url(#radialGradient)" />
        </pattern>
        <pattern id="verticalBulbPattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
          <circle cx="10" cy="10" r="10" fill="url(#radialGradient)" />
        </pattern>
        <pattern id="smallBulbPattern" x="0" y="0" width="5" height="5" patternUnits="userSpaceOnUse">
          <rect x={0} y={0} width={5} height={5} fill='#fd9' />
          <circle cx="2.5" cy="2.5" r="2.5" fill="url(#radialDarkGradient)" />
        </pattern>
      
        <linearGradient id="titleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ffdd99"}} />
            <stop offset="100%" style={{stopColor : "#dd8800"}} />
          </linearGradient>
          <linearGradient id="titleStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#aa8800"}} />
            <stop offset="70%" style={{stopColor : "#000000"}} />
          </linearGradient>
        <linearGradient id="redButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ff0000"}} />
            <stop offset="100%" style={{stopColor : "#aa0000"}} />
          </linearGradient>
          <linearGradient id="redButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#990000"}} />
            <stop offset="70%" style={{stopColor : "#330000"}} />
          </linearGradient>
          <linearGradient id="greenButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#00aa00"}} />
            <stop offset="100%" style={{stopColor : "#008800"}} />
          </linearGradient>
          <linearGradient id="greenButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#008800"}} />
            <stop offset="70%" style={{stopColor : "#003300"}} />
          </linearGradient>
        <linearGradient id="yellowButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ddbb00"}} />
            <stop offset="100%" style={{stopColor : "#aa7700"}} />
          </linearGradient>
          <linearGradient id="yellowButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ccaa00"}} />
            <stop offset="70%" style={{stopColor : "#996600"}} />
          </linearGradient>
        <linearGradient id="lightYellowButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ffdd00"}} />
            <stop offset="100%" style={{stopColor : "#cc9900"}} />
          </linearGradient>
          <linearGradient id="lightYellowButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#ccaa00"}} />
            <stop offset="70%" style={{stopColor : "#996600"}} />
          </linearGradient>
        <linearGradient id="orangeButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#eeaa00"}} />
            <stop offset="100%" style={{stopColor : "#cc8800"}} />
          </linearGradient>
          <linearGradient id="orangeButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#bb7700"}} />
            <stop offset="70%" style={{stopColor : "#995500"}} />
          </linearGradient>
        <linearGradient id="blackButtonGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="30%" style={{stopColor : "#666"}} />
            <stop offset="100%" style={{stopColor : "#333"}} />
          </linearGradient>
          <linearGradient id="blackButtonStrokeGradient" x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="30%" style={{stopColor : "#333"}} />
            <stop offset="70%" style={{stopColor : "#000"}} />
          </linearGradient>

          <rect id="button" width={__inputWidthHeight} height={__inputWidthHeight} rx={5} ry={5} fill='url(#lightYellowButtonGradient)' stroke='url(#lightYellowButtonStrokeGradient)' strokeWidth={1} />
          <rect id="redButton" width={__inputWidthHeight} height={__inputWidthHeight} rx={5} ry={5} fill='url(#redButtonGradient)' stroke='url(#redButtonStrokeGradient)' strokeWidth={1} />
        </defs>
      {/* <image x={0} y={(0)} height={canvasHeight*1.0}  href='./images/girl13.jpg' /> */}
      
      {rooms != null && rooms.map((room) => (
        <g key={pos}>
          <image x={x[pos] - (chipWidthHeight/2)} y={y[pos] - (chipWidthHeight/2)} width={chipWidthHeight} href={'images/chip'+(pos+1)+'.svg'} onClick={() => handleRoomSelect(room.roomLevel, room.roomName, room.minimumBalance)} style={{backgroundColor: "red"}} />
          <text onClick={() => handleRoomSelect(room.roomLevel, room.roomName, room.minimumBalance)}  x={x[pos]} y={y[pos] } style={{fontSize: (titleSize+"px"), fontWeight: "bold", fill: "#fff", textAnchor: "middle", dominantBaseline: "middle"}} >{room.roomName}</text>
          <text onClick={() => handleRoomSelect(room.roomLevel, room.roomName, room.minimumBalance)}  x={x[pos]} y={y[pos]- ( titleSize)} style={{fontSize: textSize, fontWeight: "bold", fill: "#fff", textAnchor: "middle", dominantBaseline: "middle"}} > {room.minimumBalance > 0 ? "$"+room.minimumBalance : ""}</text>
          <text onClick={() => handleRoomSelect(room.roomLevel, room.roomName, room.minimumBalance)}  x={x[pos]} y={(y[pos] + titleSize)} style={{fontSize: textSize, fontWeight: "bold", fill: "#fff", textAnchor: "middle", dominantBaseline: "middle"}} > {room.minimumBetAmount > 0 ? "$"+room.minimumBetAmount: ""}</text>
          {pos++ > 0 ? "": ""}
          
        </g>
      ))}

      <g visibility={customDialogVisibility}>
        <clipPath id='cp'>
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='url(#yellowButtonGradient)' stroke='url(#yellowButtonStrokeGradient)' strokeWidth={__customDialogStrokeWidth} />
        </clipPath>
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='url(#yellowButtonGradient)' stroke='url(#yellowButtonStrokeGradient)' strokeWidth={__customDialogStrokeWidth} />
        <circle onClick={() => setCustomDialogVisibility("hidden")} cx={__customDialogX + __customDialogWidth + (__lineHeight/2)} cy={__customDialogY} r={__lineHeight/2} fill='url(#redButtonGradient)' stroke='url(#redButtonStrokeGradient)' strokeWidth={2} />
        <text onClick={() => setCustomDialogVisibility("hidden")} x={__customDialogX + __customDialogWidth + (__lineHeight/2)} y={__customDialogY} fill='black' fontSize={__lineHeight/2}  dominantBaseline={'middle'} textAnchor='middle'>X</text>
        <image clipPath='url(#cp)' x={__customDialogX} y={__customDialogY}  height={__customDialogHeight} href='images/girl14.jpeg' />
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='none' stroke='#eca' strokeWidth={3} />
        {/* <rect x={__customDialogX - 10} y={__customDialogY - 10} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='none' stroke='url(#bulbPattern)' strokeWidth={20} /> */}
        {/* <line x1={__customDialogX - (__customDialogRxRy/2) + __customDialogRxRy} y1={__customDialogY} x2={__customDialogX + __customDialogWidth - (__customDialogRxRy/2)} y2={__customDialogY} stroke='url(#bulbPattern)' strokeWidth={40} />
        <line x1={__customDialogX - (__customDialogRxRy/2) + __customDialogRxRy} y1={__customDialogY + __customDialogHeight} x2={__customDialogX + __customDialogWidth - (__customDialogRxRy/2)} y2={__customDialogY + __customDialogHeight} stroke='url(#bulbPattern)' strokeWidth={20} />
        <line x1={__customDialogX} y1={__customDialogY - (__customDialogRxRy/2)} x2={__customDialogX} y2={__customDialogY + __customDialogHeight} stroke='url(#bulbPattern)' strokeWidth={20} /> */}
        
        
        
        <text x={__createRoomTitleX} y={__createRoomTitleY} fontSize={__titleFontSize} fill='#fea' stroke='url(#titleStrokeGradient)' strokeWidth={1} dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >CREATE ROOM</text>
        <text x={__joinRoomTitleX} y={__joinRoomTitleY} fontSize={__titleFontSize} fill='url(#titleGradient)' stroke='url(#titleStrokeGradient)' strokeWidth={1} dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >JOIN ROOM</text>

        
        <text x={__roomNumberX} y={__roomNumberY - __customDialogInnerPadding} fontSize={__textSize} fill='#fff' dominantBaseline={'middle'} textAnchor='start' > Room No ရိုက်ထဲ့ပါ</text>

        <rect ref={roomNumberRef} onClick={() => focusChanged("room")} x={__roomNumberX} y={__roomNumberY} width={__roomNumberWidth} height={__roomNumberHeight} rx={5} ry={5} fill='#960' stroke='#fd9' strokeWidth={3} />
        <text onClick={() => focusChanged("room")} x={__roomNumberX + (__roomNumberWidth/2)} y={__roomNumberY + (__roomNumberHeight/2)} fontSize={__textBoxTextSize} fill='#fff' dominantBaseline={'middle'} textAnchor='middle'letterSpacing={__textBoxTextSize/2} > {roomNumber}</text>

        <text x={__pinNumberX} y={__pinNumberY - __customDialogInnerPadding} fontSize={__textSize} fill='#fff' dominantBaseline={'middle'} textAnchor='start' > PIN No ရိုက်ထဲ့ပါ</text>
        <rect ref={pinNumberRef} onClick={() => focusChanged("pin")} x={__pinNumberX} y={__pinNumberY} width={__pinNumberWidth} height={__pinNumberHeight} rx={5} ry={5} fill='#960' stroke='#fd9' strokeWidth={3} />
        <text onClick={() => focusChanged("pin")} x={__pinNumberX + (__pinNumberWidth/2)} y={__pinNumberY + (__pinNumberHeight/2)} fontSize={__textBoxTextSize} fill='#fff' dominantBaseline={'middle'} textAnchor='middle'letterSpacing={__textBoxTextSize/2} > {pinNumber}</text>
        
        <use onClick={() => keyPress(1)} href='#button' x={__inputX} y={__inputY} />
        <use onClick={() => keyPress(2)} href='#button' x={__inputX + (__inputWidthHeight*1) + (__customDialogInnerPadding*1)} y={__inputY} />
        <use onClick={() => keyPress(3)} href='#button' x={__inputX + (__inputWidthHeight*2) + (__customDialogInnerPadding*2)} y={__inputY} />
        <use onClick={() => keyPress(4)} href='#button' x={__inputX + (__inputWidthHeight*3) + (__customDialogInnerPadding*3)} y={__inputY} />
        <use onClick={() => keyPress(5)} href='#button' x={__inputX + (__inputWidthHeight*4) + (__customDialogInnerPadding*4)} y={__inputY} />
        <use onClick={() => keyPress("delete")} href='#redButton' x={__inputX + (__inputWidthHeight*5) + (__customDialogInnerPadding*5)} y={__inputY} fill='url(#redButtonGradient)' stroke='url(#redButtonStrokeGradient)' />

        <text onClick={() => keyPress(1)} x={__inputTextX + (__inputTextGap * 0)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >1</text>
        <text onClick={() => keyPress(2)} x={__inputTextX + (__inputTextGap * 1)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >2</text>
        <text onClick={() => keyPress(3)} x={__inputTextX + (__inputTextGap * 2)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >3</text>
        <text onClick={() => keyPress(4)} x={__inputTextX + (__inputTextGap * 3)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >4</text>
        <text onClick={() => keyPress(5)} x={__inputTextX + (__inputTextGap * 4)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >5</text>
        <text onClick={() => keyPress("delete")} x={__inputTextX + (__inputTextGap * 5)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >X</text>

        <use onClick={() => keyPress(6)} href='#button' x={__inputX + (__inputWidthHeight*0) + (__customDialogInnerPadding*0)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(7)} href='#button' x={__inputX + (__inputWidthHeight*1) + (__customDialogInnerPadding*1)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(8)} href='#button' x={__inputX + (__inputWidthHeight*2) + (__customDialogInnerPadding*2)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(9)} href='#button' x={__inputX + (__inputWidthHeight*3) + (__customDialogInnerPadding*3)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(0)} href='#button' x={__inputX + (__inputWidthHeight*4) + (__customDialogInnerPadding*4)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />

        <text onClick={() => keyPress(6)} x={__inputTextX + (__inputTextGap * 0)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >6</text>
        <text onClick={() => keyPress(7)} x={__inputTextX + (__inputTextGap * 1)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >7</text>
        <text onClick={() => keyPress(8)} x={__inputTextX + (__inputTextGap * 2)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >8</text>
        <text onClick={() => keyPress(9)} x={__inputTextX + (__inputTextGap * 3)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >9</text>
        <text onClick={() => keyPress(0)} x={__inputTextX + (__inputTextGap * 4)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >0</text>

        <rect onClick={() => showCreateDialog()} x={__createButtonX} y={__createButtonY} width={__actionButtonWidth} height={__actionButtonHeight} rx={__actionButtonRxRy} ry={__actionButtonRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={__actionButtonStrokeWidth} />
        <text onClick={() => showCreateDialog()} x={(__createButtonX + (__actionButtonWidth/2))} y={(__createButtonY + (__actionButtonHeight/2))} fontSize={__actionButtonFontSize} fill='#fff' dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >Create Room</text>
        

        <rect x={__joinButtonX} y={__joinButtonY} width={__actionButtonWidth} height={__actionButtonHeight} rx={__actionButtonRxRy} ry={__actionButtonRxRy} fill='url(#greenButtonGradient)' stroke='url(#greenButtonStrokeGradient)' strokeWidth={__actionButtonStrokeWidth} />
        <text onClick={() => joinCustomRoom()} x={(__joinButtonX + (__actionButtonWidth/2))} y={(__joinButtonY + (__actionButtonHeight/2))} fontSize={__actionButtonFontSize} fill='white' dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >Join Room</text>
        
      </g>

      <g visibility={createDialogVisibility}>
        <clipPath id='cp'>
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='url(#yellowButtonGradient)' stroke='url(#yellowButtonStrokeGradient)' strokeWidth={__customDialogStrokeWidth} />
        </clipPath>
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='url(#yellowButtonGradient)' stroke='url(#yellowButtonStrokeGradient)' strokeWidth={__customDialogStrokeWidth} />
        <circle onClick={() => setCreateDialogVisibility("hidden")} cx={__customDialogX + __customDialogWidth + (__lineHeight/2)} cy={__customDialogY} r={__lineHeight/2} fill='url(#redButtonGradient)' stroke='url(#redButtonStrokeGradient)' strokeWidth={2} />
        <text onClick={() => setCreateDialogVisibility("hidden")} x={__customDialogX + __customDialogWidth + (__lineHeight/2)} y={__customDialogY} fill='black' fontSize={__lineHeight/2}  dominantBaseline={'middle'} textAnchor='middle'>X</text>
        <image clipPath='url(#cp)' x={__customDialogX} y={__customDialogY}  height={__customDialogHeight} href='images/girl14.jpeg' />
        <rect x={__customDialogX} y={__customDialogY} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='none' stroke='#eca' strokeWidth={3} />
        {/* <rect x={__customDialogX - 10} y={__customDialogY - 10} width={__customDialogWidth} height={__customDialogHeight} rx={__customDialogRxRy} ry={__customDialogRxRy} fill='none' stroke='url(#bulbPattern)' strokeWidth={20} /> */}
        {/* <line x1={__customDialogX - (__customDialogRxRy/2) + __customDialogRxRy} y1={__customDialogY} x2={__customDialogX + __customDialogWidth - (__customDialogRxRy/2)} y2={__customDialogY} stroke='url(#bulbPattern)' strokeWidth={40} />
        <line x1={__customDialogX - (__customDialogRxRy/2) + __customDialogRxRy} y1={__customDialogY + __customDialogHeight} x2={__customDialogX + __customDialogWidth - (__customDialogRxRy/2)} y2={__customDialogY + __customDialogHeight} stroke='url(#bulbPattern)' strokeWidth={20} />
        <line x1={__customDialogX} y1={__customDialogY - (__customDialogRxRy/2)} x2={__customDialogX} y2={__customDialogY + __customDialogHeight} stroke='url(#bulbPattern)' strokeWidth={20} /> */}
        
        
        <rect onClick={() => levelChanged(1)} ref={levelOneRect} x={__levelRectX} y={__levelRectY} width={__levelRectWidthHeight} height={__levelRectWidthHeight} rx={__levelRectRxRy} ry={__levelRectRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={3} />
        <text onClick={() => levelChanged(1)} x={__levelRectX + (__levelRectWidthHeight/2)} y={__levelRectY + (__levelRectHeaderHeight/2)} dominantBaseline={'middle'} textAnchor='middle' fontSize={__levelRectTitleFontSize} fontWeight={'bold'} fill='#995500' >LV 1</text>
        <line x1={__levelRectX} y1={__levelRectY + __levelRectHeaderHeight} x2={__levelRectX + __levelRectWidthHeight} y2={__levelRectY + __levelRectHeaderHeight} stroke='#995500' strokeWidth={3} />
        <text onClick={() => levelChanged(1)} x={__levelRectX + 10} y={__levelRectY + (__levelRectHeaderHeight) + __levelRectFontSize} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >💰 $1000</text>
        <text onClick={() => levelChanged(1)} x={__levelRectX + 10} y={__levelRectY + (__levelRectHeaderHeight) + (__levelRectFontSize*2.5)} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >🟡 $100</text>

        <rect onClick={() => levelChanged(2)} ref={levelTwoRect} x={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding} y={__levelRectY} width={__levelRectWidthHeight} height={__levelRectWidthHeight} rx={__levelRectRxRy} ry={__levelRectRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={3} />
        <text onClick={() => levelChanged(2)} x={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectWidthHeight/2)} y={__levelRectY + (__levelRectHeaderHeight/2)} dominantBaseline={'middle'} textAnchor='middle' fontSize={__levelRectTitleFontSize} fontWeight={'bold'} fill='#995500' >LV 2</text>
        <line x1={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding} y1={__levelRectY + __levelRectHeaderHeight} x2={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding + __levelRectWidthHeight} y2={__levelRectY + __levelRectHeaderHeight} stroke='#995500' strokeWidth={3} />
        <text onClick={() => levelChanged(2)} x={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding + 10} y={__levelRectY + (__levelRectHeaderHeight) + __levelRectFontSize} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >💰 $2000</text>
        <text onClick={() => levelChanged(2)} x={__levelRectX + __levelRectWidthHeight + __customDialogInnerPadding + 10} y={__levelRectY + (__levelRectHeaderHeight) + (__levelRectFontSize*2.5)} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >🟡 $200</text>

        <rect onClick={() => levelChanged(3)} ref={levelThreeRect} x={__levelRectX + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2)} y={__levelRectY} width={__levelRectWidthHeight} height={__levelRectWidthHeight} rx={__levelRectRxRy} ry={__levelRectRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={3} />
        <text onClick={() => levelChanged(3)} x={__levelRectX + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2) + (__levelRectWidthHeight/2)} y={__levelRectY + (__levelRectHeaderHeight/2)} dominantBaseline={'middle'} textAnchor='middle' fontSize={__levelRectTitleFontSize} fontWeight={'bold'} fill='#995500' >LV 3</text>
        <line x1={__levelRectX + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2)} y1={__levelRectY + __levelRectHeaderHeight} x2={__levelRectX + + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2) + __levelRectWidthHeight} y2={__levelRectY + __levelRectHeaderHeight} stroke='#995500' strokeWidth={3} />
        <text onClick={() => levelChanged(3)} x={__levelRectX + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2) + 10} y={__levelRectY + (__levelRectHeaderHeight) + __levelRectFontSize} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >💰 $3000</text>
        <text onClick={() => levelChanged(3)} x={__levelRectX + (__levelRectWidthHeight*2) + (__customDialogInnerPadding*2) + 10} y={__levelRectY + (__levelRectHeaderHeight) + (__levelRectFontSize*2.5)} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >🟡 $300</text>

        <rect onClick={() => levelChanged(4)} ref={levelFourRect} x={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0)} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding} width={__levelRectWidthHeight} height={__levelRectWidthHeight} rx={__levelRectRxRy} ry={__levelRectRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={3} />
        <text onClick={() => levelChanged(4)} x={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0) + (__levelRectWidthHeight/2)} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight/2)} dominantBaseline={'middle'} textAnchor='middle' fontSize={__levelRectTitleFontSize} fontWeight={'bold'} fill='#995500' >LV 4</text>
        <line x1={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0)} y1={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + __levelRectHeaderHeight} x2={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0) + __levelRectWidthHeight} y2={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + __levelRectHeaderHeight} stroke='#995500' strokeWidth={3} />
        <text onClick={() => levelChanged(4)} x={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0) + 10} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight) + __levelRectFontSize} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >💰 $4000</text>
        <text onClick={() => levelChanged(4)} x={__levelRectX + (__levelRectWidthHeight*0.5) + (__customDialogInnerPadding*0) + 10} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight) + (__levelRectFontSize*2.5)} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >🟡 $400</text>

        <rect onClick={() => levelChanged(5)} ref={levelFiveRect} x={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1)} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding} width={__levelRectWidthHeight} height={__levelRectWidthHeight} rx={__levelRectRxRy} ry={__levelRectRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={3} />
        <text onClick={() => levelChanged(5)} x={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1) + (__levelRectWidthHeight/2)} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight/2)} dominantBaseline={'middle'} textAnchor='middle' fontSize={__levelRectTitleFontSize} fontWeight={'bold'} fill='#995500' >LV 5</text>
        <line x1={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1)} y1={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + __levelRectHeaderHeight} x2={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1) + __levelRectWidthHeight} y2={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + __levelRectHeaderHeight} stroke='#995500' strokeWidth={3} />
        <text onClick={() => levelChanged(5)} x={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1) + 10} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight) + __levelRectFontSize} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >💰 $5000</text>
        <text onClick={() => levelChanged(5)} x={__levelRectX + (__levelRectWidthHeight*1.5) + (__customDialogInnerPadding*1) + 10} y={__levelRectY + __levelRectWidthHeight + __customDialogInnerPadding + (__levelRectHeaderHeight) + (__levelRectFontSize*2.5)} dominantBaseline={'hanging'} textAnchor='start' fontSize={__levelRectFontSize} fontWeight={'bold'} fill='white' >🟡 $500</text>

        <text x={__createRoomTitleX} y={__createRoomTitleY} fontSize={__titleFontSize} fill='url(#titleGradient)' stroke='url(#titleStrokeGradient)' strokeWidth={1} dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >CREATE ROOM</text>

        
        <text x={__roomNumberX} y={__roomNumberY - __customDialogInnerPadding} fontSize={__textSize} fill='#fff' dominantBaseline={'middle'} textAnchor='start' > Room No (Auto Generated)</text>

        <rect  x={__roomNumberX} y={__roomNumberY} width={__roomNumberWidth} height={__roomNumberHeight} rx={5} ry={5} fill='#960' stroke='#fd9' strokeWidth={3} />
        <text  x={__roomNumberX + (__roomNumberWidth/2)} y={__roomNumberY + (__roomNumberHeight/2)} fontSize={__textBoxTextSize} fill='#fff' dominantBaseline={'middle'} textAnchor='middle'letterSpacing={__textBoxTextSize/2} > {roomNumber}</text>

        <text x={__pinNumberX} y={__pinNumberY - __customDialogInnerPadding} fontSize={__textSize} fill='#fff' dominantBaseline={'middle'} textAnchor='start' > PIN No ရိုက်ထဲ့ပါ</text>
        <rect x={__pinNumberX} y={__pinNumberY} width={__pinNumberWidth} height={__pinNumberHeight} rx={5} ry={5} fill='#960' stroke='#fd9' strokeWidth={3} />
        <text x={__pinNumberX + (__pinNumberWidth/2)} y={__pinNumberY + (__pinNumberHeight/2)} fontSize={__textBoxTextSize} fill='#fff' dominantBaseline={'middle'} textAnchor='middle'letterSpacing={__textBoxTextSize/2} > {pinNumber}</text>
        
        <use onClick={() => keyPress(1)} href='#button' x={__inputX} y={__inputY} />
        <use onClick={() => keyPress(2)} href='#button' x={__inputX + (__inputWidthHeight*1) + (__customDialogInnerPadding*1)} y={__inputY} />
        <use onClick={() => keyPress(3)} href='#button' x={__inputX + (__inputWidthHeight*2) + (__customDialogInnerPadding*2)} y={__inputY} />
        <use onClick={() => keyPress(4)} href='#button' x={__inputX + (__inputWidthHeight*3) + (__customDialogInnerPadding*3)} y={__inputY} />
        <use onClick={() => keyPress(5)} href='#button' x={__inputX + (__inputWidthHeight*4) + (__customDialogInnerPadding*4)} y={__inputY} />
        <use onClick={() => keyPress("delete")} href='#redButton' x={__inputX + (__inputWidthHeight*5) + (__customDialogInnerPadding*5)} y={__inputY} fill='url(#redButtonGradient)' stroke='url(#redButtonStrokeGradient)' />

        <text onClick={() => keyPress(1)} x={__inputTextX + (__inputTextGap * 0)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >1</text>
        <text onClick={() => keyPress(2)} x={__inputTextX + (__inputTextGap * 1)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >2</text>
        <text onClick={() => keyPress(3)} x={__inputTextX + (__inputTextGap * 2)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >3</text>
        <text onClick={() => keyPress(4)} x={__inputTextX + (__inputTextGap * 3)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >4</text>
        <text onClick={() => keyPress(5)} x={__inputTextX + (__inputTextGap * 4)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >5</text>
        <text onClick={() => keyPress("delete")} x={__inputTextX + (__inputTextGap * 5)} y={__inputTextY} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >X</text>

        <use onClick={() => keyPress(6)} href='#button' x={__inputX + (__inputWidthHeight*0) + (__customDialogInnerPadding*0)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(7)} href='#button' x={__inputX + (__inputWidthHeight*1) + (__customDialogInnerPadding*1)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(8)} href='#button' x={__inputX + (__inputWidthHeight*2) + (__customDialogInnerPadding*2)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(9)} href='#button' x={__inputX + (__inputWidthHeight*3) + (__customDialogInnerPadding*3)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />
        <use onClick={() => keyPress(0)} href='#button' x={__inputX + (__inputWidthHeight*4) + (__customDialogInnerPadding*4)} y={__inputY + __inputWidthHeight + __customDialogInnerPadding} />

        <text onClick={() => keyPress(6)} x={__inputTextX + (__inputTextGap * 0)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >6</text>
        <text onClick={() => keyPress(7)} x={__inputTextX + (__inputTextGap * 1)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >7</text>
        <text onClick={() => keyPress(8)} x={__inputTextX + (__inputTextGap * 2)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >8</text>
        <text onClick={() => keyPress(9)} x={__inputTextX + (__inputTextGap * 3)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >9</text>
        <text onClick={() => keyPress(0)} x={__inputTextX + (__inputTextGap * 4)} y={__inputTextY + __inputWidthHeight + __customDialogInnerPadding} fontSize={__inputTextSize} fontWeight={'bold'} dominantBaseline={'middle'} textAnchor='middle' >0</text>


        <rect onClick={() => createRoom()} x={__joinButtonX} y={__joinButtonY} width={__actionButtonWidth} height={__actionButtonHeight} rx={__actionButtonRxRy} ry={__actionButtonRxRy} fill='url(#orangeButtonGradient)' stroke='url(#orangeButtonStrokeGradient)' strokeWidth={__actionButtonStrokeWidth} />
        <text onClick={() => createRoom()} x={(__joinButtonX + (__actionButtonWidth/2))} y={(__joinButtonY + (__actionButtonHeight/2))} fontSize={__actionButtonFontSize} fill='white' dominantBaseline={'middle'} textAnchor='middle' fontWeight={'bold'} >Create Room</text>
        
      </g>

    
    </svg>

    <Dialog open={alertDialogVisible} onClose={() => setAlertDialogVisible(false)} fullWidth={true} maxWidth={'sm'}>
<DialogTitle size="sm"  sx={{border: "solid #a60 1px", backgroundColor: "#e90"}}>Message</DialogTitle>
<DialogContent>
  <DialogContentText sx={{padding: "30px"}}>
    {statusMsg}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={() => setAlertDialogVisible(false)}  className='button' size="small" variant="contained" color="primary">
    Close
  </Button>
</DialogActions>
</Dialog>
    </div>
    
  );
};

export default RoomList;
